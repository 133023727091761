import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { ToastService } from '../../services/toast-service/toast-service.service';
import { AuthService } from '../../services/auth-service/auth.service';
import { AnalyticsTrackerService } from '../../services/analytics-tracker/analytics-tracker.service';
import { ApiError } from '../../interfaces/api-error.interface';
import { AnalyticsTracking } from '../../interfaces/analytics.interface';

@Component({
  selector: 'app-logout-modal',
  templateUrl: './logout-modal.component.html',
  styleUrls: ['./logout-modal.component.scss'],
})
export class LogoutModalComponent implements OnInit {
  public loading: boolean = false;

  public isOpen: boolean = false;

  public analyticsCategory: string = 'Authentication';

  constructor(
    public renderer: Renderer2,
    private router: Router,
    private authService: AuthService,
    public toastService: ToastService,
    private analyticsTrackerService: AnalyticsTrackerService
  ) {}

  public ngOnInit() {}

  public openModal() {
    this.isOpen = true;
    this.loadBodyClass('add');
    this.analyticsTrackerService.trackEvent(
      AnalyticsTracking.ACTIONS.TOGGLE_MODAL,
      'Open Logout',
      this.analyticsCategory
    );
  }

  public closeModal() {
    this.loadBodyClass('remove');
    this.isOpen = false;
    this.analyticsTrackerService.trackEvent(
      AnalyticsTracking.ACTIONS.TOGGLE_MODAL,
      'Close Logout',
      this.analyticsCategory
    );
  }

  public loadBodyClass(type: string) {
    if (type === 'add') {
      this.renderer.addClass(document.body, 'disable-scroll');
    } else {
      this.renderer.removeClass(document.body, 'disable-scroll');
    }
  }

  public logout(allDevices: boolean) {
    if (this.loading) {
      return;
    }

    this.loading = true;

    this.authService
      .logout(allDevices)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        () => {
          let eventName: string = 'Successfully Logout';
          eventName += allDevices ? ' From All Devices' : '';

          this.analyticsTrackerService.trackEvent(
            AnalyticsTracking.ACTIONS.REQUEST_LOGOUT,
            eventName,
            this.analyticsCategory
          );

          this.postLogoutEvents();
        },
        (err: ApiError) => {
          // console.log('[logout] ERROR', err);
          this.analyticsTrackerService.trackEventError(
            AnalyticsTracking.ACTIONS.REQUEST_LOGOUT,
            err.error,
            true,
            this.analyticsCategory
          );
          this.toastService.error(err.message);

          if (err.error !== 'ERROR_LOGOUT_CACHE_REMOVAL') {
            this.postLogoutEvents();
          }
        }
      );
  }

  private postLogoutEvents() {
    this.analyticsTrackerService.resetUserId();
    this.closeModal();
    this.router.navigate(['/login']);
  }
}
