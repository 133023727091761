import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize, tap, mergeMap, catchError } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { CheckupClass, CheckupFactory } from '../../class/checkup/checkup.class';
import { ToastService } from '../../services/toast-service/toast-service.service';
import { CheckupComponent } from '../checkup/checkup.component';
import { SidebarMenuItem } from '../../interfaces/checkup-sidebar.interface';
import { CheckupSidebarMenuComponent } from '../../components/checkup-sidebar-menu/checkup-sidebar-menu.component';
import { CheckupUserContactCardComponent } from '../../components/checkup-user-contact-card/checkup-user-contact-card.component';
import { AnalyticsTrackerService } from '../../services/analytics-tracker/analytics-tracker.service';
import { TranslationService } from '../../services/translation/translation.service';
import { CheckupApiServiceCheckupReadingsInfoResponse } from '../../services/api-checkup/api-checkup.interface';
import { CheckupReviewService } from '../../services/checkup-review/checkup-review.service';
import { PackageInfo } from '../../interfaces/package.interface';
import { PackageService } from '../../services/package/package.service';

@Component({
  selector: 'app-checkup-results',
  templateUrl: './checkup-results.component.html',
  styleUrls: ['./checkup-results.component.scss'],
})
export class CheckupResultsComponent implements OnInit, OnDestroy {
  @ViewChild('sidebarMenu', { static: true }) public sidebarMenu: CheckupSidebarMenuComponent;

  @ViewChild('biomarkersBox', { static: true }) public biomarkersBox;

  @ViewChild('calculatedBiomarkersBox', { static: true }) public calculatedBiomarkersBox;

  @ViewChild('userContactCard', { static: true })
  public userContactCard: CheckupUserContactCardComponent;

  @ViewChild('userInfoCard', { static: true }) public userInfoCard;

  @ViewChild('appointmentBox', { static: true }) public appointmentBox;

  @ViewChild('userHeader', { static: true }) public userHeader;

  @ViewChild('questionnaireBox', { static: true }) public questionnaireBox;

  public checkup: CheckupClass;

  public loading: boolean = false;

  private checkupReviewServiceObservable$: Subscription = Subscription.EMPTY;

  private sub: any;

  constructor(
    private route: ActivatedRoute,
    private toastService: ToastService,
    @Inject(CheckupComponent) private parentCheckupComponent: CheckupComponent,
    private checkupFactory: CheckupFactory,
    private translationService: TranslationService,
    private analyticsTrackerService: AnalyticsTrackerService,
    private checkupReviewService: CheckupReviewService,
    private packageService: PackageService
  ) {}

  public ngOnInit() {
    this.analyticsTrackerService.initPageTracking(['Checkup', 'Results'], 'Checkup Results');
    this.loading = true;

    this.sub = this.route.parent.params.subscribe((params) => {
      this.loadCheckupResults(+params.packageId, +params.checkupId);
    });
  }

  public ngOnDestroy() {
    this.checkupReviewServiceObservable$.unsubscribe();
  }

  public loadSidebarMenuOptions() {
    const sidebarMenuOptions: SidebarMenuItem[] = [];

    sidebarMenuOptions.push({
      id: 'checkup-user-info',
      title: this.translationService.getInstantTranslation('CHECKUP.INFO.USER_PROFILE'),
      analytics: null,
    });

    sidebarMenuOptions.push({
      id: 'checkup-questionnaires',
      title: this.translationService.getInstantTranslation('CHECKUP.QUESTIONNAIRE.TITLE'),
      analytics: null,
    });

    const biomarkers = this.checkup.getCheckupReadings();

    if (biomarkers) {
      biomarkers.forEach((biomarkerGroup: CheckupApiServiceCheckupReadingsInfoResponse) => {
        sidebarMenuOptions.push({
          id: biomarkerGroup.group_id.toString(),
          title: biomarkerGroup.name,
          analytics: biomarkerGroup.analytics,
        });
      });
    }

    const calculatedBiomarkers = this.checkup.getCheckupCalculatedReadings();

    if (calculatedBiomarkers) {
      sidebarMenuOptions.push({
        id: 'calculated',
        title: this.translationService.getInstantTranslation('CHECKUP.CALCULATED_BIOMARKER.TITLE'),
        analytics: null,
      });
    }

    this.sidebarMenu.loadMenu(sidebarMenuOptions);
  }

  public openObsModal() {
    this.parentCheckupComponent.openObsModal();
  }

  private loadCheckupResults(packageId: number, checkupId: number) {
    this.checkup = this.checkupFactory.createCheckup();

    this.checkupReviewServiceObservable$ = this.checkupReviewService
      .init(checkupId, packageId)
      .pipe(
        mergeMap(() => this.checkupReviewService.loadCheckupReadings()),
        mergeMap(() => this.checkupReviewService.loadCheckupCalculatedReadings()),
        mergeMap(() => this.checkupReviewService.loadCheckupObservations()),
        tap(() => {
          this.loading = false;
          this.biomarkersBox.loadingComplete();
          this.calculatedBiomarkersBox.loadingComplete();
          this.userContactCard.loadingComplete();
          this.userInfoCard.loadingComplete();
          this.userHeader.loadingComplete();
          this.appointmentBox.loadingComplete();
        }),
        tap(() => {
          this.checkup = this.checkupReviewService.getCheckupLoaded();
          this.loadSidebarMenuOptions();
          this.setRecentPackage();
        }),
        mergeMap(() => this.checkupReviewService.loadCheckupQuestions()),
        finalize(() => {
          this.questionnaireBox.loadingComplete();
        }),
        catchError((err) => {
          this.toastService.error(err.message);
          return err;
        })
      )
      .subscribe();
  }

  private setRecentPackage() {
    const packageInfo: PackageInfo = {
      title: this.checkup.clientName,
      token: this.checkup.voucher,
      packageId: this.checkup.packageId,
      teleconsultation: this.checkup.hasTeleconsultation,
    };
    this.packageService.setRecentPackage(packageInfo);
  }
}
