<div class="c-error">
  <div class="c-error__content-container">
    <div class="c-error__icon-container">
      <img class="c-error__imperfect-circle"
        src="https://static.wellabe.fit/logos/imperfect-circle.svg" />
      <img class="c-error__icon" src="https://static.wellabe.fit/icons/error-page-icon.png">
    </div>

    <h1 class="c-error__title">{{ 'ERROR_PAGE.404.TITLE' | translate }}</h1>
    <p class="c-error__description">{{ 'ERROR_PAGE.404.DESCRIPTION' | translate }}</p>
    <a class="c-error__go-back" (click)="goBack()">{{ 'GENERAL.GO_BACK' | translate }}</a>
  </div>
</div>