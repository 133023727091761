<form class="c-form" [formGroup]="currentForm" (ngSubmit)="onSubmit()">
  <div [class.has-error]="
      currentForm.get('description').touched && !currentForm.get('description').valid
    ">
    <textarea class="label-info" [placeholder]="'OBSERVATION_FORM.DESCRIPTION' | translate"
      formControlName="description" rows="3"></textarea>
  </div>

  <div class="bottom-bar">
    <i class="flag-icon u-icon ion-md-flag"></i>
    <div class="flag-field">
      <select formControlName="observationFlag">
        <option *ngFor="let option of flagOptions" [value]="option.id">
          {{ option.name | translate }}
        </option>
      </select>
    </div>

    <div class="tooltip">
      <span class="tooltiptext">
        {{ 'OBSERVATION_FORM.STAFF_OBS' | translate }}
      </span>
      <span>
        <i class="u-icon ion-md-lock"></i>
      </span>
    </div>

    <button [disabled]="loading" type="submit" class="bt">
      {{ 'OBSERVATION_FORM.SAVE_MESSAGE' | translate }}
    </button>
  </div>
</form>