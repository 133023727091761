<div class="observations-list" #scrolling>
  <div class="loading" *ngIf="loading">
    <span>
      <img src="./assets/images/button-loading.gif" />
    </span>
  </div>

  <div class="no-obs-msg" *ngIf="
      !loading &&
      checkupReviewService.getCheckupObsLoaded() &&
      !checkupReviewService.getCheckupObsLoaded().observationsList.length
    ">
    <p>
      {{ 'OBSERVATION_FORM.NO_ITEMS_MESSAGE' | translate }}
    </p>
  </div>

  <div *ngIf="checkupReviewService.getCheckupObsLoaded() && !loading">
    <app-checkup-observation-card class="obs-item" *ngFor="
        let observations of checkupReviewService.getCheckupObsLoaded().observationsList;
        let last = last
      " [text]="observations.text" [staffFullName]="observations.staffFullName"
      [flags]="observations.observationFlags" [createdAt]="observations.createdAt"
      [isLastItem]="last" [fromLab]="observations.fromLab"
      [checkupOrder]="observations.performedOrder"
      [staffProfilePictureSmall]="observations.staffProfilePictureSmall">
    </app-checkup-observation-card>
  </div>
</div>

<app-checkup-observation-form #checkupObservationForm
  [checkupObsListInstance]="checkupReviewService.getCheckupObsLoaded()"
  (onUpdate)="newObservationAdded()"></app-checkup-observation-form>