import { Component, OnInit, Input } from '@angular/core';
import { ObservationFlag } from '../../class/checkup-obs/checkup-obs.interface';

@Component({
  selector: 'app-checkup-observation-card',
  templateUrl: './checkup-observation-card.component.html',
  styleUrls: ['./checkup-observation-card.component.scss'],
})
export class CheckupObservationCardComponent implements OnInit {
  @Input() public text: string;

  @Input() public flags: ObservationFlag[] = [];

  @Input() public staffFullName: string;

  @Input() public createdAt: string;

  @Input() public staffProfilePictureSmall: string;

  // not a beautiful way to set margin 0 for the last item, but considering the current CSS, it is the easiest way
  @Input() public isLastItem = false;

  @Input() public checkupOrder;

  @Input() public fromLab = false;

  public ngOnInit() {}

  public getFlagPhrase(flag: ObservationFlag): string {
    switch (flag) {
      case 'quality_related':
        return 'OBSERVATIONS.IS_QUALITY_RELATED';
      case 'special_case':
        return 'OBSERVATIONS.IS_SPECIAL_CASE';
      default:
        break;
    }

    return '';
  }
}
