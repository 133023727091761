import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsTracking } from '../../interfaces/analytics.interface';
import { AnalyticsTrackerService } from '../../services/analytics-tracker/analytics-tracker.service';
import { CheckupService } from '../../services/checkup/checkup.service';

@Component({
  selector: 'app-checkup-next-step-button',
  templateUrl: './checkup-next-step-button.component.html',
  styleUrls: ['./checkup-next-step-button.component.scss'],
})
export class CheckupNextStepButtonComponent implements OnInit {
  @Input() public buttonTitle: string;

  @Input() public nextStep: string;

  @Input() public nextStepActionIsCustomFunction: boolean = false;

  @Input() public isTeleconsultation: boolean = false;

  @Output() public nextStepSubmitAction = new EventEmitter();

  @Input() public loading: boolean = false;

  public checkupLink: string;

  public checkupId: number;

  private sub: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private checkupService: CheckupService,
    private analyticsTrackerService: AnalyticsTrackerService
  ) {}

  public ngOnInit() {
    this.sub = this.activatedRoute.parent.params.subscribe((params) => {
      if (this.isTeleconsultation) {
        this.checkupLink = `/appointments/${params.checkupId}`;
      } else {
        this.checkupLink = `/packages/${params.packageId}/checkups/${params.checkupId}`;
      }

      this.checkupId = params.checkupId;
    });
  }

  public nextStepAction() {
    // console.log('NEXT STEP [nextStepAction]');

    if (this.loading) {
      return;
    }

    this.loading = true;

    this.checkupService.setCheckupInProgress(this.checkupId, this.isTeleconsultation);

    this.analyticsTrackerService.trackEvent(
      AnalyticsTracking.ACTIONS.CHECKUP_RESULT_STEP_CONFIRMATION,
      `Go To Step "${this.nextStep}"`
    );

    if (this.nextStepActionIsCustomFunction) {
      this.nextStepSubmitAction.emit({});
    } else {
      this.router.navigate([this.checkupLink, { outlets: { checkup: this.nextStep } }], {
        queryParamsHandling: 'preserve',
      });
    }
  }
}
