import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  AuthApiServiceLoginRequest,
  AuthApiServiceLoginResponse,
} from '../../interfaces/user.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiAuthService {
  constructor(private http: HttpClient) {}

  public postLogin(username: string, password: string) {
    const body: AuthApiServiceLoginRequest = {
      username,
      password,
      client_id: 3,
    };

    return this.http.post<AuthApiServiceLoginResponse>(`${environment.api}/doc/auth/login`, body);
  }

  public logout(authToken: string, logoutFromAllDevices: boolean) {
    const body: {} = { Authorization: authToken };
    const paramLogoutFromAllDevices: string = logoutFromAllDevices ? '/all' : '';

    return this.http.post(`${environment.api}/staff/logout${paramLogoutFromAllDevices}`, body);
  }
}
