/* eslint-disable max-classes-per-file */
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {
  ChallengeItemSearchResult,
  ChallengeSearchApiServiceResponse,
} from '../../services/api-challenges/api-challenges.interface';
import { ApiChallengesService } from '../../services/api-challenges/api-challenges.service';
import { ApiJourneyService } from '../../services/api-journey/api-journey.service';
import {
  JourneyApiServiceResponse,
  JourneyApiServiceJourneyToUpdate,
} from '../../services/api-journey/api-journey.interface';
import { Utils } from '../utils/utils.class';

@Injectable({
  providedIn: 'root',
})
export class CheckupChallengeFactory {
  constructor(
    private apiChallengesService: ApiChallengesService,
    private apiJourneyService: ApiJourneyService,
    private utils: Utils
  ) {}

  public createCheckupChallenge() {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return new CheckupChallengeClass(this.apiChallengesService, this.apiJourneyService, this.utils);
  }
}

export class CheckupChallengeClass {
  public challengesList: CheckupChallengeClass[] = [];

  public checkupId: number;

  public challengeId: number;

  public title: string;

  public analytics: string;

  public order: string;

  public image: string;

  public contentType: string = '';

  public content: string = '';

  constructor(
    private apiChallengesService: ApiChallengesService,
    private apiJourneyService: ApiJourneyService,
    private utils: Utils
  ) {}

  public searchChallengesByTitle(title: string, currentPage: number) {
    const titleEncoded: string = encodeURI(title);

    return this.apiChallengesService.getChallengesByTitle(titleEncoded, currentPage).pipe(
      map((challengeSearchResult: ChallengeSearchApiServiceResponse) => {
        const resetChallengesList: boolean = !(currentPage >= 2);
        this.loadChallenges(challengeSearchResult.data, false, resetChallengesList);

        return {
          showLoadMoreButton: challengeSearchResult.current_page < challengeSearchResult.last_page,
        };
      })
    );
  }

  public getChallengesFromJourney() {
    return this.apiJourneyService.getJourney(this.checkupId).pipe(
      map((journeyList: JourneyApiServiceResponse[]) => {
        return this.loadChallenges(journeyList);
      })
    );
  }

  public loadChallenges(
    challengesList: any[],
    fromJourney: boolean = true,
    resetChallengesList: boolean = true
  ) {
    if (resetChallengesList) {
      this.challengesList = [];
    }

    let i: number = 1;

    challengesList.forEach((currentItem: any) => {
      const checkupChallengeFactory = new CheckupChallengeFactory(
        this.apiChallengesService,
        this.apiJourneyService,
        this.utils
      );
      const currentChallenge: CheckupChallengeClass =
        checkupChallengeFactory.createCheckupChallenge();

      if (fromJourney) {
        this.loadChallengeFromJourney(currentChallenge, currentItem as JourneyApiServiceResponse);
      } else {
        this.loadChallenge(currentChallenge, currentItem as ChallengeItemSearchResult);
      }

      currentChallenge.order = this.utils.addLeadingZero(i);

      i += 1;

      this.challengesList.push(currentChallenge);
    });

    return this.challengesList;
  }

  public loadChallenge(
    currentChallenge: CheckupChallengeClass,
    currentItem: ChallengeItemSearchResult
  ) {
    const challange = currentChallenge;

    challange.challengeId = currentItem.challenge_id;
    challange.title = currentItem.title;
    challange.analytics = currentItem.analytics;
    [challange.image] = currentItem.media;
    challange.contentType = 'challenge';
  }

  public loadChallengeFromJourney(
    currentChallenge: CheckupChallengeClass,
    currentItem: JourneyApiServiceResponse
  ) {
    const challange = currentChallenge;

    challange.challengeId = currentItem.journey_content_id;
    challange.title = currentItem.content.title;
    challange.analytics = currentItem.content.analytics;
    [challange.image] = currentItem.content.media;
    challange.contentType = currentItem.journey_content_type;
  }

  public updateChallengesOrder() {
    this.challengesList.forEach((challenge: CheckupChallengeClass, index: number) => {
      // eslint-disable-next-line no-param-reassign
      challenge.order = this.utils.addLeadingZero(index + 1);
    });
  }

  public getChallengesToUpdate() {
    const challengesListToUpdate: JourneyApiServiceJourneyToUpdate[] = [];
    this.challengesList.forEach((challenge: CheckupChallengeClass, index: number) => {
      challengesListToUpdate.push({
        journey_content_id: challenge.challengeId,
        journey_content_type: 'challenge',
        order: index,
      });
    });

    return challengesListToUpdate;
  }

  public getChallengeContent() {
    return this.apiChallengesService
      .getChallengeById(this.challengeId)
      .toPromise()
      .then((response) => {
        this.content = response.content;
      });
  }

  public submitChallengesChanges() {
    return this.apiJourneyService.putJourney(this.checkupId, this.getChallengesToUpdate());
  }

  public removeChallengeFromList(challengeToRemove: CheckupChallengeClass) {
    this.challengesList = this.challengesList.filter((currentChallenge: CheckupChallengeClass) => {
      return currentChallenge.challengeId !== challengeToRemove.challengeId;
    });

    this.updateChallengesOrder();
  }

  public includeNewChallenge(challenge: CheckupChallengeClass) {
    const newChallenge = challenge;
    newChallenge.order = this.utils.addLeadingZero(this.challengesList.length + 1);

    this.challengesList.push(newChallenge);
  }
}
