import { Component, Input } from '@angular/core';
import { CheckupApiServiceCalculatedReadingsInfoResponse } from '../../services/api-checkup/api-checkup.interface';

@Component({
  selector: 'app-checkup-calculated-biomarkers-box',
  templateUrl: './checkup-calculated-biomarkers-box.component.html',
  styleUrls: ['./checkup-calculated-biomarkers-box.component.scss'],
})
export class CheckupCalculatedBiomarkersBoxComponent {
  @Input() public resultsByGroup: CheckupApiServiceCalculatedReadingsInfoResponse[] = [];

  public loading: boolean = true;

  public loadingComplete() {
    this.loading = false;
  }
}
