<div class="questionnaire-card">
  <div class="questionnaire-card__name-wrapper">
    <div class="questionnaire-card__name">{{ title }}</div>
    <div class="tooltip questionnaire-card__date-container" *ngIf="pivot.answered_at">
      <div class="tooltiptext tooltiptext--md">
        {{ pivot.answered_at | formatDate: 'L' }}
      </div>
      <span class="questionnaire-card__date">
        {{ pivot.answered_at | formatDate: 'questionnaire' }}
      </span>
    </div>
  </div>
  <div class="questionnaire-cardvalue-wrapper">
    <div class="questionnaire-card__value" *ngIf="pivot.answered_at">
      <span *ngFor="let answer of answers; let last = last">
        <span>{{ answer.value }}</span>
        <span class="separator" *ngIf="!last">
          /
        </span>
      </span>
      <span class="empty" *ngIf="answers.length === 0">
        {{ 'GENERAL.NA' | translate }}
      </span>
    </div>
    <div class="questionnaire-card__value" *ngIf="!pivot.answered_at">
      <span class="empty">
        {{ 'CHECKUP.QUESTIONNAIRE_NOT_ANSWERED' | translate }}
      </span>
    </div>
  </div>
</div>