<div id="biomarker-group-checkup-user-info">
  <div *ngIf="!loading && checkup">
    <h2 class="user-info__title">
      {{ 'CHECKUP.INFO.USER_PROFILE' | translate }}
    </h2>
    <div class="user-info">
      <div class="side">
        <div class="item" *ngIf="checkup.userName && checkup.hasTeleconsultation">
          <i class="fa fa-signature icon"></i>
          <p class="value">
            {{ checkup.userName }}
          </p>
        </div>

        <div class="item">
          <i class="far fa-id-badge icon large"></i>
          <p class="value">
            {{ checkup.wellabeId }}
            <button class="button--icon-only" (click)="copyToClipboard(checkup.wellabeId)">
              <i *ngIf="!copiedToClipboard" class="far fa-copy"></i>
              <i *ngIf="copiedToClipboard" class="u-icon ion-md-checkmark icon--success"></i>
            </button>
          </p>
        </div>

        <div class="item">
          <span [ngSwitch]="checkup.userSex">
            <i class="fa fa-neutral icon large" *ngSwitchCase="0"></i>
            <i class="fa fa-mars icon large" *ngSwitchCase="1"></i>
            <i class="fa fa-venus icon large" *ngSwitchCase="2"></i>
            <i class="fa fa-genderless icon large" *ngSwitchDefault></i>
          </span>
          <p class="value">
            {{ checkup.ageAtCheckup }}, {{ getTranslatedSexString(checkup.getSexString()) }}
          </p>
        </div>

        <div class="item">
          <i class="fa fa-text-height icon"></i>
          <p *ngIf="checkup.userHeight !== null" class="value">
            {{ checkup.userHeight }} {{ 'GENERAL.UNIT.CM' | translate }}
          </p>
          <p *ngIf="checkup.userHeight === null" class="value empty">
            {{ 'GENERAL.NA' | translate }}
          </p>
        </div>

        <div class="item">
          <i class="fa fa-weight icon"></i>
          <p *ngIf="checkup.userWeight !== null" class="value">
            {{ checkup.userWeight }} {{ 'GENERAL.UNIT.KG' | translate }}
          </p>
          <p *ngIf="checkup.userWeight === null" class="value empty">
            {{ 'GENERAL.NA' | translate }}
          </p>
        </div>

        <div class="item">
          <i class="fa fa-globe icon"></i>
          <p *ngIf="checkup.userLanguage !== null" class="value">
            {{ checkup.userLanguage }}
          </p>
          <p *ngIf="checkup?.userLanguage === null" class="value empty">
            {{ 'GENERAL.NA' | translate }}
          </p>
        </div>

        <hr *ngIf="checkup.trackingInfo.metrics.length > 0">

        <div class="item" *ngFor="let item of checkup.trackingInfo.metrics">
          <i class="icon {{ item.icon }}"></i>
          <p class="value">
            {{ item.value }} <span class="title">{{ item.title | translate }}</span>
          </p>
        </div>
      </div>

      <div class="main">
        <div class="section-title">
          {{ 'CHECKUP.INFO.PRE_CHECKUP_DETAILS' | translate }}
        </div>
        <ul class="list-items">
          <li class="item">
            <div class="title">
              {{ 'CHECKUP.INFO.PHYSICAL_ACTIVITY' | translate }}
            </div>
            <div class="value">
              <p *ngIf="checkup.hoursLastExercise !== null">
                {{ checkup.hoursLastExercise }} {{ 'CHECKUP.INFO.HOURS_BEFORE' | translate }}
              </p>
              <p *ngIf="checkup.hoursLastExercise === null">{{ 'GENERAL.NA' | translate }}</p>
            </div>
          </li>
          <li class="item">
            <div class="title">
              {{ 'CHECKUP.INFO.INTENSE_EXERCISE' | translate }}
            </div>
            <div class="value">
              <p *ngIf="checkup.recentIntenseExercise === true">{{ 'GENERAL.YES' | translate }}</p>
              <p *ngIf="checkup.recentIntenseExercise === false">{{ 'GENERAL.NO' | translate }}</p>
              <p *ngIf="checkup.recentIntenseExercise === null">{{ 'GENERAL.NA' | translate }}</p>
            </div>
          </li>
          <li class="item">
            <div class="title">
              {{ 'CHECKUP.INFO.FASTING' | translate }}
            </div>
            <div class="value">
              <p *ngIf="checkup.hoursLastMeal !== null">
                {{ 'GENERAL.FOR' | translate }} {{ checkup.hoursLastMeal }}
                {{ 'CHECKUP.INFO.HOURS' | translate }}
              </p>
              <p *ngIf="checkup.hoursLastMeal === null">{{ 'GENERAL.NA' | translate }}</p>
            </div>
          </li>
          <li class="item">
            <div class="title">
              {{ 'CHECKUP.INFO.SMOKING_STATUS' | translate }}
            </div>
            <div class="value">
              <p [ngSwitch]="checkup.smokingStatus">
                <ng-container *ngSwitchCase="0">{{ 'CHECKUP.INFO.SMOKING_STATUS.0' | translate }}
                </ng-container>
                <ng-container *ngSwitchCase="1">{{ 'CHECKUP.INFO.SMOKING_STATUS.1' | translate }}
                </ng-container>
                <ng-container *ngSwitchCase="2">{{ 'CHECKUP.INFO.SMOKING_STATUS.2' | translate }}
                </ng-container>
                <ng-container *ngSwitchCase="3">{{ 'CHECKUP.INFO.SMOKING_STATUS.3' | translate }}
                </ng-container>
                <ng-container *ngSwitchCase="4">{{ 'CHECKUP.INFO.SMOKING_STATUS.4' | translate }}
                </ng-container>
                <ng-container *ngSwitchDefault>{{ 'GENERAL.NA' | translate }}</ng-container>
              </p>
            </div>
          </li>
        </ul>

        <hr>

        <app-checkup-lab-observation [checkup]="checkup"
          (openObservations)="openObservationsModal()">
        </app-checkup-lab-observation>
      </div>

      <app-checkup-results-doc-rating [checkup]="checkup"></app-checkup-results-doc-rating>
    </div>

    <div *ngIf="loading">
      <h2 class="fake-content"></h2>
      <div class="user-info fake-content"></div>
    </div>
  </div>