<div class="e-checkup-tags__wrapper"
  *ngIf="status !== CheckupTagsContentStatus.LOADING; else loading">
  <form class="c-form" [formGroup]="currentForm">
    <label class="e-checkup-tags__label">
      {{ 'CHECKUP.FEEDBACK.TAGS_DESCRIPTION' | translate }}
    </label>

    <ng-select class="ng2-select-tags" [class.ng2-select-tags--attached]="!appendToBody"
      [items]="tagsOptions" bindLabel="tag" [multiple]="true" [hideSelected]="true"
      dropdownPosition="bottom" [clearable]="false"
      [placeholder]="'CHECKUP.FEEDBACK.TAGS_SECONDARY_PLACEHOLDER' | translate"
      [appendTo]="appendToBody ? '.float-element' : ''" formControlName="tags"
      (add)="submitCheckupTags()" (remove)="submitCheckupTags()">
    </ng-select>
  </form>

  <div class="form-message">
    <p>
      {{ 'CHECKUP.TAGS.FORM_TIP' | translate }}
    </p>
  </div>

  <div class="form-message form-status" [ngSwitch]="status">
    <p *ngSwitchCase="CheckupTagsContentStatus.SUBMITTING">
      <img src="./assets/images/spinner.svg" />
      {{ 'CHECKUP.TAGS.SYNCING' | translate }}
    </p>

    <p *ngSwitchCase="CheckupTagsContentStatus.SYNCED">
      <img src="./assets/images/check-circle-green.svg" />
      {{ 'CHECKUP.TAGS.SYNCED' | translate }}
    </p>

    <p *ngSwitchCase="CheckupTagsContentStatus.ERROR">
      <img src="./assets/images/error-circle.svg" />
      {{ 'CHECKUP.TAGS.ERROR' | translate }}
    </p>
  </div>
</div>

<ng-template #loading>
  <div class="e-checkup-tags__loading">
    <img src="./assets/images/button-loading.gif" />
  </div>
</ng-template>