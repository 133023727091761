import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { CheckupClass } from '../../class/checkup/checkup.class';
import { TranslationService } from '../../services/translation/translation.service';
import { CheckupReviewService } from '../../services/checkup-review/checkup-review.service';

@Component({
  selector: 'app-checkup-user-info-card',
  templateUrl: './checkup-user-info-card.component.html',
  styleUrls: ['./checkup-user-info-card.component.scss'],
})
export class CheckupUserInfoCardComponent {
  @Input() public checkup: CheckupClass = null;

  @Output() public openObservations = new EventEmitter();

  public loading: boolean = true;

  public copiedToClipboard = false;

  constructor(
    private translationService: TranslationService,
    private clipboardService: ClipboardService,
    public checkupReviewService: CheckupReviewService
  ) {}

  public getTranslatedSexString(sexAsString: string) {
    return this.translationService.getInstantTranslation(`CHECKUP.GENDER_OPTIONS.${sexAsString}`);
  }

  public loadingComplete() {
    this.loading = false;
  }

  public copyToClipboard(content: string) {
    this.clipboardService.copyFromContent(content);

    // Toggle the success state, used to indicate on the UI that the wellabeID was copied
    this.copiedToClipboard = true;
    setTimeout(() => {
      this.copiedToClipboard = false;
    }, 2000);
  }

  public openObservationsModal() {
    this.openObservations.emit();
  }
}
