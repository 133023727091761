import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastService } from '../../services/toast-service/toast-service.service';
import {
  Notification,
  NotificationType,
} from '../../services/toast-service/toast-service.interface';

@Component({
  selector: 'app-toast-messages',
  templateUrl: './toast-messages.component.html',
  styleUrls: ['./toast-messages.component.scss'],
})
export class ToastMessagesComponent implements OnInit, OnDestroy {
  public notifications: Notification[] = [];

  public toastServiceObservable: Subscription;

  constructor(public toastService: ToastService) {}

  public ngOnInit() {
    this.toastServiceObservable = this.toastService
      .listenEvents()
      .subscribe((notification: Notification) => {
        this.showNotification(notification);
      });
  }

  public ngOnDestroy() {
    this.toastServiceObservable.unsubscribe();
  }

  public removeNotificationEvent(notification: Notification) {
    // eslint-disable-next-line no-param-reassign
    notification.effect = 'fade-out-effect';

    setTimeout(() => {
      this.notifications = this.notifications.filter(
        (currentItem: Notification) => currentItem.id !== notification.id
      );
    }, 500);
  }

  public showNotification(notification: Notification) {
    this.notifications.push(notification);

    setTimeout(() => {
      this.removeNotificationEvent(notification);
    }, 3500);
  }

  public cssClass(notification: Notification): string {
    if (!notification) {
      return null;
    }

    switch (notification.type) {
      case NotificationType.ERROR:
        return 'toast-error';
      case NotificationType.SUCCESS:
        return 'toast-success';
      case NotificationType.WARNING:
        return 'toast-warning';
      default:
        return null;
    }
  }
}
