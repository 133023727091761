import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'keys' })
export class KeysPipe implements PipeTransform {
  public transform(value: any): any {
    const keys: any[] = [];
    Object.keys(value).forEach((key) => {
      keys.push({ key, value: value[key] });
    });
    return keys;
  }
}
