import { Component, Input, ViewChild, Output, EventEmitter, OnInit } from '@angular/core';
import { AnalyticsTrackerService } from '../../services/analytics-tracker/analytics-tracker.service';
import { AnalyticsTracking } from '../../interfaces/analytics.interface';
import { CheckupTagsInputComponent } from '../checkup-tags-input/checkup-tags-input.component';
import { CheckupObservationBoxComponent } from '../checkup-observation-box/checkup-observation-box.component';
@Component({
  selector: 'app-checkup-observation-and-tags-modal',
  templateUrl: './checkup-observation-and-tags-modal.component.html',
  styleUrls: ['./checkup-observation-and-tags-modal.component.scss'],
})
export class CheckupObservationAndTagsModalComponent implements OnInit {
  @ViewChild(CheckupTagsInputComponent, { static: true })
  public checkupTagsInputComponent: CheckupTagsInputComponent;

  @ViewChild(CheckupObservationBoxComponent, { static: true })
  public checkupObservationBoxComponent: CheckupObservationBoxComponent;

  @Output() public onObservationAdded = new EventEmitter();

  @Input() public checkupId: number;

  public isOpen = false;

  public loading = false;

  public tabSelected: string = 'observations';

  constructor(private analyticsTrackerService: AnalyticsTrackerService) {}

  public ngOnInit() {}

  public toggleModal() {
    if (this.isOpen) {
      this.closeModal();
    } else {
      this.openObsBox();
    }
  }

  public closeModal() {
    if (this.isOpen) {
      this.analyticsTrackerService.trackEvent(
        AnalyticsTracking.ACTIONS.TOGGLE_MODAL,
        'Close Observations Modal'
      );

      this.checkupObservationBoxComponent.cacheObservationNotSaved();
      this.isOpen = false;
    }
  }

  public openObsBox() {
    if (!this.isOpen) {
      this.analyticsTrackerService.trackEvent(
        AnalyticsTracking.ACTIONS.TOGGLE_MODAL,
        'Open Observations Modal'
      );
      this.isOpen = true;
      this.checkupObservationBoxComponent.loadCheckupObservations();
      this.checkupTagsInputComponent.loadFormData();
    }
  }

  public newObservationAdded() {
    this.checkupObservationBoxComponent.loadCheckupObservations();
    this.onObservationAdded.emit();
  }

  public selectTab(tab: string) {
    this.tabSelected = tab;
  }

  public getTabSelected() {
    return this.tabSelected;
  }

  public onTabClick(event: Event) {
    event.stopPropagation();
  }
}
