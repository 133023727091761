import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { CheckupReviewService } from '../../services/checkup-review/checkup-review.service';
import { CheckupWarningContentNotSavedComponent } from '../../components/checkup-warning-content-not-saved/checkup-warning-content-not-saved.component';
import { AuthService } from '../../services/auth-service/auth.service';

@Component({
  selector: 'app-checkup',
  templateUrl: './checkup.component.html',
  styleUrls: ['./checkup.component.scss'],
})
export class CheckupComponent implements OnDestroy, OnInit {
  @ViewChild('checkupObsModal') public checkupObsModal;

  @ViewChild(CheckupWarningContentNotSavedComponent, { static: true })
  public checkupWarningContentNotSavedModal: CheckupWarningContentNotSavedComponent;

  public checkupId: number;

  private modalConfirmationSubject = new Subject();

  private observationAddedSubject = new Subject<void>();

  constructor(
    public checkupReviewService: CheckupReviewService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  public ngOnInit() {
    this.route.params.subscribe((params) => {
      this.checkupId = +params.checkupId;
    });
  }

  public ngOnDestroy() {
    this.checkupReviewService.reset();
    this.observationAddedSubject.unsubscribe();
    this.modalConfirmationSubject.unsubscribe();
  }

  public canDeactivate() {
    return this.checkUnsavedData();
  }

  public checkUnsavedData() {
    const observationNotSaved = this.checkupReviewService.getCheckupObsLoaded();

    if (
      this.authService.getLoggedInUser() &&
      observationNotSaved &&
      (observationNotSaved.text || observationNotSaved.observationFlags.length)
    ) {
      this.checkupWarningContentNotSavedModal.openModal(
        this.checkupReviewService.getCheckupObsLoaded()
      );
      return this.modalConfirmationSubject.asObservable();
    }
    return true;
  }

  public openObsModal() {
    this.checkupObsModal.openObsBox();
  }

  public listenObsAddedSuccessEvent(): Observable<any> {
    return this.observationAddedSubject.asObservable();
  }

  public openObsModalFromContentNotSaved() {
    this.checkupObsModal.openObsBox();
    this.listenObsAddedSuccessEvent().subscribe(() => this.deactivate());
  }

  public observationAddedSuccessCallback() {
    this.observationAddedSubject.next();
  }

  public continueNavigation() {
    this.modalConfirmationSubject.next(true);
  }

  private deactivate() {
    this.checkupObsModal.closeModal();
    this.continueNavigation();
  }
}
