import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import {
  TeleConsultationMessage,
  TeleConsultationMessageType,
  TeleConsultationUser,
} from './tele-consultation-integration.interface';
import { environment } from '../../../environments/environment';
import { ToastService } from '../toast-service/toast-service.service';
import { UserClass } from '../../class/user/user.class';

@Injectable({
  providedIn: 'root',
})
export class TeleConsultationIntegrationService implements OnDestroy {
  public onBrowserBlockedPopup$ = new Subject<boolean>();

  public app = {
    window: null,
    await$: null,
    impersonateId: null,
    handshake$: new Subject<boolean>(),
    onOpen$: new BehaviorSubject<boolean>(false),
    onLoading$: new BehaviorSubject<boolean>(false),
    onError$: new BehaviorSubject<boolean>(false),
  };

  public teleapp = {
    window: null,
    await$: null,
    handshake$: new Subject<boolean>(),
    onOpen$: new BehaviorSubject<boolean>(false),
    onLoading$: new BehaviorSubject<boolean>(false),
    onError$: new BehaviorSubject<boolean>(false),
  };

  private aspectRatio = 1.7786;

  private handler = null;

  // Flag to control if only the integration should open the mobile app
  private shouldOpenApp = false;

  // data of current call
  private currentWellabeId = null;

  private currentVoucherId = null;

  private isTeleconsultation = null;

  constructor(private toastService: ToastService, public currentUser: UserClass) {
    this.registerHooks();
  }

  public ngOnDestroy() {
    // calling the handler will ensure it disconnects
    if (this.handler) {
      this.handler();
    }
  }

  public async openApps(
    impersonateId: string,
    voucherId: string,
    isTeleconsultation: boolean,
    shouldOpenApp = true
  ) {
    this.shouldOpenApp = shouldOpenApp;

    this.currentWellabeId = impersonateId;
    this.currentVoucherId = voucherId;
    this.isTeleconsultation = isTeleconsultation;

    this.openTeleappWindow(isTeleconsultation);

    if (shouldOpenApp) {
      this.openAppWindow();
    }
  }

  /**
   * Opens app window with correct size
   * If window is already opened, it'll instead send a message to impersonate a different user
   * param wellabeId: the ID of the user to be impersonated
   */
  public async openAppWindow() {
    const link = environment.app;
    const size = this.generateMobileAppWindowSize();

    if (!this.app.onOpen$.getValue()) {
      this.app.window = window.open(
        link,
        'wellabe App',
        `toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=${size.width}, height=${size.height}, top=${size.top}, left=${size.left}`
      );

      if (this.app.window) {
        // this variable will be null if browser has blocked the popup
        this.app.window.resizeTo(size.width, size.height);
        this.app.onOpen$.next(true);

        // creates a timer to check if window has been closed
        const timer = setInterval(() => {
          if (this.app.window.closed) {
            clearInterval(timer);
            this.app.onOpen$.next(false);
            this.app.onLoading$.next(false);
          }
        }, 1000);
      } else {
        this.onBrowserBlockedPopup$.next(true);
        return;
      }
    }

    this.app.onLoading$.next(true);
    this.app.onError$.next(false);

    this.sendMessage(this.app.window, TeleConsultationMessageType.HANDSHAKE, null);

    // unsubscribe from previously running promises
    if (this.app.await$) {
      this.app.await$.unsubscribe();
    }

    this.app.await$ = this.app.handshake$.subscribe(
      () => {
        // send impersonate request
        const data = { wellabeId: this.currentWellabeId };
        this.sendMessage(this.app.window, TeleConsultationMessageType.IMPERSONATE, data);

        this.app.await$.unsubscribe();
      },
      () => {
        this.app.onLoading$.next(false);
        this.app.onError$.next(true);
      }
    );
  }

  public closeAppWindow() {
    if (this.app.window && !this.app.window.closed) {
      this.app.window.close();
    }

    this.app.onLoading$.next(false);
    this.app.onError$.next(false);

    this.app.impersonateId = false;
    this.currentWellabeId = null;
    this.currentVoucherId = null;
    this.isTeleconsultation = null;

    if (this.app.await$) {
      this.app.await$.unsubscribe();
    }
  }

  /**
   * Opens teleapp window with correct size
   * If window is already opened, it'll instead send a message to connect to a different token
   * param wellabeId: the ID of the user to create a room with
   */
  public openTeleappWindow(isTeleconsultation = true) {
    const size = this.generateTeleappWindowSize(this.shouldOpenApp);
    const link = isTeleconsultation
      ? `${environment.teleapp}/doctor/${this.currentWellabeId}`
      : `${environment.teleapp}/doctor/${this.currentVoucherId}?kiosk=true`;

    if (!this.teleapp.onOpen$.getValue()) {
      this.teleapp.window = window.open(
        link,
        'wellabe TeleApp',
        `toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=${size.width}, height=${size.height}, top=${size.top}, left=${size.left}`
      );

      if (this.teleapp.window) {
        // this variable will be null if browser has blocked the popup
        this.teleapp.window.resizeTo(size.width, size.height);
        this.teleapp.onOpen$.next(true);

        // creates a timer to check if window has been closed
        const timer = setInterval(() => {
          if (this.teleapp.window.closed) {
            clearInterval(timer);
            this.teleapp.onOpen$.next(false);
            this.teleapp.onLoading$.next(false);
          }
        }, 1000);
      } else {
        this.onBrowserBlockedPopup$.next(true);
        return;
      }
    }

    this.teleapp.onLoading$.next(true);
    this.teleapp.onError$.next(false);

    this.sendMessage(this.teleapp.window, TeleConsultationMessageType.HANDSHAKE, null);

    // unsubscribe from previously running promises
    if (this.teleapp.await$) {
      this.teleapp.await$.unsubscribe();
    }

    this.teleapp.await$ = this.teleapp.handshake$.subscribe(
      () => {
        // send impersonate request
        const doctorInfo: TeleConsultationUser = {
          name: this.currentUser.fullName,
          imageUrl: this.currentUser.profilePictureMedium,
        };

        const userInfo: TeleConsultationUser = {
          name: this.currentWellabeId,
          imageUrl: null,
        };

        this.sendMessage(this.teleapp.window, TeleConsultationMessageType.DOC_INFO, doctorInfo);
        this.sendMessage(this.teleapp.window, TeleConsultationMessageType.USER_INFO, userInfo);

        // send impersonate request if teleconsultation
        const data = {
          wellabeId: isTeleconsultation ? this.currentWellabeId : this.currentVoucherId,
          isTeleconsultation,
        };
        this.sendMessage(this.teleapp.window, TeleConsultationMessageType.IMPERSONATE, data);

        this.teleapp.onLoading$.next(false);

        this.teleapp.await$.unsubscribe();
      },
      () => {
        this.teleapp.onError$.next(true);
      },
      () => {
        this.teleapp.onLoading$.next(false);
      }
    );
  }

  public closeTeleappWindow() {
    // Event to request teleapp to end the call
    this.sendMessage(this.teleapp.window, TeleConsultationMessageType.HANG_UP, true);

    // Wait for call to be ended
    // TODO: A better approach would be using events to know when the call was ended
    setTimeout(() => {
      if (this.teleapp.window && !this.teleapp.window.closed) {
        this.teleapp.window.close();
      }

      this.teleapp.onOpen$.next(false);
      this.teleapp.onLoading$.next(false);
      this.teleapp.onError$.next(false);
      this.currentWellabeId = null;
      this.currentVoucherId = null;
      this.isTeleconsultation = null;

      if (this.teleapp.await$) {
        this.teleapp.await$.unsubscribe();
      }
    }, 1000);
  }

  /**
   * Auxiliary functions for app's connections
   */
  public getIsAppConnected(): boolean {
    return this.app.onOpen$.getValue() && this.app.impersonateId;
  }

  public getIsTeleconsultation(): boolean {
    return this.isTeleconsultation;
  }

  public getConnectedWellabeId(): string {
    if (this.getIsAppConnected()) {
      return this.app.impersonateId;
    }
    if (this.getIsTeleappConnected()) {
      return this.currentWellabeId;
    }
    return null;
  }

  public getConnectedPackageId(): string {
    if (this.getIsTeleappConnected()) {
      return this.currentVoucherId;
    }
    return null;
  }

  public getConnectedAppImpersonatedId(): string {
    if (this.getIsAppConnected()) {
      return this.app.impersonateId;
    }
    return null;
  }

  public getIsTeleappConnected(): boolean {
    return this.teleapp.onOpen$.getValue();
  }

  public getIsLoading(): boolean {
    return this.app.onOpen$.getValue() && this.teleapp.onLoading$.getValue();
  }

  public getIsError(): boolean {
    return this.app.onError$.getValue();
  }

  public setShouldOpenApp(isAppNeeded: boolean) {
    this.shouldOpenApp = isAppNeeded;
  }

  /**
   * Register message hooks
   */
  private registerHooks() {
    this.handler = window.addEventListener('message', this.messageHandler.bind(this), false);
  }

  /**
   * Handles postWindow incoming messages
   */
  private messageHandler(event) {
    // if it fails to convert to type, ignore message
    const eventData: TeleConsultationMessage = event.data;

    // if handshake was from another window, ignore
    if (event.source !== this.app.window && event.source !== this.teleapp.window) {
      return;
    }

    switch (eventData.type) {
      case TeleConsultationMessageType.HANDSHAKE:
        // handshake received, check source
        if (event.source === this.app.window) {
          this.app.handshake$.next(true);
        } else if (event.source === this.teleapp.window) {
          this.teleapp.handshake$.next(true);
        }
        break;
      case TeleConsultationMessageType.QUERY:
        this.sendMessage(
          this.app.window,
          TeleConsultationMessageType.IMPERSONATE,
          this.app.impersonateId
        );
        break;
      case TeleConsultationMessageType.IMPERSONATE:
        this.app.impersonateId = eventData.data;
        this.app.onLoading$.next(false);
        break;
      case TeleConsultationMessageType.ERROR:
        if (event.source === this.app.window) {
          this.app.onLoading$.next(false);
          this.app.onError$.next(true);
        } else if (event.source === this.teleapp.window) {
          this.teleapp.onError$.next(true);
        }

        this.toastService.error(eventData.data);
        break;
      default:
        break;
    }
  }

  /**
   * Send message to a different window with containing data
   */
  private sendMessage(window: Window, type: TeleConsultationMessageType, data: any) {
    if (window) {
      window.postMessage(this.buildMessageData(type, data), '*');
    }
  }

  /**
   * Build standard data object to be transferred through the message
   */
  private buildMessageData(
    messageType: TeleConsultationMessageType,
    messageData: any
  ): TeleConsultationMessage {
    return {
      type: messageType,
      data: messageData,
    };
  }

  /**
   * Generate the width and height of the mobile app window size
   */
  private generateMobileAppWindowSize() {
    const wWidth = window.screen.availWidth;
    const wHeight = window.screen.availHeight;

    // TODO: Check if is in portrait mode
    let appWidth = wWidth / 3 <= 375 ? wWidth / 3 : wWidth / 3;

    // Keep iPhone 8 aspect ratio
    let appHeight = appWidth * this.aspectRatio;

    // App window is too big, use height to calculate
    if (appHeight > wHeight) {
      appHeight = wHeight;
      appWidth = appHeight / this.aspectRatio;
    }

    return { width: appWidth, height: appHeight, left: 0, top: 0 };
  }

  /**
   * Generate the width and height of the teleapp window size
   */
  private generateTeleappWindowSize(isAppOpened = false) {
    const wWidth = window.screen.width;
    const wHeight = window.screen.height;
    const mobileAppSize = this.generateMobileAppWindowSize();

    return {
      width: isAppOpened ? wWidth - mobileAppSize.width : wWidth,
      height: wHeight,
      left: mobileAppSize.width,
      top: 0,
    };
  }
}
