import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-strip-top-dev-environment',
  templateUrl: './strip-top-dev-environment.component.html',
  styleUrls: ['./strip-top-dev-environment.component.scss'],
})
export class StripTopDevEnvironmentComponent implements OnInit {
  public show: boolean = false;

  public ngOnInit() {
    if (environment.currentEnv === 'development') {
      this.show = true;
    }
  }
}
