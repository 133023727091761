import { Component, OnInit, Renderer2 } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { CheckupClass } from '../../class/checkup/checkup.class';
import { CheckupEmailPreviewApiResponse } from '../../interfaces/checkup-feedback.interface';
import { ToastService } from '../../services/toast-service/toast-service.service';
import { AnalyticsTrackerService } from '../../services/analytics-tracker/analytics-tracker.service';

@Component({
  selector: 'app-checkup-feedback-email-preview',
  templateUrl: './checkup-feedback-email-preview.component.html',
  styleUrls: ['./checkup-feedback-email-preview.component.scss'],
})
export class CheckupFeedbackEmailPreviewComponent implements OnInit {
  public isOpen: boolean = false;

  public messagePreview: string = '';

  public loading: boolean = true;

  public isStaticEmailContent: boolean = false;

  constructor(
    private toastService: ToastService,
    private renderer: Renderer2,
    private analyticsTrackerService: AnalyticsTrackerService
  ) {}

  public ngOnInit() {}

  public openModalEmailPreview(checkup: CheckupClass) {
    this.analyticsTrackerService.trackEvent('Toggle Modal', 'Open E-mail Preview Modal');
    this.isOpen = true;
    this.isStaticEmailContent = false;
    this.loadContent(checkup);
  }

  public openModalEmailSent(emailContent: string) {
    this.analyticsTrackerService.trackEvent('Toggle Modal', 'Open E-mail Preview Modal');
    this.isOpen = true;
    this.isStaticEmailContent = true;
    this.messagePreview = `<p>${emailContent}</p>`;
    this.loading = false;
  }

  public closeModal() {
    this.analyticsTrackerService.trackEvent('Toggle Modal', 'Close E-mail Preview Modal');
    this.isOpen = false;
    // this.loadBodyClass('remove');
  }

  public loadBodyClass(type: string) {
    if (type === 'add') {
      this.renderer.addClass(document.body, 'disable-scroll');
    } else {
      this.renderer.removeClass(document.body, 'disable-scroll');
    }
  }

  private loadContent(checkup: CheckupClass) {
    this.loading = true;
    this.messagePreview = '';

    checkup
      .getCheckupEmailPreview()
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        (res: CheckupEmailPreviewApiResponse) => {
          this.messagePreview = res.message;
        },
        (err) => {
          this.toastService.error(err.message);
        }
      );
  }
}
