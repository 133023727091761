import { Component, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { CheckupClass } from '../../class/checkup/checkup.class';
import { TeleConsultationIntegrationService } from '../../services/tele-consultation-integration/tele-consultation-integration.service';
import { CheckupAppointmentBoxStatus } from './checkup-appointment-box.interface';
import { CheckupPopupBlockedHelpComponent } from '../checkup-popup-blocked-help/checkup-popup-blocked-help.component';

@Component({
  selector: 'app-checkup-appointment-box',
  templateUrl: './checkup-appointment-box.component.html',
  styleUrls: ['./checkup-appointment-box.component.scss'],
})
export class CheckupAppointmentBoxComponent implements OnInit, OnDestroy {
  @Input() public checkup: CheckupClass = null;

  @Input() public openApp = true;

  @ViewChild('popupBlockedHelpComponent', { static: true })
  public popupBlockedHelpComponent: CheckupPopupBlockedHelpComponent;

  public loading: boolean = true;

  public teleIntegrationLoading: boolean = false;

  public appIntegrationLoading = false;

  public onTeleAppLoading$: Subscription;

  public onTeleAppOpen$: Subscription;

  public onAppLoading$: Subscription;

  public onAppOpen$: Subscription;

  public onBrowserBlockedPopup$: Subscription;

  public appOpened: boolean = false;

  public appWindow: Window;

  public STATUS = CheckupAppointmentBoxStatus;

  public currentStatus: CheckupAppointmentBoxStatus;

  public currentPackageId: string = null;

  public currentWellabeId: string = null;

  public isTeleconsultation: boolean = null;

  constructor(private integrationService: TeleConsultationIntegrationService) {}

  public ngOnInit() {
    this.setStatus();

    this.onTeleAppLoading$ = this.integrationService.teleapp.onLoading$.subscribe((isLoading) => {
      this.teleIntegrationLoading = isLoading;
      this.setStatus();
    });

    this.onTeleAppOpen$ = this.integrationService.teleapp.onOpen$.subscribe(() => {
      this.setStatus();
    });

    this.onAppOpen$ = this.integrationService.app.onOpen$.subscribe((isOpen) => {
      this.appOpened = isOpen;
    });

    this.onAppLoading$ = this.integrationService.app.onLoading$.subscribe((isLoading) => {
      this.appIntegrationLoading = isLoading;
      if (this.openApp) {
        this.setStatus();
      }
    });

    this.onBrowserBlockedPopup$ = this.integrationService.onBrowserBlockedPopup$.subscribe(
      (isBlocked) => {
        if (isBlocked) {
          // close both windows
          this.integrationService.closeAppWindow();
          this.integrationService.closeTeleappWindow();

          // show help dialog
          this.showBlockedPopupHelp();
        }
      }
    );
  }

  public ngOnDestroy() {
    this.onAppLoading$.unsubscribe();
    this.onAppOpen$.unsubscribe();
    this.onTeleAppOpen$.unsubscribe();
    this.onTeleAppLoading$.unsubscribe();
    this.onBrowserBlockedPopup$.unsubscribe();
  }

  public loadingComplete() {
    // wait for checkup details to propagate
    setTimeout(() => {
      this.setStatus();
      this.loading = false;
    }, 0);
  }

  public startCall() {
    this.integrationService.openApps(
      this.checkup.wellabeId,
      this.checkup.voucher,
      this.checkup.hasTeleconsultation,
      this.openApp
    );
  }

  public openMobileApp() {
    this.integrationService.openAppWindow();
  }

  public hungUp() {
    this.integrationService.closeAppWindow();
    this.integrationService.closeTeleappWindow();
  }

  private showBlockedPopupHelp() {
    this.popupBlockedHelpComponent.open();
  }

  private setStatus() {
    const connectedId = this.integrationService.getConnectedWellabeId();
    const connectedPackage = this.integrationService.getConnectedPackageId();
    const isTeleconsultation = this.integrationService.getIsTeleconsultation();

    if (!this.checkup.hasTeleconsultation) {
      // if it is a kiosk checkup
      if (!connectedPackage) {
        this.currentStatus = CheckupAppointmentBoxStatus.READY_KIOSK;
      } else if (connectedPackage === this.checkup.voucher) {
        if (connectedId !== this.checkup.wellabeId) {
          this.currentStatus = CheckupAppointmentBoxStatus.CONNECTED_SAME_KIOSK_DIFFERENT_USER;
        } else {
          this.currentStatus = CheckupAppointmentBoxStatus.CONNECTED_SAME_KIOSK_SAME_USER;
        }
      } else {
        this.currentStatus = CheckupAppointmentBoxStatus.CONNECTED_DIFFERENT_KIOSK;
      }
    } else {
      // if it is a teleconsultation checkup
      // eslint-disable-next-line no-lonely-if
      if (!connectedId) {
        this.currentStatus = CheckupAppointmentBoxStatus.READY;
      } else if (connectedId === this.checkup.wellabeId) {
        this.currentStatus = CheckupAppointmentBoxStatus.CONNECTED_SAME_USER;
      } else {
        this.currentStatus = CheckupAppointmentBoxStatus.CONNECTED_DIFFERENT_USER;
      }
    }

    // update current wellabe ID and package
    this.currentPackageId = connectedPackage;
    this.currentWellabeId = connectedId;
    this.isTeleconsultation = isTeleconsultation;
  }
}
