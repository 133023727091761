import { NgModule } from '@angular/core';
import { PageNotFoundComponent } from './page-not-found.component';
import { routing } from './page-not-found.routing';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [SharedModule, routing],
  declarations: [PageNotFoundComponent],
  exports: [PageNotFoundComponent],
})
export class PageNotFoundModule {}
