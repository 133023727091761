import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TranslationService } from '../translation/translation.service';
import { Notification, NotificationType } from './toast-service.interface';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  public subject = new Subject<Notification>();

  constructor(private translationService: TranslationService) {}

  public listenEvents(): Observable<any> {
    return this.subject.asObservable();
  }

  public success(message: string) {
    this.triggerShowNotificationEvent(
      NotificationType.SUCCESS,
      message,
      this.translationService.getInstantTranslation('GENERAL.SUCCESS')
    );
  }

  public error(message: string) {
    this.triggerShowNotificationEvent(
      NotificationType.ERROR,
      message,
      this.translationService.getInstantTranslation('GENERAL.OOPS')
    );
  }

  public warning(message: string) {
    this.triggerShowNotificationEvent(
      NotificationType.WARNING,
      message,
      this.translationService.getInstantTranslation('GENERAL.ALERT')
    );
  }

  public triggerShowNotificationEvent(type: NotificationType, message: string, title: string) {
    /* tslint:disable:no-object-literal-type-assertion */
    this.subject.next({
      type,
      description: message,
      title,
      effect: 'fade-in-effect',
      id: new Date().getTime(),
    } as Notification);
  }
}
