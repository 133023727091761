<app-menu-results></app-menu-results>

<router-outlet name="checkup"></router-outlet>

<app-checkup-warning-content-not-saved #checkupWarningContentNotSavedModal
  (closeModalAction)="continueNavigation()" (editObsAction)="openObsModalFromContentNotSaved()">
</app-checkup-warning-content-not-saved>

<app-checkup-observation-and-tags-modal *ngIf="checkupReviewService.initialized$ | async"
  #checkupObsModal [checkupId]="checkupId" (onObservationAdded)="observationAddedSuccessCallback()">
</app-checkup-observation-and-tags-modal>