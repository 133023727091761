import { Component, OnInit, Input } from '@angular/core';
import { CheckupApiServiceCheckupReadingsInfoResponse } from '../../services/api-checkup/api-checkup.interface';

@Component({
  selector: 'app-checkup-biomarkers-box',
  templateUrl: './checkup-biomarkers-box.component.html',
  styleUrls: ['./checkup-biomarkers-box.component.scss'],
})
export class CheckupBiomarkersBoxComponent implements OnInit {
  @Input() public resultsByGroup: CheckupApiServiceCheckupReadingsInfoResponse[] = [];

  @Input() public currentPerformedAt: string;

  @Input() public previousPerformedAt: string;

  public loading: boolean = true;

  public ngOnInit() {}

  public loadingComplete() {
    this.loading = false;
  }
}
