<div class="modal" [style.display]="isOpen ? 'flex' : 'none'">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="panel">
          <div class="content">
            <div class="close" (click)="closeModal()">
              <i class="u-icon ion-md-close"></i>
            </div>
            <div *ngIf="isStaticEmailContent">
              <h2>{{ 'CHECKUP.PREVIOUS.EMAIL_SENT_TITLE' | translate }}</h2>
              <p>{{ 'CHECKUP.PREVIOUS.EMAIL_SENT_DESCRIPTION' | translate }}</p>
            </div>

            <div *ngIf="!isStaticEmailContent">
              <h2>{{ 'CHECKUP.FEEDBACK.EMAIL_PREVIEW_TITLE' | translate }}</h2>
              <p>{{ 'CHECKUP.FEEDBACK.EMAIL_PREVIEW_DESCRIPTION' | translate }}</p>
            </div>

            <div class="email-preview">
              <div *ngIf="!loading" class="message" [innerHTML]="messagePreview"></div>
              <div class="loading" *ngIf="loading">
                <span>
                  <img src="./assets/images/button-loading.gif" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>