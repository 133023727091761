import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-results',
  templateUrl: './menu-results.component.html',
  styleUrls: ['./menu-results.component.scss'],
})
export class MenuResultsComponent implements OnInit {
  public menuItems: any = {
    results: { order: 1, title: 'RESULTS', checked: false, clickable: false },
    challenges: { order: 2, title: 'JOURNEYS', checked: false, clickable: false },
    feedback: { order: 3, title: 'FEEDBACK', checked: false, clickable: false },
  };

  constructor(private route: Router) {
    this.listenRouteChange();
  }

  public ngOnInit() {}

  private listenRouteChange() {
    this.route.events.subscribe((routeInfo: any) => {
      this.focusTopPage();
      this.checkMenuStatus(routeInfo.url);
    });
  }

  private focusTopPage() {
    window.scrollTo(0, 0);
  }

  private checkMenuStatus(currentUrl: string) {
    const tmpRoutePieces: string[] = currentUrl !== undefined ? currentUrl.split('/') : null;

    if (!tmpRoutePieces || !tmpRoutePieces[5]) {
      return;
    }

    const resultMenuPieceWithoutHash: string = tmpRoutePieces[5].split('#')[0];

    this.menuItems.results.checked = false;
    this.menuItems.challenges.checked = false;
    this.menuItems.feedback.checked = false;

    switch (resultMenuPieceWithoutHash) {
      case '(checkup:challenges)':
        this.menuItems.results.checked = true;

        this.menuItems.results.clickable = true;
        this.menuItems.challenges.clickable = true;
        break;
      case '(checkup:feedback)':
        this.menuItems.results.checked = true;
        this.menuItems.challenges.checked = true;

        this.menuItems.results.clickable = true;
        this.menuItems.challenges.clickable = true;
        this.menuItems.feedback.clickable = true;
        break;
      default:
        break;
    }
  }
}
