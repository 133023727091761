<div class="modal" [style.display]="isOpen ? 'flex' : 'none'">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="panel">
          <div class="content">
            <div class="close" (click)="close()">
              <i class="u-icon ion-md-close"></i>
            </div>
            <i class="fa fa-exclamation-triangle warning-icon"></i>
            <div>
              <h2>
                {{'CHECKUP.INTEGRATION.POPUP_BLOCKED_HEADER'| translate}}
              </h2>
              <p>
                {{'CHECKUP.INTEGRATION.POPUP_BLOCKED_MESSAGE'| translate}}
              </p>

              <img class="instruction-image instruction-image--chrome"
                src="./assets/images/permissions/{{this.lang}}/blocked-popup-instructions.png">

              <img class="instruction-image instruction-image--firefox"
                src="./assets/images/permissions/{{this.lang}}/blocked-popup-firefox.png">

              <div class="row">
                <div class="c-btn c-btn__secondary btn-container" (click)="close()">
                  <a>{{ 'GENERAL.CANCEL' | translate }}</a>
                </div>
                <div class="c-btn btn-container" (click)="retry()">
                  <a>{{ 'GENERAL.RETRY' | translate}}</a>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>