<div class="observations">
  <div class="title">
    {{ 'CHECKUP.INFO.LAB_OBSERVATIONS' | translate }}
    <a (click)="openObservationsModal()" *ngIf="hasPreviousLabObs">
      {{ 'CHECKUP.INFO.LAB_OBSERVATIONS_VIEW_MORE' | translate }}
    </a>
  </div>
  <div class="value">
    <p class="observation" *ngFor="let observation of labObservations">
      <span class="italic">{{ observation.text }}</span>

      <span *ngFor="let flag of observation.observationFlags" class="tooltip tooltip--flags">
        <span class="tooltiptext">{{ getFlagPhrase(flag) | translate }}</span>
        <img [src]="'./assets/images/' + flag + '.svg'">
      </span>

      <span class="tooltip">
        <div class="tooltiptext">
          {{ observation.staffFullName }}
          <br>
          <span>
            {{observation.createdAt | date:'MMMM dd, yyyy'}}
          </span>
        </div>
        <i class="fa fa-info-circle"></i>
      </span>
    </p>
    <p *ngIf="labObservations.length === 0">{{ 'GENERAL.NA' | translate }}</p>
  </div>
</div>