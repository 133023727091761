import { NgModule } from '@angular/core';
import { CheckupComponent } from './checkup.component';
import { routing } from './checkup.routing';
import { SharedModule } from '../../shared/shared.module';

import { MenuResultsComponent } from '../../components/menu-results/menu-results.component';
import { CanDeactivateGuardService } from '../../services/can-deactivate-guard/can-deactivate-guard.service';

@NgModule({
  imports: [SharedModule, routing],
  declarations: [CheckupComponent, MenuResultsComponent],
  exports: [CheckupComponent, MenuResultsComponent],
  providers: [CanDeactivateGuardService],
})
export class CheckupModule {}
