<div class="container">
  <div>
    <app-checkup-challenges-box #challengesBox [challengeIncludedForCheckup]="true"
      [checkup]="checkup" [checkupChallenge]="checkupChallenge" (onSaving)="onSaving()"
      (onSaved)="onSaved()"></app-checkup-challenges-box>
    <div class="buttons">
      <div class="buttons-cancel">
        <button class="c-btn c-btn__secondary" [routerLink]="'../results'" routerLinkActive="active"
          queryParamsHandling="preserve">
          {{ 'GENERAL.GO_BACK' | translate }}
        </button>
      </div>
      <app-checkup-next-step-button class="buttons-next" *ngIf="checkup"
        [isTeleconsultation]="checkup.hasTeleconsultation" buttonTitle="GENERAL.PROCEED"
        nextStep="feedback" [loading]="loading"></app-checkup-next-step-button>
    </div>
  </div>
</div>