import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  ApiStaffProfileInfoResponse,
  ApiStaffPutRequest,
  ApiStaffPutPasswordRequest,
} from './api-staff.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiStaffService {
  constructor(private http: HttpClient) {}

  public putProfile(profileInfo: ApiStaffPutRequest) {
    return this.http.put<ApiStaffProfileInfoResponse>(
      `${environment.api}/staff/profile`,
      profileInfo
    );
  }

  public putPassword(passwordBody: ApiStaffPutPasswordRequest) {
    return this.http.put<any[]>(`${environment.api}/staff/password`, passwordBody);
  }

  public getProfile() {
    return this.http.get<ApiStaffProfileInfoResponse>(`${environment.api}/staff/profile`);
  }
}
