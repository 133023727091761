import { Component, Input } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { CheckupClass } from '../../class/checkup/checkup.class';

@Component({
  selector: 'app-checkup-user-contact-card',
  templateUrl: './checkup-user-contact-card.component.html',
  styleUrls: ['./checkup-user-contact-card.component.scss'],
})
export class CheckupUserContactCardComponent {
  @Input() checkup: CheckupClass;

  public loading = true;

  public copiedToClipboard = {
    phone: false,
    email: false,
  };

  constructor(private clipboardService: ClipboardService) {}

  public loadingComplete() {
    this.loading = false;
  }

  public copyToClipboard(type: 'phone' | 'email', content: string) {
    this.clipboardService.copyFromContent(content);

    // Toggle the success state, used to indicate on the UI that the field was copied
    this.copiedToClipboard[type] = true;
    setTimeout(() => {
      this.copiedToClipboard[type] = false;
    }, 2000);
  }
}
