import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiStaffService } from '../../services/api-staff/api-staff.service';
import {
  ApiStaffProfileInfoResponse,
  ApiStaffPutRequest,
  ApiStaffPutPasswordRequest,
} from '../../services/api-staff/api-staff.interface';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { TranslationService } from '../../services/translation/translation.service';
import { AnalyticsTrackerService } from '../../services/analytics-tracker/analytics-tracker.service';

@Injectable({
  providedIn: 'root',
})
export class UserClass {
  public firstName: string;

  public lastName: string;

  public email: string;

  public languageId: string;

  public phoneCountryCode: number;

  public phoneNumber: string;

  public timezone: string;

  public staffId: number;

  public fullName: string;

  public profilePictureOriginal: string;

  public profilePictureSmall: string;

  public profilePictureMedium: string;

  public profilePictureLarge: string;

  public languageLocale: string;

  constructor(
    public apiStaffService: ApiStaffService,
    public localStorageService: LocalStorageService,
    public translationService: TranslationService,
    public analyticsTrackerService: AnalyticsTrackerService
  ) {}

  public submitUserInfo() {
    const bodyPayload: ApiStaffPutRequest = {
      first_name: this.firstName,
      last_name: this.lastName,
      phone_country_code: this.phoneCountryCode,
      phone_number: this.phoneNumber,
      language_id: this.languageId,
      timezone: this.timezone,
      profile_picture: this.profilePictureOriginal,
    };

    return this.apiStaffService.putProfile(bodyPayload).pipe(
      map((userInfoUpdated: ApiStaffProfileInfoResponse) => {
        this.loadUserInfo(userInfoUpdated, false);
      })
    );
  }

  public updatePassword(
    currentPassword: string,
    newPassword: string,
    newPasswordConfirmation: string
  ) {
    const passwordBody: ApiStaffPutPasswordRequest = {
      current_password: currentPassword,
      new_password: newPassword,
      new_password_confirmation: newPasswordConfirmation,
    };

    return this.apiStaffService.putPassword(passwordBody);
  }

  public getUserInfo() {
    // console.log('[getUserInfo(]');

    return this.apiStaffService.getProfile().pipe(
      map((staffInfo: ApiStaffProfileInfoResponse) => {
        this.loadUserInfo(staffInfo);
      })
    );
  }

  public loadUserInfoFromCache() {
    const userInfoFromCache: ApiStaffProfileInfoResponse = this.localStorageService.get('userInfo');

    if (userInfoFromCache && Object.keys(userInfoFromCache).length !== 0) {
      this.loadUserInfo(userInfoFromCache);
      return true;
    }

    return false;
  }

  public loadUserInfo(staffInfo: ApiStaffProfileInfoResponse, firstTime: boolean = true) {
    // console.log('[loadUserInfo]', staffInfo);

    this.firstName = staffInfo.first_name;
    this.lastName = staffInfo.last_name;
    this.email = staffInfo.email;
    this.languageId = staffInfo.language_id;
    this.languageLocale = staffInfo.language !== undefined ? staffInfo.language.locale : 'en';
    this.phoneCountryCode = staffInfo.phone_country_code;
    this.phoneNumber = staffInfo.phone_number;
    this.timezone = staffInfo.timezone;
    this.staffId = staffInfo.staff_id;
    this.fullName = staffInfo.full_name;
    this.profilePictureSmall = staffInfo.profile_picture_small;
    this.profilePictureMedium = staffInfo.profile_picture_medium;
    this.profilePictureLarge = staffInfo.profile_picture_large;

    this.translationService.setCurrentLanguage(this.languageLocale);
    this.localStorageService.set('userInfo', staffInfo);

    if (firstTime) {
      this.analyticsTrackerService.setUserId(this.fullName);
    }
  }
}

/* tslint:disable:ban-types */
export function loadUserInfoFromCache(userClass: UserClass): Function {
  return () => {
    return new Promise((resolve) => {
      userClass.loadUserInfoFromCache();
      resolve(true);
    });
  };
}
