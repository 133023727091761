<div class="observation" [class.last-item]="isLastItem" [class.from-lab]="fromLab">
  <img *ngIf="staffProfilePictureSmall" [src]="staffProfilePictureSmall" [alt]="staffFullName"
    class="avatar" />

  <div class="observation-content">
    <div class="observation-cm-badge-wrapper" *ngIf="fromLab">
      <span class="observation-cm-badge">{{ 'OBSERVATIONS_BADGE' | translate }}</span>
    </div>

    <div class="observation-text">
      <p>{{ text }}</p>
    </div>

    <div class="observation-info">
      <span class="observation-author" title="{{ staffFullName }}">
        {{ staffFullName }}
      </span>

      <div *ngFor="let flag of flags" class="tooltip">
        <span class="tooltiptext">{{ getFlagPhrase(flag) | translate }}</span>
        <img class="quality-related-icon" [src]="'./assets/images/' + flag + '.svg'" />
      </div>

      <div class="tooltip">
        <span *ngIf="checkupOrder"
          class="tooltiptext tooltiptext--small">{{ 'Checkup: ' + checkupOrder }}</span>
        <span>{{ createdAt }}</span>
      </div>
    </div>
  </div>
</div>