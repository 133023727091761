/* eslint-disable max-classes-per-file */
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ApiCheckupService } from '../../services/api-checkup/api-checkup.service';
import {
  CheckupApiServiceCheckupInfoResponse,
  CheckupApiServiceCheckupInfoResponseTags,
  CheckupApiServiceCheckupPreviousInfoResponse,
  CheckupApiServiceCheckupReadingsInfoResponse,
  CheckupApiServiceCheckupQuestionResponse,
  CheckupApiServiceCalculatedReadingsInfoResponse,
} from '../../services/api-checkup/api-checkup.interface';
import {
  CheckupReviewPostBody,
  CheckupReviewPutBody,
  CheckupEmailPreviewPutBody,
} from '../../interfaces/checkup-feedback.interface';
import { CheckupTrackingInfo } from '../../interfaces/checkup.interface';

@Injectable({
  providedIn: 'root',
})
export class CheckupFactory {
  constructor(private apiCheckupService: ApiCheckupService) {}

  public createCheckup() {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return new CheckupClass(this.apiCheckupService);
  }
}

export class CheckupClass {
  public date: string;

  public isThisYear: boolean;

  public isToday: boolean;

  public time: string;

  public ageAtCheckup: number;

  public checkupId: number;

  public packageId: number;

  public status: string;

  public statusLabel: string;

  public performed: boolean = false;

  public userSex: number;

  public userHeight: number;

  public userWeight: string;

  public userInMedicalTreatment: boolean = false;

  public userHasDiabetes: boolean = false;

  public userIsPregnant: boolean = false;

  public userHasPacemaker: boolean = false;

  public userLanguage: string = '';

  public userId: number;

  public voucher: string = '';

  public clientName: string = '';

  public userName: string = '';

  public userEmail: string = '';

  public userPhoneNumber: string = '';

  public token: string;

  public biomarkersResultsByGroups: CheckupApiServiceCheckupReadingsInfoResponse[] = [];

  public calculatedBiomarkersResultsByGroups: CheckupApiServiceCalculatedReadingsInfoResponse[] =
    [];

  public hoursLastExercise: number;

  public hoursLastMeal: number;

  public recentIntenseExercise: boolean = false;

  public reviewed: boolean = false;

  public wellabeId: string;

  public riskFlagged: boolean = false;

  public riskAccepted: boolean = false;

  public observationsCount: number = 0;

  public reviewScore: number;

  public reviewOptions: string[] = [];

  public reviewMessage: string;

  public showYellowWarning: boolean = false;

  public showRedWarning: boolean = false;

  public recentlyAvailable: boolean = false;

  public healthConditionUnknownByUser: boolean;

  public allowContact: boolean = false;

  public type: string;

  public healthScore: number = 0;

  public willingnessToChange: number = null;

  public trackingInfo: CheckupTrackingInfo = { general: {}, metrics: [] };

  public previousCheckup: CheckupApiServiceCheckupPreviousInfoResponse = null;

  public checkupTags: CheckupApiServiceCheckupInfoResponseTags[] = [];

  public questionnaire: CheckupApiServiceCheckupQuestionResponse[] = [];

  public noShow: boolean = false;

  public createdByLab: boolean = false;

  public manuallyCancelled: boolean = false;

  public performedAt: string = '';

  public smokingStatus: number = null;

  public hasTeleconsultation = false;

  public teleconsultationPhone: string = '';

  public teleconsultationReason: string = '';

  constructor(private apiCheckupService: ApiCheckupService) {}

  public getCheckup() {
    return this.apiCheckupService.getCheckup(this.checkupId).pipe(
      tap((checkupInfo: CheckupApiServiceCheckupInfoResponse) => {
        this.loadCheckup(checkupInfo);
      })
    );
  }

  public getCheckupReadingsFromApi() {
    return this.apiCheckupService.getCheckupReadings(this.checkupId).pipe(
      tap((checkupReadings: CheckupApiServiceCheckupReadingsInfoResponse[]) => {
        this.loadCheckupReadings(checkupReadings);
      })
    );
  }

  public getCheckupCalculatedReadingsFromApi() {
    return this.apiCheckupService.getCheckupCalculatedReadings(this.userId).pipe(
      tap((checkupReadings: CheckupApiServiceCalculatedReadingsInfoResponse[]) => {
        this.loadCheckupCalculatedReadings(checkupReadings);
      })
    );
  }

  public getCheckupQuestionsFromApi() {
    return this.apiCheckupService.getCheckupQuestions(this.checkupId).pipe(
      tap((checkupQuestions: CheckupApiServiceCheckupQuestionResponse[]) => {
        this.loadCheckupQuestions(checkupQuestions);
      })
    );
  }

  public submitCheckupReview() {
    return this.reviewed ? this.putCheckupReview() : this.postCheckupReview();
  }

  public submitCheckupPerformed() {
    return this.apiCheckupService.postCheckupPerformed(this.checkupId);
  }

  public postCheckupReview() {
    const reviewScoreFormatted: CheckupReviewPostBody = {
      review_score: this.reviewScore,
      review_message: this.reviewMessage,
      review_options: this.reviewOptions,
      risk_flagged: this.riskFlagged,
      health_condition_unknown_by_user: this.healthConditionUnknownByUser,
      allow_contact: this.allowContact,
      health_score: this.healthScore,
      willingness_to_change: this.willingnessToChange,
    };

    return this.apiCheckupService.postCheckupReview(this.checkupId, reviewScoreFormatted);
  }

  public putCheckupReview() {
    const reviewScoreFormatted: CheckupReviewPutBody = {
      risk_flagged: this.riskFlagged,
      health_condition_unknown_by_user: this.healthConditionUnknownByUser,
      allow_contact: this.allowContact,
      health_score: this.healthScore,
      willingness_to_change: this.willingnessToChange,
    };

    return this.apiCheckupService.putCheckupReview(this.checkupId, reviewScoreFormatted);
  }

  public getCheckupTagsId() {
    const tagsIds: number[] = [];

    this.checkupTags.forEach((currentTag: { tag_id: number; tag: string }) => {
      tagsIds.push(currentTag.tag_id);
    });

    return tagsIds;
  }

  public putCheckupTags() {
    return this.apiCheckupService.putCheckupTags(this.checkupId, {
      tags: this.getCheckupTagsId(),
    });
  }

  public getCheckupEmailPreview() {
    const checkupEmailPreviewBody: CheckupEmailPreviewPutBody = {
      review_score: this.reviewScore,
      review_message: this.reviewMessage,
      review_options: this.reviewOptions,
    };

    return this.apiCheckupService.getCheckupEmailPreview(this.checkupId, checkupEmailPreviewBody);
  }

  public getPdfReport() {
    return this.apiCheckupService.getCheckupReport(this.checkupId);
  }

  public getFiles() {
    return this.apiCheckupService.getFiles(this.checkupId);
  }

  public getFile(filename: string) {
    return this.apiCheckupService.getFile(this.checkupId, filename);
  }

  public loadCheckupReadings(checkupReadings: CheckupApiServiceCheckupReadingsInfoResponse[]) {
    this.biomarkersResultsByGroups = checkupReadings;
  }

  public loadCheckupCalculatedReadings(
    checkupReadings: CheckupApiServiceCalculatedReadingsInfoResponse[]
  ) {
    this.calculatedBiomarkersResultsByGroups = checkupReadings;
  }

  public loadCheckupQuestions(checkupQuestions: CheckupApiServiceCheckupQuestionResponse[]) {
    this.questionnaire = checkupQuestions;
  }

  public getCheckupReadings() {
    if (this.biomarkersResultsByGroups[0] === undefined) {
      return null;
    }
    return this.biomarkersResultsByGroups;
  }

  public getCheckupCalculatedReadings() {
    if (this.calculatedBiomarkersResultsByGroups[0] === undefined) {
      return null;
    }
    return this.calculatedBiomarkersResultsByGroups;
  }

  public getCheckupQuestions() {
    if (this.questionnaire[0] === undefined) {
      return null;
    }
    return this.questionnaire;
  }

  public loadCheckup(currentCheckup: CheckupApiServiceCheckupInfoResponse) {
    this.checkupId = currentCheckup.checkup_id;
    this.ageAtCheckup = currentCheckup.age_at_checkup;
    this.wellabeId = currentCheckup.user.wellabe_id;
    this.userSex = currentCheckup.user.sex;
    this.userHeight = currentCheckup.user.height;
    this.userWeight = currentCheckup.user.weight;
    this.userInMedicalTreatment = currentCheckup.in_medical_treatment;
    this.userHasDiabetes = currentCheckup.has_diabetes;
    this.userIsPregnant = currentCheckup.is_pregnant;
    this.userHasPacemaker = currentCheckup.has_pacemaker;
    this.userId = currentCheckup.user.user_id;
    this.userName = currentCheckup.user.name;
    this.userEmail = currentCheckup.user.email;
    this.userPhoneNumber = currentCheckup.user.phone_number;
    this.voucher = currentCheckup.package.voucher;
    this.clientName = currentCheckup.package.client.name;
    this.hoursLastExercise = currentCheckup.hours_last_exercise;
    this.hoursLastMeal = currentCheckup.hours_last_meal;
    this.recentIntenseExercise = currentCheckup.recent_intense_exercise;
    this.reviewed = currentCheckup.reviewed;
    this.userLanguage = currentCheckup.user.language.name;
    this.reviewScore = currentCheckup.review_score;
    this.reviewMessage = currentCheckup.review_message;
    this.reviewOptions = currentCheckup.review_options ? currentCheckup.review_options : [];
    this.riskFlagged = currentCheckup.appointment ? currentCheckup.appointment.risk_flagged : null;
    this.healthConditionUnknownByUser = currentCheckup.health_condition_unknown_by_user;
    this.healthScore = currentCheckup.health_score || 0;
    this.willingnessToChange = currentCheckup.willingness_to_change;
    this.allowContact = currentCheckup.allow_contact || false;
    this.previousCheckup = currentCheckup.previous_checkup;
    this.performedAt = currentCheckup.performed_at;
    this.smokingStatus = currentCheckup.smoking_status;
    this.hasTeleconsultation = currentCheckup.package.appointment_type !== 'checkup';

    if (currentCheckup.teleconsultation) {
      this.teleconsultationPhone = currentCheckup.teleconsultation.teleconsultation_phone;
      this.teleconsultationReason = currentCheckup.teleconsultation.teleconsultation_reason;
    }

    if (currentCheckup.appointment) {
      this.checkupTags = currentCheckup.appointment.tags || [];
    } else {
      this.checkupTags = [];
    }
  }

  public getSexString(): string {
    switch (this.userSex) {
      case 0:
        return 'NOT_KNOWN';
      case 1:
        return 'MALE';
      case 2:
        return 'FEMALE';
      case 9:
      default:
        return 'NOT_APPLICABLE';
    }
  }
}
