/* eslint-disable max-classes-per-file */
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { tap, map } from 'rxjs/operators';
import { ApiCheckupObservationsService } from '../../services/api-checkup-observations/api-checkup-observations.service';
import { ApiCheckupObservationsServiceObservationInfoResponse } from '../../services/api-checkup-observations/api-checkup-observations.interface';
import { ObservationFlag } from './checkup-obs.interface';

@Injectable({
  providedIn: 'root',
})
export class CheckupObsFactory {
  constructor(private apiCheckupObservationsService: ApiCheckupObservationsService) {}

  public createCheckupObs() {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return new CheckupObsClass(this.apiCheckupObservationsService);
  }
}

export class CheckupObsClass {
  public text: string;

  public observationFlags: ObservationFlag[] = [];

  public checkupId: number;

  public checkupObservationId: number;

  public staffFullName: string;

  public staffProfilePictureSmall: string;

  public createdAt: string;

  public fromLab: boolean;

  public observationsList: CheckupObsClass[] = [];

  public userId: number;

  constructor(private apiCheckupObservationsService: ApiCheckupObservationsService) {}

  public reset() {
    this.text = null;
    this.checkupObservationId = null;
    this.staffProfilePictureSmall = null;
    this.staffFullName = null;
    this.createdAt = null;
    this.fromLab = null;
    this.observationFlags = [];
  }

  public getObservationsFromCheckup() {
    return this.apiCheckupObservationsService.getUserObservations(this.userId).pipe(
      map((observationsList: ApiCheckupObservationsServiceObservationInfoResponse[]) => {
        this.loadObservations(observationsList);
      })
    );
  }

  public loadObservations(
    observationsList: ApiCheckupObservationsServiceObservationInfoResponse[]
  ) {
    this.observationsList = [];

    observationsList.forEach(
      (observation: ApiCheckupObservationsServiceObservationInfoResponse) => {
        this.observationsList.push(this.createCheckupObsInstance(observation));
      }
    );

    return this.observationsList;
  }

  public addCheckupObsToList(checkupObs: CheckupObsClass) {
    this.observationsList.push(checkupObs);
  }

  public createCheckupObsInstance(
    observation: ApiCheckupObservationsServiceObservationInfoResponse
  ) {
    const currentObs: CheckupObsClass = new CheckupObsClass(this.apiCheckupObservationsService);
    currentObs.loadObs(observation);

    return currentObs;
  }

  public loadObs(observation: ApiCheckupObservationsServiceObservationInfoResponse) {
    this.text = observation.text;
    this.observationFlags = [];

    if (observation.quality_related) {
      this.observationFlags.push('quality_related');
    }
    if (observation.special_case) {
      this.observationFlags.push('special_case');
    }

    this.checkupObservationId = observation.checkup_observation_id;
    this.staffFullName = observation.staff.full_name;
    this.staffProfilePictureSmall = observation.staff.profile_picture_small;
    this.fromLab = observation.from_lab;
    this.checkupId = observation.appointment?.checkup?.checkup_id ?? this.checkupId;
    this.createdAt = moment(observation.created_at).format('DD MMM YYYY');
  }

  public submitCheckupObservation() {
    // tslint:disable-next-line:prefer-const
    const body = {
      text: this.text,
    };
    for (const flag of this.observationFlags) {
      body[flag] = true;
    }

    return this.apiCheckupObservationsService.postCheckupObservation(this.checkupId, body).pipe(
      tap((checkupObsCreated: ApiCheckupObservationsServiceObservationInfoResponse) => {
        this.loadObs(checkupObsCreated);
      })
    );
  }
}
