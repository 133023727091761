import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler, Injector } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { AppComponent } from './app.component';

//= =======================================================================
// INTERCEPTORS
//= =======================================================================

import { ApiInterceptor } from './interceptors/api.interceptor';

//= =======================================================================
// SERVICES
//= =======================================================================

import { TranslationService, initTranslations } from './services/translation/translation.service';
import { CookieConsentService } from './services/cookie-consent/cookie-consent.service';

//= =======================================================================
// ROUTES
//= =======================================================================

import { AppRoutingModule } from './routes/app-routing.module';

//= =======================================================================
// PAGES
//= =======================================================================

import { CheckupModule } from './pages/checkup/checkup.module';
import { CheckupResultsModule } from './pages/checkup-results/checkup-results.module';
import { CheckupChallengesModule } from './pages/checkup-challenges/checkup-challenges.module';
import { PageNotFoundModule } from './pages/page-not-found/page-not-found.module';
import { CheckupFeedbackModule } from './pages/checkup-feedback/checkup-feedback.module';

// import { CheckupQuestionsComponent } from './pages/checkup-questions/checkup-questions.component';

//= =======================================================================
// COMPONENTS
//= =======================================================================

import { MenuComponent } from './components/menu/menu.component';
import { LogoutModalComponent } from './components/logout-modal/logout-modal.component';
import { ToastMessagesComponent } from './components/toast-messages/toast-messages.component';
import { StripTopDevEnvironmentComponent } from './components/strip-top-dev-environment/strip-top-dev-environment.component';
import { FooterComponent } from './components/footer/footer.component';

// import { CheckupQuestionsModalComponent } from './components/checkup-questions-modal/checkup-questions-modal.component';
// import { CheckupQuestionCardComponent } from './components/checkup-question-card/checkup-question-card.component';
// import { CheckupQuestionsBoxComponent } from './components/checkup-questions-box/checkup-questions-box.component';

//= =======================================================================
// Classes
//= =======================================================================

import { UserClass, loadUserInfoFromCache } from './class/user/user.class';

//= ==========================================================================
// Directives (the ones called from app.component needs to be included here)
//= ==========================================================================

import { ClickOutsideDirective } from './directives/click-outside.directive';
import {
  RollbarErrorHandler,
  rollbarFactory,
  RollbarService,
} from './services/rollbar/rollbar.service';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    LogoutModalComponent,
    ClickOutsideDirective,
    ToastMessagesComponent,
    StripTopDevEnvironmentComponent,
    FooterComponent,
  ],
  imports: [
    TranslateModule.forRoot(),
    NgcCookieConsentModule.forRoot(CookieConsentService.CONFIG),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    PageNotFoundModule,
    CheckupModule,
    CheckupResultsModule,
    CheckupChallengesModule,
    CheckupFeedbackModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initTranslations,
      deps: [TranslationService, Injector],
      multi: true,
    },
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: loadUserInfoFromCache,
      deps: [UserClass],
      multi: true,
    },
    Title,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
