<ng-container *ngIf="!loading; else loadingState">
  <ng-container *ngIf="resultsByGroup.length > 0">
    <div class="biomarker-box" id="biomarker-group-calculated">
      <div class="biomarker-box-title">
        <h2>{{ 'CHECKUP.CALCULATED_BIOMARKER.TITLE' | translate }}</h2>
      </div>
      <div class="generic-panel">
        <ul>
          <ng-container *ngFor="let biomarkerGroup of resultsByGroup">
            <li *ngFor="let biomarker of biomarkerGroup.biomarkers">
              <app-checkup-calculated-biomarker-card [biomarker]="biomarker"
                [groupName]="biomarkerGroup.name">
              </app-checkup-calculated-biomarker-card>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #loadingState>
  <div class="biomarker-box" *ngFor="let key of [1, 2, 3, 4]">
    <h2 class="fake-content"></h2>
    <div class="generic-panel fake-content-biomarker-list">
      <ul>
        <li *ngFor="let key of [1, 2, 3, 4]" class="fake-content fake-content-biomarker-item"></li>
      </ul>
    </div>
  </div>
</ng-template>