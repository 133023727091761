<div class="container">
  <div class="row title">
    <div class="title">
      <h2>
        {{ 'FEEDBACK' | translate }}
      </h2>
      <p class="title-voucher" *ngIf="checkup">{{ checkup?.wellabeId }}</p>
    </div>
  </div>
  <div class="panel">
    <form class="c-form" [formGroup]="currentForm" (ngSubmit)="submitCheckupReview()">
      <div class="answers-list">
        <div class="feedback-title">
          <h2>
            {{ 'CHECKUP.FEEDBACK.USER_FEEDBACK' | translate }}
          </h2>
        </div>
        <p>
          {{ 'CHECKUP.FEEDBACK.USER_FEEDBACK_DESCRIPTION' | translate }}
        </p>

        <div *ngIf="!loading; else loadingAnswers">
          <div class="feedback-answer" [class.feedback-answer--reviewed]="checkup.reviewed"
            [class.feedback-answer--faded]="
              currentForm.value.score != null && answer.review_score != formControls.score.value
            " [class.feedback-answer--selected]="answer.review_score == formControls.score.value"
            *ngFor="let answer of reviewScoresOptions" (click)="selectReviewScore(answer)">
            <div class="feedback-answer__header">
              <div class="feedback-answer__text">
                {{ answer.review_score }} - {{ answer.text_doctor }}
              </div>
              <div class="email-preview-button"
                [hidden]="answer.review_score !== formControls.score.value">
                <a (click)="openEmailPreviewModal()"
                  [class.disabled]="currentForm.invalid || loading">
                  {{ 'GENERAL.EMAIL_PREVIEW' | translate }}
                </a>
              </div>
            </div>

            <div class="feedback-answer__options-container"
              *ngIf="answer.options != null && answer.review_score == formControls.score.value">
              <h3>{{ answer.options.title }}</h3>

              <div class="feedback-answer__option"
                [class.feedback-answer--selected]="option.selected"
                *ngFor="let option of answer.options.values" (click)="selectAnswerOption(option)">
                {{ option.text_doctor }}
              </div>
              <app-form-error-message [control]="formControls.scoreOptions">
              </app-form-error-message>
            </div>

            <div class="feedback-answer__input" *ngIf="
                answer?.custom_input != null && answer.review_score == formControls.score.value
              ">
              <h3>{{ answer.custom_input.title }}</h3>
              <p>{{ answer.custom_input.description }}</p>
              <textarea rows="4" formControlName="scoreInput"
                [class.error]="formControls.scoreInput.touched && formControls.scoreInput.invalid"></textarea>
              <app-form-error-message [control]="formControls.scoreInput"></app-form-error-message>
              <h5>
                {{
                  'CHECKUP.FEEDBACK.USER_LANGUAGE' | translate: { language: checkup.userLanguage }
                }}
              </h5>
            </div>
          </div>

          <app-form-error-message [control]="formControls.score"></app-form-error-message>
        </div>

        <ng-template #loadingAnswers>
          <div class="answer-option-box fake-content-box" *ngFor="let key of [1, 2, 3, 4, 5, 6, 7]">
            <div class="fake-content fake-content-item"></div>
          </div>
        </ng-template>
      </div>

      <div class="checkup-feedback__form-item">
        <h2>
          {{ 'CHECKUP.FEEDBACK.TAGS_TITLE' | translate }}
        </h2>
        <div>
          <app-checkup-tags-input #checkupTagsInputComponent [checkupId]="checkupId"
            [parentForm]="currentForm"></app-checkup-tags-input>
        </div>
      </div>

      <div class="checkup-feedback__form-item" *ngIf="!loading">
        <h2>
          {{ 'CHECKUP.FEEDBACK.HEALTH_SCORE_TITLE' | translate }}
        </h2>
        <p>
          {{ 'CHECKUP.FEEDBACK.HEALTH_SCORE_DESCRIPTION' | translate }}
        </p>

        <div class="checkup-feedback__health-score-range">
          <ngx-slider [(value)]="formControls.healthScore.value" [options]="healthScoreOptions">
          </ngx-slider>
        </div>
      </div>

      <div class="checkup-feedback__form-item" *ngIf="!loading">
        <h2>
          {{ 'CHECKUP.FEEDBACK.WILLINGNESS_TO_CHANGE.TITLE' | translate }}
        </h2>
        <p>
          {{ 'CHECKUP.FEEDBACK.WILLINGNESS_TO_CHANGE.DESCRIPTION' | translate }}
        </p>

        <div class="checkup-feedback__willingness-score-range">
          <ngx-slider [(value)]="formControls.willingnessToChange.value"
            [options]="willingnessToChangeOptions"></ngx-slider>
        </div>
      </div>

      <div class="radio" *ngIf="!loading">
        <h2>
          {{ 'CHECKUP.FEEDBACK.HEALTH_CONDITION' | translate }}
        </h2>
        <p>
          {{ 'CHECKUP.FEEDBACK.HEALTH_CONDITION_DESCRIPTION' | translate }}
        </p>

        <label>
          <input class="c-form__input" type="radio" formControlName="healthConditionUnknownByUser"
            [value]="null" (click)="updateFieldTypeRadio('healthConditionUnknownByUser', null)" />
          N/A
        </label>
        <label>
          <input class="c-form__input" type="radio" formControlName="healthConditionUnknownByUser"
            [value]="true" (click)="updateFieldTypeRadio('healthConditionUnknownByUser', true)" />
          {{ 'GENERAL.YES' | translate }}
        </label>
        <label>
          <input class="c-form__input" type="radio" formControlName="healthConditionUnknownByUser"
            [value]="false" (click)="updateFieldTypeRadio('healthConditionUnknownByUser', false)" />
          {{ 'GENERAL.NO' | translate }}
        </label>
      </div>

      <div class="radio" *ngIf="!loading">
        <h2>
          {{ 'CHECKUP.FEEDBACK.ALLOW_CONTACT_TITLE' | translate }}
        </h2>
        <p>
          {{ 'CHECKUP.FEEDBACK.ALLOW_CONTACT_DESCRIPTION' | translate }}
        </p>

        <label>
          <input class="c-form__input" type="radio" formControlName="allowContact" [value]="true"
            (click)="updateFieldTypeRadio('allowContact', true)" />
          {{ 'GENERAL.YES' | translate }}
        </label>
        <label>
          <input class="c-form__input" type="radio" formControlName="allowContact" [value]="false"
            (click)="updateFieldTypeRadio('allowContact', false)" />
          {{ 'GENERAL.NO' | translate }}
        </label>
      </div>

      <div class="radio" *ngIf="!loading">
        <h2>
          {{ 'CHECKUP.FEEDBACK.LOCKUP_ACCESS' | translate }}
        </h2>
        <p>
          {{ 'CHECKUP.FEEDBACK.LOCKUP_ACCESS_DESCRIPTION' | translate }}
        </p>

        <label>
          <input class="c-form__input" type="radio" formControlName="riskFlagged" [value]="true"
            (click)="updateFieldTypeRadio('riskFlagged', true)" />
          {{ 'GENERAL.YES' | translate }}
        </label>
        <label>
          <input class="c-form__input" type="radio" formControlName="riskFlagged" [value]="false"
            (click)="updateFieldTypeRadio('riskFlagged', false)" />
          {{ 'GENERAL.NO' | translate }}
        </label>
      </div>

      <div class="radio" *ngIf="!loading">
        <h2>
          {{ 'CHECKUP.FEEDBACK.FOLLOWUP_EMAIL' | translate }}
        </h2>
        <p>
          {{ 'CHECKUP.FEEDBACK.FOLLOWUP_EMAIL_DESCRIPTION' | translate }}
        </p>

        <ng-select class="checkup-feedback__followup-email" dropdownPosition="bottom"
          [clearable]="false" [searchable]="false" formControlName="followupEmail">
          <ng-option [value]="ignoreFollowupEmail">{{ ignoreFollowupEmail | translate }}
          </ng-option>
          <ng-option *ngFor="let option of followupEmailOptions; trackBy: trackByOptions"
            [value]="option">
            {{ option }}
          </ng-option>
        </ng-select>
      </div>

      <div class="buttons" *ngIf="!loading">
        <div class="bt-container">
          <button class="c-btn c-btn__secondary" [routerLink]="'../challenges'"
            routerLinkActive="active" queryParamsHandling="preserve">
            {{ 'GENERAL.GO_BACK' | translate }}
          </button>
        </div>
        <div class="bt-container-next">
          <button class="c-btn" type="submit" [class.disabled]="currentForm.invalid || loading">
            {{ 'GENERAL.SAVE_AND_FINISH' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<app-loading-spinner [loading]="sendingReview"></app-loading-spinner>
<app-checkup-feedback-email-preview #emailPreviewModal></app-checkup-feedback-email-preview>
<app-checkup-warning-content-not-saved #checkupWarningContentNotSavedModal
  (closeModalAction)="submitCheckupReview()" (editObsAction)="openObsModal()">
</app-checkup-warning-content-not-saved>