<li class="container biomarker-card" *ngIf="previousAge !== null || currentAge !== null">
  <div class="row">
    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
      <span>
        <strong class="biomarker-card__name"> {{ name }} {{ 'GENERAL.AGE' | translate }} </strong>
      </span>
    </div>

    <div class="value-wrapper col-lg-4 col-md-4 col-sm-4 col-xs-4">
      <div *ngIf="previousAge"
        class="biomarker-card__value biomarker-card__value--previous tooltip">
        <span>{{ previousAge }}</span>

        <img src="./assets/images/icons/caret-right-solid.svg" />
      </div>

      <div class="biomarker-card__value tooltip">
        <span *ngIf="currentAge !== null">
          {{ currentAge }} {{ 'GENERAL.YEARS' | translate }}
        </span>
        <span *ngIf="currentAge === null">N/A</span>
      </div>
    </div>
  </div>
</li>