export interface TeleConsultationMessage {
  type: TeleConsultationMessageType;
  data: any;
}

export enum TeleConsultationMessageType {
  IMPERSONATE,
  HANDSHAKE,
  QUERY,
  ERROR,
  DOC_INFO,
  USER_INFO,
  HANG_UP,
}

export interface TeleConsultationUser {
  name: string;
  imageUrl: string;
}
