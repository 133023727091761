import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../auth-service/auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  public canActivate(): boolean {
    return this.checkLogin();
  }

  public checkLogin(): boolean {
    // console.log('[checkLogin]', this.authService.getLoggedInUser());

    if (this.authService.getLoggedInUser()) {
      return true;
    }
    this.router.navigate(['/login']);
    return false;
  }
}
