export interface Notification {
  type: NotificationType;
  description: string;
  title: string;
  effect: string;
  id: number;
}

export enum NotificationType {
  SUCCESS,
  ERROR,
  WARNING,
}
