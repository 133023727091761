<div class="container biomarker-card"
  [class.not-expected]="!currentCheckup.expected && type === 'measured'">
  <div class="row">
    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
      <span>
        <i class="biomarker-card__status-circle check"
          [style.background-color]="currentCheckup.color"></i>
        <strong class="biomarker-card__name">{{ name }}</strong>
      </span>
    </div>

    <div class="biomarker-card__wrapper col-lg-4 col-md-4 col-sm-4 col-xs-4">
      <div *ngIf="hasSelfReportedBiomarker" class="tooltip biomarker-card__sr-biomarker">
        <div class="tooltiptext">
          {{ 'CHECKUP.BIOMARKER.ADDED_BY_USER' | translate }}
        </div>
        <img src="./assets/images/icons/self-biomarker.svg" />
      </div>
      <div class="biomarker-card__value biomarker-card__value--previous" *ngIf="previousCheckup">
        <div class="tooltip">
          <div class="tooltiptext" *ngIf="previousCheckupTooltip">
            {{ previousCheckupTooltip }}
            <br>
            <span *ngIf="previousCheckup.measured_at"
              class="biomarker-card__value-tooltip">({{ previousCheckup.measured_at | formatDate: 'LL' }})</span>
          </div>
          <span class="biomarker-card__value--previous-text">
            <ng-container *ngIf="previousCheckup.value !== null; else emptyCheckupValue">
              {{ previousCheckup.value }}
            </ng-container>
            <ng-template #emptyCheckupValue> N/A </ng-template>
          </span>
        </div>

        <img src="./assets/images/icons/caret-right-solid.svg" />
      </div>

      <div class="biomarker-card__value tooltip">
        <div class="tooltiptext" *ngIf="currentCheckupTooltip">
          {{ currentCheckupTooltip }}
        </div>
        <span>
          <span *ngIf="currentCheckup.value !== null">{{ currentCheckup.value }} {{ unit }}</span>
          <span *ngIf="currentCheckup.value === null">N/A</span>
        </span>
      </div>
    </div>
  </div>
</div>