import { Component, OnInit, Input } from '@angular/core';
import { CheckupApiServiceCheckupQuestionResponse } from '../../services/api-checkup/api-checkup.interface';
import { CheckupClass } from '../../class/checkup/checkup.class';

@Component({
  selector: 'app-checkup-questionnaire-box',
  templateUrl: './checkup-questionnaire-box.component.html',
  styleUrls: ['./checkup-questionnaire-box.component.scss'],
})
export class CheckupQuestionnaireBoxComponent implements OnInit {
  @Input()
  public checkup: CheckupClass = null;

  public questions: CheckupApiServiceCheckupQuestionResponse[] = [];

  public loading: boolean = true;

  public ngOnInit() {}

  public loadingComplete() {
    this.loading = false;
  }
}
