import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  CheckupApiServiceCheckupReadingsInfoResponse,
  CheckupApiServiceCheckupInfoResponse,
  CheckupReportApiResponse,
  CheckupApiServiceCheckupQuestionResponse,
  CheckupApiServiceCalculatedReadingsInfoResponse,
} from './api-checkup.interface';
import {
  CheckupReviewPostBody,
  CheckupReviewPutBody,
  CheckupEmailPreviewApiResponse,
  CheckupEmailPreviewPutBody,
  CheckupsTagsPutBody,
} from '../../interfaces/checkup-feedback.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiCheckupService {
  constructor(private http: HttpClient) {}

  public getCheckup(checkupId: number) {
    return this.http.get<CheckupApiServiceCheckupInfoResponse>(
      `${environment.api}/doc/checkup/${checkupId}`
    );
  }

  public getCheckupReadings(checkupId: number) {
    return this.http.get<CheckupApiServiceCheckupReadingsInfoResponse[]>(
      `${environment.api}/doc/checkup/${checkupId}/readings`
    );
  }

  public getCheckupCalculatedReadings(userId: number) {
    return this.http.get<CheckupApiServiceCalculatedReadingsInfoResponse[]>(
      `${environment.api}/doc/user/${userId}/readings/calculated`
    );
  }

  public getCheckupQuestions(checkupId: number) {
    return this.http.get<CheckupApiServiceCheckupQuestionResponse[]>(
      `${environment.api}/doc/checkup/${checkupId}/questions`
    );
  }

  public postCheckupPerformed(checkupId: number) {
    return this.http.post(`${environment.api}/doc/checkup/${checkupId}/perform`, null);
  }

  public postCheckupReview(checkupId: number, checkupReviewBody: CheckupReviewPostBody) {
    return this.http.post(`${environment.api}/doc/checkup/${checkupId}/review`, checkupReviewBody);
  }

  public postFollowupEmail(checkupId: number, followupKey: string) {
    return this.http.post(`${environment.api}/doc/checkup/${checkupId}/follow_up`, {
      type: followupKey,
    });
  }

  public putCheckupReview(checkupId: number, checkupReviewBody: CheckupReviewPutBody) {
    return this.http.put(`${environment.api}/doc/checkup/${checkupId}/review`, checkupReviewBody);
  }

  public getCheckupReport(checkupId: number): Observable<CheckupReportApiResponse> {
    return this.http.get<CheckupReportApiResponse>(
      `${environment.api}/doc/checkup/${checkupId}/report`
    );
  }

  public getFiles(checkupId: number): Observable<string[]> {
    return this.http.get<string[]>(`${environment.api}/doc/checkup/${checkupId}/files`);
  }

  public getFile(checkupId: number, filename: string): Observable<any> {
    return this.http.get<any>(`${environment.api}/doc/checkup/${checkupId}/files/${filename}`, {
      responseType: 'blob' as 'json',
    });
  }

  public getCheckupEmailPreview(
    checkupId: number,
    checkupEmailPreviewBody: CheckupEmailPreviewPutBody
  ): Observable<CheckupEmailPreviewApiResponse> {
    return this.http.put<CheckupEmailPreviewApiResponse>(
      `${environment.api}/doc/checkup/${checkupId}/feedback/preview`,
      checkupEmailPreviewBody
    );
  }

  public putCheckupTags(checkupId: number, checkupsTagsBody: CheckupsTagsPutBody) {
    return this.http.put(`${environment.api}/doc/checkup/${checkupId}/tags`, checkupsTagsBody);
  }
}
