import { Component, OnInit, Input, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { concatMap, finalize } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CheckupService } from '../../services/checkup/checkup.service';
import { CheckupReviewService } from '../../services/checkup-review/checkup-review.service';
import { ToastService } from '../../services/toast-service/toast-service.service';
import { AnalyticsTrackerService } from '../../services/analytics-tracker/analytics-tracker.service';
import { AnalyticsTracking } from '../../interfaces/analytics.interface';
import { CheckupTagsContentStatus } from './checkup-tags-input.interface';
import { ApiCheckupTagsInfoResponse } from '../../services/api-checkup-tags/api-checkup-tags.interface';

@Component({
  selector: 'app-checkup-tags-input',
  templateUrl: './checkup-tags-input.component.html',
  styleUrls: ['./checkup-tags-input.component.scss'],
})
export class CheckupTagsInputComponent implements OnInit, AfterContentChecked {
  @Input() public parentForm: UntypedFormGroup;

  @Input() public appendToBody = false;

  @Input() public checkupId: number;

  public tagsOptions: ApiCheckupTagsInfoResponse[] = [];

  public currentForm = this.formBuilder.group({
    tags: [],
  });

  public status: CheckupTagsContentStatus = CheckupTagsContentStatus.LOADING;

  public CheckupTagsContentStatus = CheckupTagsContentStatus;

  constructor(
    private checkupService: CheckupService,
    private cdRef: ChangeDetectorRef,
    public formBuilder: UntypedFormBuilder,
    private toastService: ToastService,
    private analyticsTrackerService: AnalyticsTrackerService,
    private checkupReviewService: CheckupReviewService
  ) {}

  public ngOnInit() {}

  public ngAfterContentChecked() {
    this.cdRef.detectChanges();
  }

  public loadFormData() {
    if (this.parentForm) {
      this.currentForm = this.parentForm;
    }

    this.status = CheckupTagsContentStatus.LOADING;

    this.checkupReviewService
      .init(this.checkupId, null)
      .pipe(
        concatMap(() => this.checkupService.loadTagsOptions()),
        finalize(() => {
          this.status = CheckupTagsContentStatus.SYNCED;
        })
      )
      .subscribe(
        () => {
          this.tagsOptions = this.checkupService.getTagsOptions();
          this.currentForm.controls.tags.setValue(
            this.checkupReviewService.getCheckupLoaded().checkupTags
          );
        },
        (err) => {
          this.toastService.error(err.message);
          this.status = CheckupTagsContentStatus.ERROR;
        }
      );
  }

  public submitCheckupTags() {
    const checkup = this.checkupReviewService.getCheckupLoaded();
    this.status = CheckupTagsContentStatus.SUBMITTING;
    checkup.checkupTags = this.currentForm.value.tags;

    checkup
      .putCheckupTags()
      .pipe(
        finalize(() => {
          this.status = CheckupTagsContentStatus.SYNCED;
        })
      )
      .subscribe(
        () => {
          this.checkupService.setCheckupInProgress(checkup.checkupId, checkup.hasTeleconsultation);

          this.analyticsTrackerService.trackEvent(
            AnalyticsTracking.ACTIONS.REQUEST_UPDATE_FEEDBACK,
            'Successfully Update Tags'
          );
        },
        (err) => {
          this.analyticsTrackerService.trackEventError(
            AnalyticsTracking.ACTIONS.REQUEST_UPDATE_FEEDBACK,
            err.error
          );
          this.toastService.error(err.message);
          this.status = CheckupTagsContentStatus.ERROR;
        }
      );
  }
}
