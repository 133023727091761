import { Injectable } from '@angular/core';
import { Observable, Subject, throwError as observableThrowError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { ApiAuthService } from '../api-auth/api-auth.service';
import {
  AuthApiServiceLoginResponse,
  AuthServiceUserLoggedIn,
} from '../../interfaces/user.interface';
import { FeedbackMessagesRepository } from '../../state/feedback-messages/feedback-messages.repository';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public authServiceEvents: any;

  public userLoggedIn: AuthServiceUserLoggedIn = null;

  private localStorageKey: string = 'userLoggedIn';

  private subject = new Subject<any>();

  constructor(
    private localStorageService: LocalStorageService,
    private authApiService: ApiAuthService,
    private feedbackMessagesRepository: FeedbackMessagesRepository
  ) {
    this.userLoggedIn = this.localStorageService.get(this.localStorageKey);
    this.authServiceEvents = this.subject.asObservable();
  }

  public getLoggedInUser() {
    if (this.userLoggedIn && Object.keys(this.userLoggedIn).length !== 0) {
      return this.userLoggedIn;
    }
    return null;
  }

  public getTokenFromLoggedUser() {
    // console.log('[getTokenFromLoggedUser]', this.userLoggedIn);

    if (this.userLoggedIn && Object.keys(this.userLoggedIn).length !== 0) {
      return `${this.userLoggedIn.token_type} ${this.userLoggedIn.access_token}`;
    }
    return null;
  }

  public setLoggedInUser(userAuthData: AuthApiServiceLoginResponse): boolean {
    if (this.localStorageService.set(this.localStorageKey, userAuthData)) {
      this.userLoggedIn = userAuthData;
      return true;
    }
    return false;
  }

  public triggerAuthEvent(eventType: string) {
    this.subject.next({ type: eventType });
  }

  public logout(fromAllDevices: boolean = false) {
    return this.authApiService.logout(this.getTokenFromLoggedUser(), fromAllDevices).pipe(
      tap(() => {
        this.feedbackMessagesRepository.reset();
      }),
      map(() => {
        this.removeLocalLoggedUserInfo();
      }),
      catchError((err) => {
        if (err.error !== 'ERROR_LOGOUT_CACHE_REMOVAL') {
          this.removeLocalLoggedUserInfo();
        }
        return observableThrowError(err);
      })
    );
  }

  public removeLocalLoggedUserInfo() {
    const canLogOut = this.removeUserLoggedInfoFromCache();

    if (canLogOut) {
      this.triggerAuthEvent('logout');
    } else {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw {
        error: 'ERROR_LOGOUT_CACHE_REMOVAL',
        message: 'An error occurred while trying to logout.',
      };
    }

    return canLogOut;
  }

  public removeUserLoggedInfoFromCache() {
    if (this.getLoggedInUser()) {
      this.localStorageService.delete(this.localStorageKey);
      this.localStorageService.delete('userInfo');
      this.localStorageService.delete('reviewScores');

      this.userLoggedIn = null;
      return true;
    }
    return false;
  }

  public login(username: string, password: string) {
    if (!username || !password) {
      return new Observable((observer) => {
        observer.error({ message: 'Missing required parameters.' });
      });
    }

    return this.authApiService.postLogin(username, password).pipe(
      map((userAuthData: AuthApiServiceLoginResponse) => {
        const tmp: AuthServiceUserLoggedIn = userAuthData;
        tmp.username = username;
        this.setLoggedInUser(tmp);
        return true;
      })
    );
  }
}
