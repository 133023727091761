<div id="biomarker-group-checkup-questionnaires">
  <div *ngIf="!loading">
    <div class="questionnaire-box">
      <h2>
        {{ 'CHECKUP.QUESTIONNAIRE.TITLE' | translate }}
      </h2>
      <div class="generic-panel">
        <ul>
          <li class="questionnaire-header">
            <div class="questionnaire-header__name col-lg-3 col-md-3 col-sm-3 col-xs-3">
              {{ 'CHECKUP.QUESTIONNAIRE.SAFETY_QUESTIONS' | translate }}
            </div>

            <div class="value-wrapper col-lg-9 col-md-9 col-sm-9 col-xs-9">
              <div class="questionnaire-header__icon tooltip"
                *ngIf="checkup.userInMedicalTreatment !== null">
                <div class="tooltiptext tooltiptext--separator">
                  {{ 'CHECKUP.INFO.IN_MEDICAL_TREATMENT' | translate }}:
                  <strong>
                    {{ (checkup.userInMedicalTreatment ? 'GENERAL.YES' : 'GENERAL.NO') | translate }}
                  </strong>
                </div>
                <img [alt]="'CHECKUP.INFO.IN_MEDICAL_TREATMENT' | translate" [src]="
                    checkup.userInMedicalTreatment
                      ? './assets/images/icons/medical-treatment-check.svg'
                      : './assets/images/icons/medical-treatment-disabled.svg'
                  " />
              </div>
              <div class="questionnaire-header__icon tooltip">
                <div class="tooltiptext tooltiptext--separator">
                  {{ 'CHECKUP.INFO.DIABETIC' | translate }}:
                  <strong>
                    {{ (checkup.userHasDiabetes ? 'GENERAL.YES' : 'GENERAL.NO') | translate }}
                  </strong>
                </div>
                <img [alt]="'CHECKUP.INFO.DIABETIC' | translate" [src]="
                    checkup.userHasDiabetes
                      ? './assets/images/icons/diabetes-check.svg'
                      : './assets/images/icons/diabetes-disabled.svg'
                  " />
              </div>
              <div class="questionnaire-header__icon tooltip">
                <div class="tooltiptext tooltiptext--separator">
                  {{ 'CHECKUP.INFO.HAS_PACEMAKER' | translate }}:
                  <strong>
                    {{ (checkup.userHasPacemaker ? 'GENERAL.YES' : 'GENERAL.NO') | translate }}
                  </strong>
                </div>
                <img [alt]="'CHECKUP.INFO.HAS_PACEMAKER' | translate" [src]="
                    checkup.userHasPacemaker
                      ? './assets/images/icons/pacemaker-check.svg'
                      : './assets/images/icons/pacemaker-disabled.svg'
                  " />
              </div>
              <div class="questionnaire-header__icon tooltip">
                <div class="tooltiptext tooltiptext--separator">
                  {{ 'CHECKUP.INFO.PREGNANT' | translate }}:
                  <strong>
                    {{ (checkup.userIsPregnant ? 'GENERAL.YES' : 'GENERAL.NO') | translate }}
                  </strong>
                </div>
                <img [alt]="'CHECKUP.INFO.PREGNANT' | translate" [src]="
                    checkup.userIsPregnant
                      ? './assets/images/icons/pregnancy-check.svg'
                      : './assets/images/icons/pregnancy-disabled.svg'
                  " />
              </div>
            </div>
          </li>

          <li *ngFor="let question of checkup.questionnaire">
            <app-checkup-questionnaire-card [title]="question.text" [pivot]="question.pivot"
              [answers]="question.answers">
            </app-checkup-questionnaire-card>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div *ngIf="loading">
    <div class="questionnaire-box">
      <h2 class="fake-content"></h2>
      <div class="generic-panel fake-content-questionnaire-list">
        <ul>
          <li *ngFor="let key of [1, 2, 3, 4, 5]"
            class="fake-content fake-content-questionnaire-item"></li>
        </ul>
      </div>
    </div>
  </div>
</div>