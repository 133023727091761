import { Component, Input, ViewChild, OnInit } from '@angular/core';
import * as moment from 'moment';
import { CheckupFeedbackEmailPreviewComponent } from '../checkup-feedback-email-preview/checkup-feedback-email-preview.component';
import { CheckupClass } from '../../class/checkup/checkup.class';

@Component({
  selector: 'app-checkup-results-doc-rating',
  templateUrl: './checkup-results-doc-rating.component.html',
  styleUrls: ['./checkup-results-doc-rating.component.scss'],
})
export class CheckupResultsDocRatingComponent implements OnInit {
  @ViewChild(CheckupFeedbackEmailPreviewComponent, { static: true })
  public emailPreviewModal: CheckupFeedbackEmailPreviewComponent;

  @Input() public checkup: CheckupClass;

  public performedAt: string;

  public reviewScore: number = null;

  public reviewMessage: string;

  public isPreviousCheckup = false;

  public ngOnInit() {
    if (this.checkup.reviewScore !== null) {
      this.reviewScore = this.checkup.reviewScore;
      this.reviewMessage = this.checkup.reviewMessage;
      this.performedAt = this.checkup.performedAt;
    } else if (this.checkup.previousCheckup !== null) {
      this.isPreviousCheckup = true;
      this.reviewScore = this.checkup.previousCheckup.review_score;
      this.reviewMessage = this.checkup.previousCheckup.review_message_full;
      this.performedAt = this.checkup.previousCheckup.performed_at;
    }

    this.performedAt = this.performedAt ? moment(this.performedAt).fromNow() : null;
  }

  public openModalEmailSent() {
    this.emailPreviewModal.openModalEmailSent(this.reviewMessage);
  }
}
