import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError, BehaviorSubject } from 'rxjs';
import { CheckupClass, CheckupFactory } from '../../class/checkup/checkup.class';
import { CheckupObsClass, CheckupObsFactory } from '../../class/checkup-obs/checkup-obs.class';
import {
  CheckupChallengeClass,
  CheckupChallengeFactory,
} from '../../class/checkup-challenge/checkup-challenge.class';

@Injectable({
  providedIn: 'root',
})
export class CheckupReviewService {
  public checkup: CheckupClass;

  public checkupObs: CheckupObsClass;

  public checkupChallenges: CheckupChallengeClass;

  public loadCheckupObs$: Observable<void> = null;

  public initialized$ = new BehaviorSubject(false);

  constructor(
    private checkupFactory: CheckupFactory,
    private checkupChallengeFactory: CheckupChallengeFactory,
    private checkupObsFactory: CheckupObsFactory
  ) {}

  public getCheckupLoaded() {
    return this.checkup;
  }

  public getCheckupObsLoaded() {
    return this.checkupObs;
  }

  public getCheckupChallengesLoaded() {
    return this.checkupChallenges;
  }

  public init(checkupId: number, packageId: number, forceUpdate: boolean = false) {
    return new Observable((observer) => {
      if (!this.checkup || forceUpdate) {
        this.checkup = this.checkupFactory.createCheckup();
        this.checkup.checkupId = checkupId;
        this.checkup.packageId = packageId;

        this.checkup.getCheckup().subscribe(
          () => {
            this.initialized$.next(true);
            observer.next();
            observer.complete();
          },
          (err) => {
            observer.error(err);
          }
        );
      } else {
        this.initialized$.next(true);
        observer.next();
        observer.complete();
      }
    });
  }

  public loadCheckupReadings(forceUpdate: boolean = false) {
    if (!this.checkup) {
      return observableThrowError('Checkup not loaded.');
    }

    return new Observable((observer) => {
      if (!this.checkup.getCheckupReadings() || forceUpdate) {
        this.checkup.getCheckupReadingsFromApi().subscribe(
          () => {
            observer.next();
            observer.complete();
          },
          (err) => {
            observer.error(err);
          }
        );
      } else {
        observer.next();
        observer.complete();
      }
    });
  }

  public loadCheckupCalculatedReadings(forceUpdate: boolean = false) {
    if (!this.checkup) {
      return observableThrowError('Checkup not loaded.');
    }

    return new Observable((observer) => {
      if (!this.checkup.getCheckupCalculatedReadings() || forceUpdate) {
        this.checkup.getCheckupCalculatedReadingsFromApi().subscribe(
          () => {
            observer.next();
            observer.complete();
          },
          (err) => {
            observer.error(err);
          }
        );
      } else {
        observer.next();
        observer.complete();
      }
    });
  }

  public loadCheckupQuestions(forceUpdate: boolean = false) {
    if (!this.checkup) {
      return observableThrowError('Checkup not loaded.');
    }

    return new Observable((observer) => {
      if (!this.checkup.getCheckupQuestions() || forceUpdate) {
        this.checkup.getCheckupQuestionsFromApi().subscribe(
          () => {
            observer.next();
            observer.complete();
          },
          (err) => {
            observer.error(err);
          }
        );
      } else {
        observer.next();
        observer.complete();
      }
    });
  }

  public loadCheckupObservations(forceUpdate: boolean = false) {
    if (!this.checkup) {
      return observableThrowError('Checkup not loaded.');
    }

    if (!this.checkupObs || forceUpdate) {
      this.checkupObs = this.checkupObsFactory.createCheckupObs();
      this.checkupObs.checkupId = this.checkup.checkupId;
      this.checkupObs.userId = this.checkup.userId;

      this.loadCheckupObs$ = this.checkupObs.getObservationsFromCheckup();
    }

    return this.loadCheckupObs$;
  }

  public loadCheckupFiles() {
    if (!this.checkup) {
      return observableThrowError('Checkup not loaded.');
    }

    return this.checkup.getFiles();
  }

  public loadCheckupFile(filename: string) {
    if (!this.checkup) {
      return observableThrowError('Checkup not loaded.');
    }

    return this.checkup.getFile(filename);
  }

  public loadCheckupChallenges(forceUpdate: boolean = false) {
    if (!this.checkup) {
      return observableThrowError('Checkup not loaded.');
    }

    return new Observable((observer) => {
      if (!this.checkupChallenges || forceUpdate) {
        this.checkupChallenges = this.checkupChallengeFactory.createCheckupChallenge();
        this.checkupChallenges.checkupId = this.checkup.checkupId;

        this.checkupChallenges.getChallengesFromJourney().subscribe(
          () => {
            observer.next();
            observer.complete();
          },
          (err) => {
            observer.error(err);
          }
        );
      } else {
        observer.next();
        observer.complete();
      }
    });
  }

  public reset() {
    this.checkupObs = null;
    this.checkup = null;
    this.checkupChallenges = null;
    this.initialized$.next(false);
  }
}
