import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class Utils {
  public getJson(val): any {
    try {
      const valueParsed: any = JSON.parse(val);
      return valueParsed;
    } catch (e) {
      return false;
    }
  }

  public addLeadingZero(currentNumber: number): string {
    if (currentNumber < 10) {
      return `0${currentNumber}`;
    }
    return currentNumber.toString();
  }

  public base64ToPdfBlob(base64String: string) {
    const binary = atob(base64String.replace(/\s/g, ''));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);

    for (let i = 0; i < len; i += 1) {
      view[i] = binary.charCodeAt(i);
    }

    // Convert to blob type
    return new Blob([view], { type: 'application/pdf' });
  }
}
