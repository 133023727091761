import { NgModule } from '@angular/core';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CheckupFeedbackComponent } from './checkup-feedback.component';
import { routing } from './checkup-feedback.routing';
import { SharedModule } from '../../shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [SharedModule, routing, NgxSliderModule, NgSelectModule],
  declarations: [CheckupFeedbackComponent],
  exports: [CheckupFeedbackComponent],
})
export class CheckupFeedbackModule {}
