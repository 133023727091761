import { Injectable } from '@angular/core';

declare let window: {
  [key: string]: any;
  prototype: Window;
  new (): Window;
};

@Injectable({
  providedIn: 'root',
})
export class MatomoTrackerService {
  public init(trackerUrl: string, siteId: string, subdomain: string) {
    try {
      const script = document.createElement('script');

      script.innerHTML =
        `${'var _paq = _paq || [];_paq.push(["setCookieDomain", "'}${subdomain}"]);` +
        `_paq.push(["setDomains", "${subdomain}"]);` +
        `_paq.push(["enableCrossDomainLinking"]);` +
        `_paq.push(["enableLinkTracking"]);` +
        `_paq.push(["forgetUserOptOut"]);` +
        `(function () {` +
        `var uuu = "${trackerUrl}";` +
        `_paq.push(["setTrackerUrl", uuu + "js/"]);` +
        `_paq.push(["setSiteId", "${siteId}"]);` +
        `var ddd = document, ggg = ddd.createElement("script"), sss = ddd.getElementsByTagName("script")[0];` +
        `ggg.type = "text/javascript";` +
        `ggg.async = true;` +
        `ggg.defer = true;` +
        `ggg.src = uuu + "js/";` +
        `sss.parentNode.insertBefore(ggg, sss);` +
        `})();`;

      document.head.appendChild(script);
      // console.log('[MatomoTrackerService] init SUCCESS');
    } catch (err) {
      // console.error('[MatomoTrackerService] init', err);
    }
  }

  public getInstance() {
    // eslint-disable-next-line no-underscore-dangle
    if (window && window._paq !== undefined) {
      // eslint-disable-next-line no-underscore-dangle
      return window._paq;
    }
    return null;
  }

  public trackEvent(category: string, action: string, name?: string, value?: string): void {
    const matomo: any = this.getInstance();

    if (matomo) {
      try {
        const args: any[] = [category, action];
        if (name) {
          args.push(name);
        }
        if (value) {
          args.push(value);
        }
        matomo.push(['trackEvent', ...args]);
      } catch (e) {
        if (!(e instanceof ReferenceError)) {
          throw e;
        }
      }
    }
  }

  public trackPageView(customTitle?: string): void {
    const matomo: any = this.getInstance();

    if (matomo) {
      try {
        const args: any[] = [];
        if (customTitle) {
          args.push(customTitle);
        }
        matomo.push(['trackPageView', ...args]);
      } catch (e) {
        if (!(e instanceof ReferenceError)) {
          throw e;
        }
      }
    }
  }

  public setUserId(userId: string) {
    const matomo: any = this.getInstance();

    if (matomo) {
      try {
        const args: any[] = [userId];
        matomo.push(['setUserId', ...args]);
      } catch (e) {
        if (!(e instanceof ReferenceError)) {
          throw e;
        }
      }
    }
  }

  public resetUserId() {
    const matomo: any = this.getInstance();

    if (matomo) {
      try {
        matomo.push(['resetUserId']);
      } catch (e) {
        if (!(e instanceof ReferenceError)) {
          throw e;
        }
      }
    }
  }

  public setDocumentTitle(customTitle: string): void {
    const matomo: any = this.getInstance();

    if (matomo) {
      try {
        const args: any[] = [];
        args.push(customTitle);
        matomo.push(['setDocumentTitle', ...args]);
      } catch (e) {
        if (!(e instanceof ReferenceError)) {
          throw e;
        }
      }
    }
  }

  public enableHeartBeatTimer(customInterval?: number): void {
    const matomo: any = this.getInstance();

    if (matomo) {
      try {
        const args: any[] = [];
        if (customInterval) {
          args.push(customInterval);
        }
        matomo.push(['enableHeartBeatTimer', ...args]);
      } catch (e) {
        if (!(e instanceof ReferenceError)) {
          throw e;
        }
      }
    }
  }

  public setCustomUrl(customUrl: string): void {
    const matomo: any = this.getInstance();

    if (matomo) {
      try {
        const args: any[] = [];
        args.push(customUrl);
        matomo.push(['setCustomUrl', ...args]);
      } catch (e) {
        if (!(e instanceof ReferenceError)) {
          throw e;
        }
      }
    }
  }

  public disableCookies(): void {
    const matomo: any = this.getInstance();

    if (matomo) {
      try {
        matomo.push(['disableCookies']);
      } catch (e) {
        if (!(e instanceof ReferenceError)) {
          throw e;
        }
      }
    }
  }

  public deleteCookies(): void {
    const matomo: any = this.getInstance();

    if (matomo) {
      try {
        matomo.push(['deleteCookies']);
      } catch (e) {
        if (!(e instanceof ReferenceError)) {
          throw e;
        }
      }
    }
  }
}
