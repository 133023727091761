<app-checkup-challenges-search (submitButtonClick)="addChallenges($event)"
  [challengesIncluded]="checkupChallenge.challengesList" #challengesSearch>
</app-checkup-challenges-search>

<div class="row title">
  <div class="title">
    <h2>
      {{ 'JOURNEYS' | translate }}
    </h2>
    <p class="title-voucher" *ngIf="checkup">{{ checkup?.wellabeId }}</p>
  </div>
</div>

<div class="challenges-list" [class.fake-body-content]="loading">
  <table *ngIf="loading">
    <tbody>
      <tr *ngFor="let key of [1, 2, 3]">
        <td colspan="4">
          <div class="fake-content fake-content-item"></div>
        </td>
      </tr>
    </tbody>
  </table>

  <table *ngIf="!loading">
    <thead>
      <tr>
        <th class="order-info">{{ 'CHECKUP.CHALLENGES.ORDER' | translate }}</th>
        <th class="id-info">{{ 'CHECKUP.CHALLENGES.ID' | translate }}</th>
        <th class="title-info">{{ 'CHECKUP.JOURNEYS.CONTENT' | translate }}</th>
        <th class="title-info"></th>
      </tr>
    </thead>

    <tbody [sortablejs]="checkupChallenge.challengesList" [sortablejsOptions]="sortablejsOptions">
      <tr *ngFor="let challenge of checkupChallenge.challengesList"
        id="challenge-{{ challenge.challengeId }}">
        <td class="order-info">{{ challenge.order }}</td>
        <td class="id-info">{{ challenge.challengeId }}</td>
        <td>
          <div class="vertical-dots-box">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
          <div class="title-info">{{ challenge.title }}</div>
        </td>
        <td class="remove-icon">
          <i class="u-icon ion-md-trash" (click)="removeChallenge(challenge)"></i>
        </td>
      </tr>
    </tbody>
  </table>

  <p class="no-items-message" *ngIf="!loading && checkupChallenge.challengesList.length === 0">
    {{ 'CHECKUP.JOURNEYS.NO_CONTENT_MESSAGE' | translate }}
  </p>

  <div #scrolling></div>
</div>

<div class="bt-open-search-challenge">
  <button class="c-btn" (click)="checkupChallengesSearchComponent.openModal()">
    {{ 'CHECKUP.JOURNEY.ADD_NEW_CONTENT' | translate }}
  </button>
</div>