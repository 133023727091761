import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PackageInfo } from '../../interfaces/package.interface';

@Injectable({
  providedIn: 'root',
})
export class PackageService {
  public recentPackage$: BehaviorSubject<PackageInfo>;

  constructor() {
    this.recentPackage$ = new BehaviorSubject(null);
  }

  public setRecentPackage(packageData: PackageInfo) {
    this.recentPackage$.next(packageData);
  }
}
