import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth-service/auth.service';
import { UserClass } from '../../class/user/user.class';
import { LogoutModalComponent } from '../logout-modal/logout-modal.component';
import { AnalyticsTracking } from '../../interfaces/analytics.interface';
import { AnalyticsTrackerService } from '../../services/analytics-tracker/analytics-tracker.service';
import { PackageService } from '../../services/package/package.service';
import { PackageInfo } from '../../interfaces/package.interface';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  @ViewChild(LogoutModalComponent, { static: true })
  public logoutModalComponent: LogoutModalComponent;

  public isUserLogged: boolean = true;

  public submenuIsOpen: boolean = false;

  public authServiceEvents: Subscription;

  public recentPackage$: Subject<PackageInfo>;

  constructor(
    public currentUser: UserClass,
    private authService: AuthService,
    private analyticsTracker: AnalyticsTrackerService,
    private router: Router,
    private packageService: PackageService
  ) {}

  public ngOnInit() {
    this.router.events.subscribe(() => {
      this.closeSubmenu();
    });

    this.recentPackage$ = this.packageService.recentPackage$;

    this.checkMenuActive();
    this.listenAuthEvents();
  }

  public ngOnDestroy() {
    this.authServiceEvents.unsubscribe();
  }

  public showOrHideSubmenu() {
    this.submenuIsOpen = !this.submenuIsOpen;
    this.analyticsTracker.trackEvent(
      AnalyticsTracking.ACTIONS.TOGGLE_MODAL,
      this.submenuIsOpen ? 'Open User Menu' : 'Close User Menu'
    );
  }

  public closeSubmenu() {
    this.submenuIsOpen = false;
  }

  private listenAuthEvents() {
    this.authServiceEvents = this.authService.authServiceEvents.subscribe(() => {
      this.checkMenuActive();
    });
  }

  private checkMenuActive() {
    this.isUserLogged = !!this.authService.getLoggedInUser();
  }
}
