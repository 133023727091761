import { NgModule } from '@angular/core';
import { ClipboardModule } from 'ngx-clipboard';
import { SmoothScrollToDirective, SmoothScrollDirective } from 'ng2-smooth-scroll';
import { AngularStickyThingsModule } from '@w11k/angular-sticky-things';
import { CheckupResultsComponent } from './checkup-results.component';
import { routing } from './checkup-results.routing';

import { SharedModule } from '../../shared/shared.module';

import { CheckupUserContactCardComponent } from '../../components/checkup-user-contact-card/checkup-user-contact-card.component';
import { CheckupUserInfoCardComponent } from '../../components/checkup-user-info-card/checkup-user-info-card.component';
import { CheckupBiomarkerCardComponent } from '../../components/checkup-biomarker-card/checkup-biomarker-card.component';
import { CheckupBiomarkersBoxComponent } from '../../components/checkup-biomarkers-box/checkup-biomarkers-box.component';
import { CheckupSidebarMenuComponent } from '../../components/checkup-sidebar-menu/checkup-sidebar-menu.component';
import { CheckupResultsDocRatingComponent } from '../../components/checkup-results-doc-rating/checkup-results-doc-rating.component';
import { CheckupAgeCardComponent } from '../../components/checkup-age-card/checkup-age-card.component';
import { CheckupAppointmentBoxComponent } from '../../components/checkup-appointment-box/checkup-appointment-box.component';
import { TeleConsultationIntegrationService } from '../../services/tele-consultation-integration/tele-consultation-integration.service';
import { CheckupHeaderComponent } from '../../components/checkup-header/checkup-header.component';
import { CheckupQuestionnaireBoxComponent } from '../../components/checkup-questionnaire-box/checkup-questionnaire-box.component';
import { CheckupQuestionnaireCardComponent } from '../../components/checkup-questionnaire-card/checkup-questionnaire-card.component';
import { CheckupLabObservationComponent } from '../../components/checkup-lab-observation/checkup-lab-observation.component';
import { CheckupCalculatedBiomarkersBoxComponent } from '../../components/checkup-calculated-biomarkers-box/checkup-calculated-biomarkers-box.component';
import { CheckupCalculatedBiomarkerCardComponent } from '../../components/checkup-calculated-biomarker-card/checkup-calculated-biomarker-card.component';

@NgModule({
  imports: [SharedModule, routing, ClipboardModule, AngularStickyThingsModule],
  declarations: [
    SmoothScrollToDirective,
    SmoothScrollDirective,
    CheckupResultsComponent,
    CheckupUserInfoCardComponent,
    CheckupBiomarkerCardComponent,
    CheckupBiomarkersBoxComponent,
    CheckupCalculatedBiomarkersBoxComponent,
    CheckupCalculatedBiomarkerCardComponent,
    CheckupSidebarMenuComponent,
    CheckupResultsDocRatingComponent,
    CheckupAgeCardComponent,
    CheckupAppointmentBoxComponent,
    CheckupHeaderComponent,
    CheckupQuestionnaireBoxComponent,
    CheckupQuestionnaireCardComponent,
    CheckupLabObservationComponent,
    CheckupUserContactCardComponent,
  ],
  exports: [
    SmoothScrollToDirective,
    SmoothScrollDirective,
    CheckupResultsComponent,
    CheckupUserInfoCardComponent,
    CheckupBiomarkerCardComponent,
    CheckupBiomarkersBoxComponent,
    CheckupCalculatedBiomarkersBoxComponent,
    CheckupCalculatedBiomarkerCardComponent,
    CheckupSidebarMenuComponent,
    CheckupResultsDocRatingComponent,
    CheckupAgeCardComponent,
    CheckupAppointmentBoxComponent,
    CheckupHeaderComponent,
    CheckupQuestionnaireBoxComponent,
    CheckupQuestionnaireCardComponent,
    CheckupUserContactCardComponent,
  ],
  providers: [
    TeleConsultationIntegrationService,
    CheckupHeaderComponent,
    CheckupQuestionnaireCardComponent,
    CheckupLabObservationComponent,
  ],
})
export class CheckupResultsModule {}
