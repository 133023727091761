<div *ngIf="!loading">
  <div class="row title">
    <div class="title">
      <h2>
        {{ checkup.wellabeId }}
      </h2>
      <p class="title-voucher">{{ checkup.voucher }}</p>
    </div>
    <div class="actions">
      <a (click)="downloadReport()" class="bt" [class.bt--has-dropdown]="files.length > 0">
        <span *ngIf="!isDownloadingReport; else downloadingLoader">
          <i class="u-icon ion-md-download"></i>
          {{ 'DOWNLOAD_REPORT' | translate }}
        </span>
        <ng-template #downloadingLoader>
          <app-loading-dots></app-loading-dots>
        </ng-template>
      </a>

      <ng-container *ngIf="files?.length > 0">
        <a class="bt bt--dropdown" (click)="showOrHideSelector()" [class.open]="isSelectorOpen">
          <i class="u-icon ion-md-arrow-dropdown"></i>
        </a>
      </ng-container>

      <ul class="filesMenu" [class.slideup]="!isSelectorOpen" [class.slidedown]="isSelectorOpen">
        <li *ngFor="let file of files; let i = index" (click)="downloadFile(file)">
          <a [class.loading]="filesLoading[i]">
            {{ file }}
            <i class="u-icon ion-md-download" *ngIf="!filesLoading[i]; else fileLoading"></i>
            <ng-template #fileLoading>
              <app-loading-dots></app-loading-dots>
            </ng-template>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>

<div *ngIf="loading">
  <div class="row">
    <div class="fake-content"></div>
  </div>
</div>