import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { TranslationService } from '../../services/translation/translation.service';
import { CheckupApiServiceBiomarkerReadingValuesResponse } from '../../services/api-checkup/api-checkup.interface';

@Component({
  selector: 'app-checkup-biomarker-card',
  templateUrl: './checkup-biomarker-card.component.html',
  styleUrls: ['./checkup-biomarker-card.component.scss'],
})
export class CheckupBiomarkerCardComponent implements OnInit {
  @Input()
  public name: string = null;

  @Input()
  public unit: string = null;

  @Input()
  public type: string = null;

  @Input()
  public hasSelfReportedBiomarker: string = null;

  @Input()
  public currentCheckup: CheckupApiServiceBiomarkerReadingValuesResponse = null;

  @Input()
  public previousCheckup: CheckupApiServiceBiomarkerReadingValuesResponse = null;

  public previousCheckupTooltip: string;

  public currentCheckupTooltip: string;

  constructor(
    private translationService: TranslationService,
    private translateService: TranslateService
  ) {}

  public ngOnInit() {
    this.previousCheckupTooltip = this.getReadingCheckupTooltip(this.previousCheckup, true);
    this.currentCheckupTooltip = this.getReadingCheckupTooltip(this.currentCheckup);
  }

  private getReadingCheckupTooltip(
    currentReading: CheckupApiServiceBiomarkerReadingValuesResponse,
    showFromNow: boolean = false
  ) {
    if (currentReading === null) {
      return null;
    }

    if (currentReading.value !== null) {
      if (showFromNow) {
        const dateFromNow = moment(currentReading.measured_at).fromNow();
        return this.translateService.instant('CHECKUP.BIOMARKER.MEASUREMENT', {
          date: dateFromNow,
        });
      }
      return null;
    }
    if (currentReading.expected === true) {
      return this.translationService.getInstantTranslation(
        'CHECKUP.BIOMARKER_VALUE_COULD_NOT_BE_READ'
      );
    }
    return this.translationService.getInstantTranslation('CHECKUP.BIOMARKER_NOT_INCLUDED');
  }
}
