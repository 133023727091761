import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

//= =======================================================================
// ROUTE GUARDS
//= =======================================================================

import { AuthGuardService } from '../services/auth-guard/auth-guard.service';
import { CanDeactivateGuardService } from '../services/can-deactivate-guard/can-deactivate-guard.service';

//= =======================================================================
// PAGES
//= =======================================================================

import { CheckupComponent } from '../pages/checkup/checkup.component';
import { CheckupResultsComponent } from '../pages/checkup-results/checkup-results.component';
import { CheckupChallengesComponent } from '../pages/checkup-challenges/checkup-challenges.component';
import { CheckupFeedbackComponent } from '../pages/checkup-feedback/checkup-feedback.component';
import { PageNotFoundComponent } from '../pages/page-not-found/page-not-found.component';

const appRoutes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('../pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'profile',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('../pages/user-profile/user-profile.module').then((m) => m.UserProfileModule),
  },
  {
    path: 'change-password',
    loadChildren: () =>
      import('../pages/change-password/change-password.module').then((m) => m.ChangePasswordModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'packages',
    loadChildren: () =>
      import('../pages/packages-list/packages-list.module').then((m) => m.PackagesListModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'packages',
    loadChildren: () =>
      import('../pages/checkups-list/checkups-list.module').then((m) => m.CheckupsListModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'appointments/:checkupId',
    component: CheckupComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    children: [
      { path: 'results', component: CheckupResultsComponent, outlet: 'checkup' },
      { path: 'challenges', component: CheckupChallengesComponent, outlet: 'checkup' },
      { path: 'feedback', component: CheckupFeedbackComponent, outlet: 'checkup' },
    ],
  },
  {
    path: 'appointments',
    loadChildren: () =>
      import('../pages/appointments-list/appointments-list.module').then(
        (m) => m.AppointmentsListModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'packages/:packageId/checkups/:checkupId',
    component: CheckupComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [CanDeactivateGuardService],
    children: [
      { path: 'results', component: CheckupResultsComponent, outlet: 'checkup' },
      { path: 'challenges', component: CheckupChallengesComponent, outlet: 'checkup' },
      { path: 'feedback', component: CheckupFeedbackComponent, outlet: 'checkup' },
    ],
  },
  { path: '', redirectTo: '/packages', pathMatch: 'full' },
  {
    path: '403',
    loadChildren: () =>
      import('../pages/page-forbidden/page-forbidden.module').then((m) => m.PageForbiddenModule),
  },
  {
    path: '404',
    loadChildren: () =>
      import('../pages/page-not-found/page-not-found.module').then((m) => m.PageNotFoundModule),
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      enableTracing: false,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
  providers: [AuthGuardService],
})
export class AppRoutingModule {}
