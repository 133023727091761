import { Component, OnInit } from '@angular/core';
import { CookieConsentService } from './services/cookie-consent/cookie-consent.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private cookieConsentService: CookieConsentService) {}

  public ngOnInit() {
    this.cookieConsentService.init();
  }
}
