<div class="e-loading" [class.e-loading__show]="loading">
  <div class="e-loading__wrapper" [class.loading-active]="loading">
    <div class="e-loading__content">
      <img src="./assets/images/logo-loading.png" />
      <div class="la-ball-fall">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</div>