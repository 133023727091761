import { Directive, ElementRef, Output, EventEmitter, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appClickOutside]',
})
export class ClickOutsideDirective {
  @Output()
  public clickOutside = new EventEmitter<MouseEvent>();

  @Input() public elementException;

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event']) public onClickEvent($event: MouseEvent) {
    this.onClick($event);
  }

  public onClick(event: MouseEvent): void {
    const target = event.target as Element;
    if (
      target !== undefined &&
      target.classList !== undefined &&
      target.classList.contains(this.elementException) &&
      !this.elementRef.nativeElement.contains(target)
    ) {
      this.clickOutside.emit(event);
    }
  }
}
