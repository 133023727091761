import { Component, EventEmitter, Output } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { ToastService } from '../../services/toast-service/toast-service.service';
import { AnalyticsTrackerService } from '../../services/analytics-tracker/analytics-tracker.service';
import { CheckupObsClass } from '../../class/checkup-obs/checkup-obs.class';
import { AnalyticsTracking } from '../../interfaces/analytics.interface';
import { CheckupReviewService } from '../../services/checkup-review/checkup-review.service';
import { TranslationService } from '../../services/translation/translation.service';

@Component({
  selector: 'app-checkup-warning-content-not-saved',
  templateUrl: './checkup-warning-content-not-saved.html',
  styleUrls: ['./checkup-warning-content-not-saved.component.scss'],
})
export class CheckupWarningContentNotSavedComponent {
  @Output() public closeModalAction = new EventEmitter();

  @Output() public editObsAction = new EventEmitter();

  public isOpen: boolean = false;

  public currentObservation: CheckupObsClass;

  public loading: boolean = false;

  constructor(
    private checkupReviewService: CheckupReviewService,
    private toastService: ToastService,
    private analyticsTrackerService: AnalyticsTrackerService,
    private translationService: TranslationService
  ) {}

  public openModal(contentNotSaved: CheckupObsClass) {
    this.analyticsTrackerService.trackEvent('Toggle Modal', 'Open Content Not Saved Warning Modal');
    this.isOpen = true;
    this.loadContent(contentNotSaved);
  }

  public closeModal() {
    this.analyticsTrackerService.trackEvent(
      'Toggle Modal',
      'Close Content Not Saved Warning Modal'
    );
    this.isOpen = false;
    this.closeModalAction.emit();
  }

  public discardCheckupObs() {
    this.checkupReviewService.reset();
    this.closeModal();
  }

  public submitCheckupObs() {
    this.loading = true;

    this.currentObservation
      .submitCheckupObservation()
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        () => {
          this.analyticsTrackerService.trackEvent(
            AnalyticsTracking.ACTIONS.REQUEST_SUBMIT_OBSERVATION,
            'Successfully Submit Observation'
          );

          this.toastService.success(
            this.translationService.getInstantTranslation('OBSERVATION_FORM.CHECKUP_OBS_SENT')
          );

          this.checkupReviewService.reset();
          this.closeModal();
        },
        (err) => {
          // console.log('[submitCheckupObservation] ERROR', err);
          this.analyticsTrackerService.trackEventError(
            AnalyticsTracking.ACTIONS.REQUEST_SUBMIT_OBSERVATION,
            err.error
          );

          this.toastService.error(err.message);
        }
      );
  }

  public openObsBox() {
    this.editObsAction.emit();
    this.isOpen = false;
  }

  private loadContent(contentNotSaved: CheckupObsClass) {
    this.currentObservation = contentNotSaved;
  }
}
