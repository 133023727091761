import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { MatomoTrackerService } from '../matomo-tracker/matomo-tracker.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsTrackerService {
  public documentTitle: string;

  public categoryForEvents: string;

  constructor(private matomoTrackerService: MatomoTrackerService, private location: Location) {
    this.enableHeartBeatTimer();
  }

  public init() {
    if (environment.production && !this.isLoaded()) {
      this.matomoTrackerService.init(
        environment.analytics.trackerUrl,
        environment.analytics.siteId,
        environment.analytics.subdomain
      );
    }
  }

  public trackEvent(action: string, name?: string, category?: string): void {
    this.matomoTrackerService.trackEvent(category || this.categoryForEvents, action, name, null);
  }

  public trackEventError(
    action: string,
    errorTitle: any,
    isApiError: boolean = true,
    category?: string
  ): void {
    this.matomoTrackerService.trackEvent(
      category || this.categoryForEvents,
      action,
      isApiError ? `Api Error: ${errorTitle}` : errorTitle
    );
  }

  public initPageInfo(customTitleArray: string[], categoryForEvents: string): void {
    this.setDocumentTitle(customTitleArray);
    this.setCategoryForEvents(categoryForEvents);
    this.setCustomUrl(this.location.prepareExternalUrl(this.location.path()));
  }

  public setCategoryForEvents(category: string) {
    this.categoryForEvents = category;
  }

  public initPageTracking(customTitleArray: string[], categoryForEvents: string): void {
    this.initPageInfo(customTitleArray, categoryForEvents);
    this.trackPageView();
  }

  public trackPageView(customTitleArray?: string[]): void {
    this.matomoTrackerService.trackPageView(
      customTitleArray && customTitleArray[0] !== undefined ? customTitleArray.join(' / ') : null
    );
  }

  public setDocumentTitle(customTitleArray: string[]) {
    this.documentTitle = customTitleArray.join(' / ');
    this.matomoTrackerService.setDocumentTitle(this.documentTitle);
  }

  public enableHeartBeatTimer(customInterval?: number): void {
    this.matomoTrackerService.enableHeartBeatTimer(customInterval);
  }

  public setUserId(userId: string): void {
    this.matomoTrackerService.setUserId(userId);
  }

  public resetUserId() {
    this.matomoTrackerService.resetUserId();
  }

  public setCustomUrl(customUrl: string) {
    this.matomoTrackerService.setCustomUrl(customUrl);
  }

  public isLoaded() {
    return !!this.matomoTrackerService.getInstance();
  }

  public optOutCookies() {
    this.matomoTrackerService.disableCookies();
    this.matomoTrackerService.deleteCookies();
  }
}
