import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Appointment } from './appointments.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiAppointmentsService {
  constructor(private http: HttpClient) {}

  public getAppointments(): Observable<Appointment[]> {
    return this.http.get<Appointment[]>(
      `${environment.api}/doc/appointments/teleconsultation/upcoming`
    );
  }

  public getPastAppointments(page: number): Observable<Appointment[]> {
    let query = '';

    if (environment.appointments.supportPagination) {
      query = `?page=${page}`;
    }

    return this.http.get<Appointment[]>(
      `${environment.api}/doc/appointments/teleconsultation/past${query}`
    );
  }

  public patchAppointmentNoShow(id: number, noShow: boolean) {
    return this.http.patch(`${environment.api}/doc/appointment/${id}`, { no_show: noShow });
  }

  public postTeleconsultationPerform(id: number) {
    return this.http.post(`${environment.api}/doc/teleconsultation/${id}/perform`, null);
  }
}
