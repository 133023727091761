import { Injectable } from '@angular/core';
import {
  NgcCookieConsentService,
  NgcStatusChangeEvent,
  NgcCookieConsentConfig,
} from 'ngx-cookieconsent';
import { AnalyticsTrackerService } from '../analytics-tracker/analytics-tracker.service';
import { TranslationService } from '../translation/translation.service';
import { CookieService } from '../cookie/cookie.service';
import { environment } from '../../../environments/environment';

const COOKIES_REMOVAL_NOT_ALLOWED = ['cookieconsent_status', '__cfduid'];

@Injectable({
  providedIn: 'root',
})
export class CookieConsentService {
  public static CONFIG: NgcCookieConsentConfig = {
    cookie: {
      domain: document.location.hostname,
    },
    content: {
      href: environment.cookie.privacy_link,
    },
    position: 'top-left',
    theme: 'edgeless',
    type: 'opt-out',
    showLink: false,
  };

  public previousConsentOption: boolean = true;

  constructor(
    private analyticsTrackerService: AnalyticsTrackerService,
    private ccService: NgcCookieConsentService,
    private translationService: TranslationService,
    private cookieService: CookieService
  ) {}

  public init(): void {
    this.loadContentTranslations();
    this.load();

    this.ccService.statusChange$.subscribe((event) => {
      this.statusChangeEvent(event);
    });

    this.translationService.getTranslationsUpdate().subscribe(() => {
      this.loadContentTranslations();
    });

    this.ccService.popupOpen$.subscribe(() => {
      this.previousConsentOption = this.ccService.hasConsented();
    });
  }

  public openDialog(): void {
    this.ccService.open();
  }

  public load(): void {
    if (this.ccService.hasAnswered()) {
      if (this.ccService.hasConsented()) {
        this.allowCookies();
      } else {
        this.declineCookies();
      }
    }
  }

  public statusChangeEvent(event: NgcStatusChangeEvent): void {
    if (event.status === 'allow') {
      this.allowCookies(true);
    } else {
      this.declineCookies();
    }
  }

  /**
   * @param statusChangeEvent Enabled if the event comes from a status change event
   *
   * If the previous status was "not consented" (previousConsentOption = false),
   * the only way to re-enable the Matomo cookies is forcing the page to reload. By doing this,
   * the Matomo script with be loaded and "optOutCookies" method won't be called.
   */
  public allowCookies(statusChangeEvent: boolean = false): void {
    if (statusChangeEvent && this.previousConsentOption === false) {
      this.reloadPage();
      return;
    }

    this.analyticsTrackerService.init();
  }

  /**
   * 1. Start Matomo script with cookies disabled
   * 2. Delete all cookies except the necessary ones (COOKIES_REMOVAL_NOT_ALLOWED)
   */
  public declineCookies(): void {
    this.analyticsTrackerService.init();
    this.analyticsTrackerService.optOutCookies();
    this.cookieService.deleteAll(COOKIES_REMOVAL_NOT_ALLOWED);
  }

  public loadContentTranslations(): void {
    this.ccService.getConfig().content = this.ccService.getConfig().content || {};

    this.ccService.getConfig().content = {
      header: this.translationService.getInstantTranslation('COOKIES.HEADER'),
      message: this.translationService.getInstantTranslation('COOKIES.MESSAGE'),
      deny: this.translationService.getInstantTranslation('COOKIES.DECLINE'),
      allow: this.translationService.getInstantTranslation('COOKIES.ALLOW'),
    };

    this.ccService.destroy();
    this.ccService.init(this.ccService.getConfig());
  }

  public reloadPage() {
    window.location.reload();
  }
}
