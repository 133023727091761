import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  HostListener,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Subject, Subscription } from 'rxjs';
import { auditTime } from 'rxjs/operators';
import { SidebarMenuItem } from '../../interfaces/checkup-sidebar.interface';
import { AnalyticsTracking } from '../../interfaces/analytics.interface';
import { AnalyticsTrackerService } from '../../services/analytics-tracker/analytics-tracker.service';

@Component({
  selector: 'app-checkup-sidebar-menu',
  templateUrl: './checkup-sidebar-menu.component.html',
  styleUrls: ['./checkup-sidebar-menu.component.scss'],
})
export class CheckupSidebarMenuComponent implements OnInit, OnDestroy {
  @Input() public prefixGroupTitle: string;

  @Input() public nextStep: string = null;

  @Input() public showModalButton: boolean = false;

  @Input() public checkupId: number;

  @Input() public nextStepActionIsCustomFunction: boolean = false;

  @Output() public nextStepSubmitAction = new EventEmitter();

  public items: SidebarMenuItem[] = [];

  public activeMenuItem: any;

  public loading: boolean = true;

  public loadByFragment: boolean = true;

  public currentUrlBase: string = '';

  public scrollEventSubject = new Subject<void>();

  public scrollEventSubjectSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private analyticsTrackerService: AnalyticsTrackerService
  ) {
    this.listenScrollEvent();
  }

  @HostListener('window:scroll', [])
  public onWindowScroll() {
    this.scrollEventSubject.next();
  }

  public ngOnInit() {
    this.route.parent.params.subscribe((params) => {
      this.currentUrlBase = `/packages/${params.packageId}/checkups/${params.checkupId}/(checkup:results)`;
    });
  }

  public ngOnDestroy() {
    this.scrollEventSubjectSubscription.unsubscribe();
  }

  public listenScrollEvent() {
    this.scrollEventSubjectSubscription = this.scrollEventSubject
      .pipe(auditTime(100))
      .subscribe(() => {
        this.emitEventMenuSelection();
      });
  }

  public menuSelect(currentItem: SidebarMenuItem) {
    this.analyticsTrackerService.trackEvent(
      AnalyticsTracking.ACTIONS.SELECT_CHECKUP_GROUP,
      `Select Group "${currentItem.analytics}"`
    );
    const currentFragment: string = `${this.prefixGroupTitle}-${currentItem.id}`;
    this.location.go(`${this.currentUrlBase}#${currentFragment}`);
  }

  public loadMenu(currentItems: SidebarMenuItem[]) {
    this.items = currentItems;

    this.setDefaultMenuItemActive();
    this.listenMenuClick();
    this.loading = false;
  }

  public focusBiomarkerGroupAccordingFragment(sidebarMenuItem: string) {
    setTimeout(() => {
      const element: any = document.getElementById(`biomarker-menu-item-${sidebarMenuItem}`);

      if (element) {
        element.click();
      }
    });
  }

  public setDefaultMenuItemActive() {
    [this.activeMenuItem] = this.items;
  }

  public listenMenuClick() {
    this.route.fragment.subscribe((sidebarMenuItem) => {
      if (!this.loadByFragment) {
        return;
      }

      if (sidebarMenuItem) {
        this.activeMenuItem = sidebarMenuItem;
        this.focusBiomarkerGroupAccordingFragment(sidebarMenuItem);
      }
    });
  }

  public highlightMenuItem(item: SidebarMenuItem) {
    this.activeMenuItem = item;
  }

  private emitEventMenuSelection() {
    const customOffset: number = 140;
    let currentFocus = null;

    const windowScrollTop = document.documentElement.scrollTop - customOffset;

    this.items.forEach((element) => {
      const currentElement: any = document.getElementById(`${this.prefixGroupTitle}-${element.id}`);
      const currentBoxOffsetTop = currentElement.offsetTop;

      if (windowScrollTop >= currentBoxOffsetTop) {
        currentFocus = element;
      }
    });

    if (currentFocus) {
      this.highlightMenuItem(currentFocus);
    }
  }
}
