import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { CheckupApiServiceCalculatedBiomarkerReadingsResponse } from '../../services/api-checkup/api-checkup.interface';
import { TranslationService } from '../../services/translation/translation.service';

@Component({
  selector: 'app-checkup-calculated-biomarker-card',
  templateUrl: './checkup-calculated-biomarker-card.component.html',
  styleUrls: ['./checkup-calculated-biomarker-card.component.scss'],
})
export class CheckupCalculatedBiomarkerCardComponent implements OnInit {
  @Input() biomarker: CheckupApiServiceCalculatedBiomarkerReadingsResponse;

  @Input() groupName: string;

  public readingValueTooltip: string;

  constructor(private translationService: TranslationService) {}

  ngOnInit() {
    this.readingValueTooltip = this.getReadingTooltipText();
  }

  getReadingTooltipText(): string {
    if (!this.biomarker) {
      return null;
    }

    if (this.biomarker.reading?.measured_at && this.biomarker.reading?.value) {
      const dateFromNow = moment(this.biomarker.reading.measured_at).fromNow();
      return this.translationService.getInstantTranslation('CHECKUP.BIOMARKER.MEASUREMENT', {
        date: dateFromNow,
      });
    } else {
      return null;
    }
  }
}
