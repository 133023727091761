import { NgModule } from '@angular/core';
import { SortablejsModule } from 'ngx-sortablejs';

import { CheckupChallengesComponent } from './checkup-challenges.component';
import { routing } from './checkup-challenges.routing';
import { SharedModule } from '../../shared/shared.module';

import { CheckupChallengesBoxComponent } from '../../components/checkup-challenges-box/checkup-challenges-box.component';
import { CheckupChallengesSearchComponent } from '../../components/checkup-challenges-search/checkup-challenges-search.component';

@NgModule({
  imports: [SharedModule, SortablejsModule.forRoot({ animation: 150 }), routing],
  declarations: [
    CheckupChallengesComponent,
    CheckupChallengesBoxComponent,
    CheckupChallengesSearchComponent,
  ],
  exports: [
    CheckupChallengesComponent,
    CheckupChallengesBoxComponent,
    CheckupChallengesSearchComponent,
  ],
})
export class CheckupChallengesModule {}
