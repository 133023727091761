import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { CheckupObsClass } from '../../class/checkup-obs/checkup-obs.class';
import { ToastService } from '../../services/toast-service/toast-service.service';
import { TranslationService } from '../../services/translation/translation.service';
import { AnalyticsTracking } from '../../interfaces/analytics.interface';
import { AnalyticsTrackerService } from '../../services/analytics-tracker/analytics-tracker.service';
import { CheckupService } from '../../services/checkup/checkup.service';
import { CheckupClass } from '../../class/checkup/checkup.class';

export interface ObservationFlagOptions {
  name: string;
  id?: string;
}

@Component({
  selector: 'app-checkup-observation-form',
  templateUrl: './checkup-observation-form.component.html',
  styleUrls: ['./checkup-observation-form.component.scss'],
})
export class CheckupObservationFormComponent implements OnInit {
  @Input() public checkupObsListInstance: CheckupObsClass;

  @Output() public onUpdate = new EventEmitter();

  public currentForm: UntypedFormGroup;

  public loading = false;

  public flagOptions: ObservationFlagOptions[] = [
    { name: 'OBSERVATION_FORM.NO_FLAG', id: null },
    { name: 'OBSERVATION_FORM.QUALITY_FLAG', id: 'quality_related' },
    { name: 'OBSERVATION_FORM.SPECIAL_CASE_FLAG', id: 'special_case' },
  ];

  private checkupInstance: CheckupClass;

  private checkupObsInstance: CheckupObsClass;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public translationService: TranslationService,
    public toastService: ToastService,
    private checkupService: CheckupService,
    private analyticsTrackerService: AnalyticsTrackerService
  ) {}

  public ngOnInit() {
    this.initForm();
  }

  public initForm() {
    this.currentForm = this.formBuilder.group({
      description: [null, Validators.required],
      observationFlag: [null],
    });
  }

  public loadFormData() {
    if (this.checkupObsInstance && this.checkupObsInstance.text) {
      this.currentForm.controls.description.setValue(this.checkupObsInstance.text);
      this.currentForm.controls.observationFlag.setValue(
        this.checkupObsInstance.observationFlags[0] !== undefined
          ? this.checkupObsInstance.observationFlags[0]
          : null
      );
    }
  }

  public cacheObservationNotSaved() {
    if (this.currentForm.value.description) {
      this.checkupObsInstance.text = this.currentForm.value.description;
      if (this.currentForm.value.observationFlag != null) {
        this.checkupObsInstance.observationFlags = [this.currentForm.value.observationFlag];
      }
    }
  }

  public setCheckupInstance(currentInstance: CheckupClass) {
    this.checkupInstance = currentInstance;
  }

  public setCheckupObsInstance(currentObsInstance: CheckupObsClass) {
    this.checkupObsInstance = currentObsInstance;
  }

  public onSubmit() {
    if (this.loading || !this.currentForm.valid) {
      this.showAllFormErrorMessages();
      return;
    }

    this.loading = true;
    this.checkupObsInstance.text = this.currentForm.value.description;

    if (this.currentForm.value.observationFlag != null) {
      this.checkupObsInstance.observationFlags = [this.currentForm.value.observationFlag];
    }

    this.checkupObsInstance
      .submitCheckupObservation()
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        () => {
          this.analyticsTrackerService.trackEvent(
            AnalyticsTracking.ACTIONS.REQUEST_SUBMIT_OBSERVATION,
            'Successfully Submit Observation'
          );

          this.toastService.success(
            this.translationService.getInstantTranslation('OBSERVATION_FORM.CHECKUP_OBS_SENT')
          );
          this.checkupObsInstance.reset();
          this.resetForm();
          this.onUpdate.emit();

          this.checkupService.setCheckupInProgress(
            this.checkupObsInstance.checkupId,
            this.checkupInstance.hasTeleconsultation
          );
        },
        (err) => {
          // console.log('[submitCheckupObservation] ERROR', err);
          this.analyticsTrackerService.trackEventError(
            AnalyticsTracking.ACTIONS.REQUEST_SUBMIT_OBSERVATION,
            err.error
          );
          this.toastService.error(err.message);
        }
      );
  }

  private resetForm() {
    this.currentForm.reset();
  }

  private showAllFormErrorMessages() {
    for (const field of Object.keys(this.currentForm.controls)) {
      this.currentForm.controls[field].markAsTouched();
      this.currentForm.controls[field].updateValueAndValidity();
    }
  }
}
