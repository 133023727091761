<div class="container" *ngIf="reviewScore !== null; else empty">
  <div class="item col-md-8 col-sm-8 col-xs-8">
    <h6 class="rating">{{ reviewScore }}</h6>
    <h5 class="title">
      <span *ngIf="isPreviousCheckup">{{ 'CHECKUP.INFO.PREVIOUS_DOC_RATING' | translate }}</span>
      <span *ngIf="!isPreviousCheckup">{{ 'CHECKUP.INFO.DOC_RATING' | translate }}</span>
      <span class="date-info" *ngIf="performedAt">{{ performedAt }}</span>
    </h5>
  </div>
  <div class="item col-md-4 col-sm-4 col-xs-4 email-link" *ngIf="reviewMessage">
    <a (click)="openModalEmailSent()">
      {{ 'CHECKUP.INFO.VIEW_EMAIL' | translate }}
    </a>
  </div>
</div>

<ng-template #empty>
  <div class="container">
    <div class="item col-md-8 col-sm-8 col-xs-8">
      <h5 class="title">
        <span>{{ 'CHECKUP.INFO.DOC_RATING_EMPTY' | translate }}</span>
      </h5>
    </div>
  </div>
</ng-template>

<app-checkup-feedback-email-preview #emailPreviewModal></app-checkup-feedback-email-preview>