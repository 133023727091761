<div class="modal" [style.display]="isOpen ? 'flex' : 'none'">
  <div class="container">
    <div class="panel">
      <div class="content">
        <div class="close" (click)="closeModal()">
          <i class="u-icon ion-md-close"></i>
        </div>
        <h2>
          {{ 'CHECKUP.CHALLENGES.SEARCH_CHALLENGE_TITLE' | translate }}
        </h2>
        <p>
          {{ 'CHECKUP.CHALLENGES.SEARCH_CHALLENGE_DESCRIPTION' | translate }}
        </p>

        <span class="search-bar">
          <i class="u-icon ion-md-search"></i>
          <input #searchBox
            placeholder="{{ 'CHECKUP.CHALLENGES.SEARCH_CHALLENGE_PLACEHOLDER' | translate }}"
            (keyup)="searchTerm$.next($event.target.value)">
        </span>

        <div class="results">
          <ul>
            <li class="item"
              *ngFor="let challenge of checkupChallenge.challengesList; let i = index"
              [class.selected]="challengesToBeIncluded[challenge.challengeId] !== undefined"
              [class.disabled]="challengesIdIncluded[challenge.challengeId] === true"
              [class.preview]="challengePreview === challenge && visiblePreview"
              (click)="challengeClick(challenge)">
              <div class="challenge-search__results-entry">
                <div (click)="addOrRemoveChallenge(challenge, $event);">
                  <i
                    class="fas fa-check-square challenge-search__results-entry-checkbox challenge-search__results-entry-checkbox--checked"></i>
                  <div
                    class="challenge-search__results-entry-checkbox challenge-search__results-entry-checkbox--unchecked">
                  </div>
                </div>

                <div class="challenge-search__results-entry-title">{{ challenge.title }}</div>

                <div class="challenge-search__results-entry-id">{{ challenge.challengeId }}</div>

                <i class="fas fa-chevron-right challenge-search__results-entry-arrow"
                  (click)="previewChallenge(challenge, $event);"></i>
              </div>
            </li>
          </ul>

          <div class="loading" *ngIf="loading">
            <span>
              <img src="./assets/images/button-loading.gif">
            </span>
          </div>

          <button *ngIf="showLoadMoreButton" #scrolling class="bt-load-more-results"
            (click)="loadMore()">
            {{ 'CHECKUP.CHALLENGES.SEARCH_CHALLENGE_LOAD_MORE' | translate }}
          </button>

          <p *ngIf="showNoResultsMessage" class="no-results-msg">
            {{ 'CHECKUP.CHALLENGES.SEARCH_CHALLENGE_NO_RESULTS' | translate }}
          </p>
        </div>

        <div class="bts">
          <div class="bts-cancel">
            <button class="c-btn c-btn__secondary"
              (click)="closeModal()">{{ 'GENERAL.CANCEL' | translate }}</button>
          </div>
          <div class="bts-next">
            <button class="c-btn" (click)="addChallenges()"
              [class.disabled]="!isSubmitButtonEnabled()">
              {{ 'CHECKUP.CHALLENGES.ADD_CHALLENGES' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="panel panel--preview" *ngIf="visiblePreview && challengePreview">

      <div class="close" (click)="hidePreview()">
        <i class="u-icon ion-md-eye-off"></i>
      </div>

      <div class="content-wrapper" *ngIf="!loadingPreview">
        <div class="content">

          <div class="image-wrapper">
            <img *ngIf="challengePreview.image" [src]="challengePreview.image">

            <h2>{{challengePreview.title}}</h2>
          </div>
          <div class="text-container" [class.fake-body-content]="loading">
            <content-parser class="text-content" [descriptionContent]="challengePreview.content"
              *ngIf="challengePreview.content">
            </content-parser>
          </div>
        </div>

        <div class="bts-edit"
          *ngIf="challengesToBeIncluded[challengePreview?.challengeId] === undefined">
          <button class="c-btn" (click)="addOrRemoveChallenge(challengePreview)">
            <p>
              {{ 'CHECKUP.CHALLENGES.USE_CHALLENGE' | translate }}
            </p>
          </button>
        </div>
      </div>

      <div class="loading" *ngIf="loadingPreview">
        <span>
          <img src="./assets/images/button-loading.gif">
        </span>
      </div>
    </div>
  </div>
</div>