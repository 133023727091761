import { Component, OnInit, ViewChild, ChangeDetectorRef, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { concatMap, throttle, distinctUntilChanged, finalize, tap } from 'rxjs/operators';
import { forkJoin, interval, Subscription } from 'rxjs';
import { CheckupClass } from '../../class/checkup/checkup.class';
import { ToastService } from '../../services/toast-service/toast-service.service';
import { TranslationService } from '../../services/translation/translation.service';
import { ReviewScoreMessage, ReviewScoreOption } from '../../interfaces/checkup-feedback.interface';
import { CheckupFeedbackEmailPreviewComponent } from '../../components/checkup-feedback-email-preview/checkup-feedback-email-preview.component';
import { AnalyticsTrackerService } from '../../services/analytics-tracker/analytics-tracker.service';
import { AnalyticsTracking } from '../../interfaces/analytics.interface';
import { CheckupWarningContentNotSavedComponent } from '../../components/checkup-warning-content-not-saved/checkup-warning-content-not-saved.component';
import { CheckupReviewService } from '../../services/checkup-review/checkup-review.service';
import {
  FeedbackFormControls,
  ScoreSliderOptions,
  SCORE_SLIDER_UNDEFINED_STEP,
} from './checkup-feedback.interface';
import { CheckupService } from '../../services/checkup/checkup.service';
import { CheckupTagsInputComponent } from '../../components/checkup-tags-input/checkup-tags-input.component';
import { CheckupComponent } from '../checkup/checkup.component';
import { PackageInfo } from '../../interfaces/package.interface';
import { PackageService } from '../../services/package/package.service';
import { ApiAppointmentsService } from '../../services/api-appointments/api-appointments.service';
import { ApiMetadataService } from '../../services/api-metadata/api-metadata.service';
import { ApiCheckupService } from '../../services/api-checkup/api-checkup.service';
import { FeedbackMessagesRepository } from '../../state/feedback-messages/feedback-messages.repository';

@Component({
  selector: 'app-checkup-feedback',
  templateUrl: './checkup-feedback.component.html',
  styleUrls: ['./checkup-feedback.component.scss'],
})
export class CheckupFeedbackComponent implements OnInit, OnDestroy {
  @ViewChild(CheckupTagsInputComponent, { static: true })
  public checkupTagsInputComponent: CheckupTagsInputComponent;

  @ViewChild(CheckupFeedbackEmailPreviewComponent, { static: true })
  public emailPreviewModal: CheckupFeedbackEmailPreviewComponent;

  @ViewChild(CheckupWarningContentNotSavedComponent, { static: true })
  public checkupWarningContentNotSavedModal: CheckupWarningContentNotSavedComponent;

  public checkup: CheckupClass = null;

  public reviewScoresOptions: ReviewScoreMessage[] = [];

  public selectedScore: ReviewScoreMessage = null;

  public healthScoreOptions: ScoreSliderOptions = {
    showTicks: true,
    stepsArray: [
      {
        value: -5,
        legend: this.translationService.getInstantTranslation(
          'CHECKUP.FEEDBACK.HEALTH_SCORE_FLOOR'
        ),
      },
      { value: -4 },
      { value: -3 },
      { value: -2 },
      { value: -1 },
      {
        value: 0,
        legend: this.translationService.getInstantTranslation(
          'CHECKUP.FEEDBACK.HEALTH_SCORE_NORMAL'
        ),
      },
      { value: 1 },
      { value: 2 },
      { value: 3 },
      { value: 4 },
      {
        value: 5,
        legend: this.translationService.getInstantTranslation('CHECKUP.FEEDBACK.HEALTH_SCORE_CEIL'),
      },
    ],
  };

  public willingnessToChangeOptions: ScoreSliderOptions = {
    showTicks: true,
    stepsArray: [],
  };

  public loading: boolean = false;

  public currentForm: UntypedFormGroup;

  public sendingReview: boolean = false;

  public checkupId: number;

  public followupEmailOptions: string[] = [];

  public ignoreFollowupEmail = 'CHECKUP.FEEDBACK.DONT_SEND';

  private listenObsAddedSuccessObservable: Subscription = Subscription.EMPTY;

  constructor(
    @Inject(CheckupComponent) private parentCheckupComponent: CheckupComponent,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private translationService: TranslationService,
    private toastService: ToastService,
    private analyticsTrackerService: AnalyticsTrackerService,
    private changeDetectorRef: ChangeDetectorRef,
    private checkupReviewService: CheckupReviewService,
    private checkupService: CheckupService,
    private checkupApi: ApiCheckupService,
    private packageService: PackageService,
    private appointmentApi: ApiAppointmentsService,
    private metadataService: ApiMetadataService,
    private feedbackMessagesRepository: FeedbackMessagesRepository
  ) {}

  get formControls(): FeedbackFormControls {
    return this.currentForm.controls as any;
  }

  public ngOnInit() {
    this.loading = true;

    this.analyticsTrackerService.initPageTracking(['Checkup', 'Feedback'], 'Checkup Feedback');
    this.populateSliderOptions();
    this.preInitEmptyForm();

    this.route.parent.params.subscribe((params) => {
      this.checkupId = +params.checkupId;
      this.loadCheckupResults(+params.packageId, +params.checkupId);
    });
  }

  public ngOnDestroy() {
    this.listenObsAddedSuccessObservable.unsubscribe();
  }

  public selectReviewScore(score: ReviewScoreMessage) {
    if (
      (this.selectedScore && this.selectedScore.review_score === score.review_score) ||
      this.checkup.reviewed
    ) {
      return;
    }

    this.analyticsTrackerService.trackEvent(
      AnalyticsTracking.ACTIONS.SELECT_OPTION,
      `Select Feedback "${score.review_score}"`
    );
    this.selectedScore = score;
    this.currentForm.controls.score.setValue(score.review_score);

    /* Reset form */
    this.currentForm.controls.scoreInput.setValue('');
    this.currentForm.controls.scoreInput.setErrors(null);
    this.currentForm.controls.scoreInput.clearValidators();
    this.currentForm.controls.scoreInput.markAsPristine();
    this.currentForm.controls.scoreInput.markAsUntouched();
    this.currentForm.controls.scoreOptions.setValue([]);
    this.currentForm.controls.scoreOptions.setErrors(null);
    this.currentForm.controls.scoreOptions.clearValidators();
    this.currentForm.controls.scoreOptions.markAsPristine();

    /* Reset the selectedScore */
    if (this.selectedScore && this.selectedScore.options && this.selectedScore.options.values) {
      this.selectedScore.options.values.forEach((option) => {
        option.selected = false;
      });
    }

    /* Add Validators if required */
    if (score.custom_input && score.custom_input.required) {
      this.currentForm.controls.scoreInput.setValidators([Validators.required]);
    }
    if (score.options && score.options.required) {
      this.currentForm.controls.scoreOptions.setValidators([Validators.required]);
      this.currentForm.controls.scoreOptions.updateValueAndValidity();
    }

    const feedbackMessage = this.feedbackMessagesRepository.getFeedbackMessage(
      this.checkup.checkupId
    );

    if (feedbackMessage?.score.review_score === this.selectedScore.review_score) {
      this.currentForm.controls.scoreInput.setValue(feedbackMessage.text);
    }

    this.changeDetectorRef.detectChanges();
  }

  /*
   Process selected option
   */

  public selectAnswerOption(option: ReviewScoreOption) {
    if (this.checkup.reviewed || this.selectedScore === null) {
      return;
    }

    if (this.selectedScore.options) {
      switch (this.selectedScore.options.type) {
        case 'multiple':
          // eslint-disable-next-line no-param-reassign
          option.selected = !option.selected;
          break;
        case 'single':
          this.resetAllOptions();
          // eslint-disable-next-line no-param-reassign
          option.selected = !option.selected;
          break;
        default:
          break;
      }

      this.updateOptionsControl();
    }
  }

  public submitCheckupReview() {
    /* TODO: Show errors */
    if (this.sendingReview || !this.currentForm.valid || !this.isSelectedOptionValid()) {
      this.showAllFormErrorMessages();
      return;
    }
    if (this.checkUnsavedContent()) {
      return;
    }

    if (!this.checkup.reviewed) {
      this.checkup.reviewScore = this.currentForm.value.score;
      this.checkup.reviewMessage = this.currentForm.value.scoreInput;
      this.checkup.reviewOptions = this.currentForm.value.scoreOptions;
    }

    this.checkup.healthScore = this.formControls.healthScore.value;
    this.checkup.willingnessToChange =
      this.formControls.willingnessToChange.value === SCORE_SLIDER_UNDEFINED_STEP
        ? null
        : this.formControls.willingnessToChange.value;
    this.sendingReview = true;

    let analyticsRequestType: string = AnalyticsTracking.ACTIONS.REQUEST_SUBMIT_FEEDBACK;
    let actionType: string = 'Submit';

    if (this.checkup.reviewed) {
      analyticsRequestType = AnalyticsTracking.ACTIONS.REQUEST_UPDATE_FEEDBACK;
      actionType = 'Update';
    }

    const calls = [this.checkup.submitCheckupReview()];

    const teleconsultationId = parseInt(
      this.route.snapshot.queryParamMap.get('teleconsultationId'),
      10
    );
    if (teleconsultationId) {
      calls.push(this.appointmentApi.postTeleconsultationPerform(teleconsultationId));
    }

    const followupEmailValue = this.formControls.followupEmail.value;

    if (followupEmailValue !== this.ignoreFollowupEmail) {
      calls.push(this.checkupApi.postFollowupEmail(this.checkupId, followupEmailValue));
    }

    forkJoin(calls)
      .pipe(
        finalize(() => {
          this.sendingReview = false;
        })
      )
      .subscribe(
        () => {
          this.analyticsTrackerService.trackEvent(
            analyticsRequestType,
            `Successfully ${actionType} Feedback`
          );

          this.checkupService.removeCheckupFromInProgress(this.checkup.checkupId);
          this.feedbackMessagesRepository.removeMessage(this.checkup.checkupId);
          this.redirectUser();

          this.toastService.success(
            this.translationService.getInstantTranslation(
              this.checkup.reviewed
                ? 'CHECKUP.FEEDBACK.UPDATE_SUCCESS_MESSAGE'
                : 'CHECKUP.FEEDBACK.SUBMIT_SUCCESS_MESSAGE'
            )
          );
        },
        (err) => {
          this.analyticsTrackerService.trackEventError(analyticsRequestType, err.error);
          // console.log('[postCheckupReview] ERROR', err);
          this.toastService.error(err.message);
        }
      );
  }

  public updateFieldTypeRadio(fieldTitle: string, val: boolean) {
    this.checkup[fieldTitle] = val;
  }

  public openEmailPreviewModal() {
    this.checkup.reviewScore = this.currentForm.getRawValue().score;
    this.checkup.reviewMessage = this.currentForm.getRawValue().scoreInput;
    this.checkup.reviewOptions = this.currentForm.getRawValue().scoreOptions;

    this.emailPreviewModal.openModalEmailPreview(this.checkup);
  }

  public openObsModal() {
    this.parentCheckupComponent.openObsModalFromContentNotSaved();
    this.parentCheckupComponent
      .listenObsAddedSuccessEvent()
      .subscribe(() => this.submitCheckupReview());
  }

  public trackByOptions(index: number, option: string) {
    return option;
  }

  private loadFormData() {
    /* Set the selected score */

    this.checkupTagsInputComponent.loadFormData();

    const feedbackMessage = this.feedbackMessagesRepository.getFeedbackMessage(
      this.checkup.checkupId
    );

    if (this.checkup.reviewed) {
      for (const rScore of this.reviewScoresOptions) {
        if (rScore.review_score === this.checkup.reviewScore) {
          this.selectedScore = rScore;
          break;
        }
      }

      this.currentForm.controls.scoreInput.setValue(this.checkup.reviewMessage);
      this.currentForm.controls.scoreInput.disable();

      if (
        this.selectedScore !== null &&
        this.selectedScore.options !== null &&
        this.checkup.reviewOptions
      ) {
        this.currentForm.controls.scoreOptions.setValue(this.checkup.reviewOptions);
        this.updateOptionsScore();
      }
      return;
    }

    if (feedbackMessage) {
      // bind reviewScoreOptions with feedbackMessage
      for (const rScore of this.reviewScoresOptions) {
        if (rScore.review_score === feedbackMessage.score.review_score) {
          feedbackMessage.score = rScore;
          break;
        }
      }

      this.selectReviewScore(feedbackMessage.score);
    }
  }

  private preInitEmptyForm() {
    this.currentForm = this.formBuilder.group({
      scores: this.formBuilder.array([]),
      scoreOptions: [],
      willingnessToChange: [],
      scoreInput: [],
      riskFlagged: [],
      healthConditionUnknownByUser: [],
      healthScore: [],
      allowContact: [],
      tags: [],
      followupEmail: [],
    });
  }

  private checkUnsavedContent() {
    const observationNotSaved = this.checkupReviewService.getCheckupObsLoaded();

    if (
      observationNotSaved &&
      (observationNotSaved.text || observationNotSaved.observationFlags.length)
    ) {
      this.checkupWarningContentNotSavedModal.openModal(observationNotSaved);
      return true;
    }
    return false;
  }

  private redirectUser() {
    if (this.checkup.hasTeleconsultation) {
      this.router.navigate([`/appointments`]);
    } else {
      this.router.navigate([`/packages/${this.checkup.packageId}/checkups`]);
    }
  }

  private showAllFormErrorMessages() {
    Object.keys(this.currentForm.controls).forEach((field) => {
      this.currentForm.controls[field].markAsTouched();
      this.currentForm.controls[field].updateValueAndValidity();
    });
  }

  private isSelectedOptionValid() {
    if (this.selectedScore) {
      if (
        this.selectedScore.custom_input &&
        this.selectedScore.custom_input.required &&
        (this.currentForm.controls.scoreInput.invalid ||
          this.currentForm.controls.scoreInput.value === '' ||
          this.currentForm.controls.scoreInput.value === null)
      ) {
        return false;
      }
      if (this.selectedScore.options) {
        const selectedOptions = this.selectedScore.options.values.filter((option) => {
          return option.selected;
        });

        // Check if at least one answer was selected
        if (this.selectedScore.options.required && selectedOptions.length === 0) {
          return false;
        }
      }

      return true;
    }

    return false;
  }

  private loadCheckupResults(packageId: number, checkupId: number) {
    this.checkupReviewService
      .init(checkupId, packageId)
      .pipe(
        concatMap(() => {
          return this.metadataService.getFollowUpEmailTranslations().pipe(
            tap((phrases) => {
              this.followupEmailOptions = Object.keys(phrases);
            })
          );
        }),
        concatMap(() => this.checkupService.loadReviewScoresOptions()),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        () => {
          this.reviewScoresOptions = JSON.parse(
            JSON.stringify(this.checkupService.getReviewScoresOptions())
          );
          this.checkup = this.checkupReviewService.getCheckupLoaded();

          this.initForm();
          this.loadFormData();
          this.setRecentPackage();
          this.setScoreInputListener();
        },
        (err: any) => {
          // console.log('[getReviewScores] ERROR', err);
          this.toastService.error(err.message);
        }
      );
  }

  private initForm() {
    this.currentForm = this.formBuilder.group({
      score: [this.checkup.reviewScore, Validators.required],
      scoreOptions: [this.checkup.reviewOptions],
      scoreInput: [],
      riskFlagged: [this.checkup.riskFlagged, Validators.required],
      healthConditionUnknownByUser: [this.checkup.healthConditionUnknownByUser],
      healthScore: [this.checkup.healthScore, Validators.required],
      willingnessToChange: [
        this.checkup.willingnessToChange === null
          ? SCORE_SLIDER_UNDEFINED_STEP
          : this.checkup.willingnessToChange,
        Validators.required,
      ],
      allowContact: [this.checkup.allowContact, Validators.required],
      followupEmail: [this.ignoreFollowupEmail, Validators.required],
    });
  }

  /* Updates the controller with the selected options */
  private updateOptionsControl() {
    const selectedOptions = this.selectedScore.options.values.filter((option) => {
      return option.selected;
    });
    const optionIds = [];

    for (const selectedOption of selectedOptions) {
      optionIds.push(selectedOption.id);
    }

    this.currentForm.controls.scoreOptions.setValue(optionIds);
  }

  /* Unselect all options */
  private resetAllOptions() {
    const selectedOptions = this.selectedScore.options.values.filter((option) => {
      return option.selected;
    });
    for (const selectedOption of selectedOptions) {
      selectedOption.selected = false;
    }
  }

  /* Update the reviewScoresOptions with the pre-selected option */
  private updateOptionsScore() {
    for (const selectedOption of this.currentForm.value.scoreOptions) {
      for (const option of this.selectedScore.options.values) {
        if (option.id === selectedOption) {
          option.selected = true;
          break;
        }
      }
    }
  }

  private populateSliderOptions() {
    this.willingnessToChangeOptions.stepsArray = [
      {
        value: SCORE_SLIDER_UNDEFINED_STEP,
        legend: this.translationService.getInstantTranslation(
          `CHECKUP.FEEDBACK.WILLINGNESS_TO_CHANGE.STEP_UNDEFINED`
        ),
      },
    ];

    for (let i = 0; i < 5; i += 1) {
      this.willingnessToChangeOptions.stepsArray.push({
        value: i,
        legend: this.translationService.getInstantTranslation(
          `CHECKUP.FEEDBACK.WILLINGNESS_TO_CHANGE.STEP_${i}`
        ),
      });
    }
  }

  private setRecentPackage() {
    const packageInfo: PackageInfo = {
      title: this.checkup.clientName,
      token: this.checkup.voucher,
      packageId: this.checkup.packageId,
      teleconsultation: this.checkup.hasTeleconsultation,
    };
    this.packageService.setRecentPackage(packageInfo);
  }

  private setScoreInputListener() {
    this.currentForm.controls.scoreInput.valueChanges
      .pipe(
        throttle(() => interval(200), { trailing: true }),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        if (!this.selectedScore) {
          return;
        }

        const { checkupId } = this.checkup;

        const feedbackMessage = this.feedbackMessagesRepository.getFeedbackMessage(checkupId);
        const payload = { checkup_id: checkupId, text: value, score: this.selectedScore };

        // When there's a new value and there is no value assigned anywhere -- first access
        // This one serves as a guarantee that the feedbackMessage will exist from this point on in the function
        if (feedbackMessage === undefined && value !== '' && value !== undefined) {
          this.feedbackMessagesRepository.addMessage(payload);
          return;
        }

        // prevents the need for optional chaining
        // this case can happen when the user focus on the feedback fields, without typing a value
        if (feedbackMessage === undefined || feedbackMessage === null) {
          return;
        }

        // When there's a message already filled on another review score and the user accesses another one
        if (
          value === '' &&
          feedbackMessage.text !== '' &&
          feedbackMessage.score.review_score !== this.selectedScore.review_score
        ) {
          const warningPhrase = this.translationService.getInstantTranslation(
            'CHECKUP.FEEDBACK.SCORE_WARNING',
            { score: feedbackMessage.score.review_score }
          );
          this.toastService.warning(warningPhrase);
          return;
        }

        // If the user doesn't change the review score or access another review score without typing anything on it, the action is ignored
        if (
          feedbackMessage.text === value ||
          (feedbackMessage.score.review_score !== this.selectedScore.review_score && value === '')
        ) {
          return;
        }

        // If the user deletes the review score the message is removed
        if (
          value === '' &&
          feedbackMessage.score.review_score === this.selectedScore.review_score
        ) {
          this.feedbackMessagesRepository.removeMessage(checkupId);
          return;
        }

        // Save the value on typing on the review score
        this.feedbackMessagesRepository.addMessage(payload);
      });
  }
}
