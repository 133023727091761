import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';

//= =======================================================================
// COMPONENTS
//= =======================================================================

import { FormErrorMessageComponent } from '../components/form-error-message/form-error-message.component';
import { LoadingSpinnerComponent } from '../components/loading-spinner/loading-spinner.component';
import { CheckupNextStepButtonComponent } from '../components/checkup-next-step-button/checkup-next-step-button.component';
import { ViewSelectorMenuComponent } from '../components/view-selector-menu/view-selector-menu.component';
import { CategorySelectorMenuComponent } from '../components/category-selector-menu/category-selector-menu.component';
import { CheckupObservationAndTagsModalComponent } from '../components/checkup-observation-and-tags-modal/checkup-observation-and-tags-modal.component';
import { CheckupObservationFormComponent } from '../components/checkup-observation-form/checkup-observation-form.component';
import { CheckupObservationCardComponent } from '../components/checkup-observation-card/checkup-observation-card.component';
import { CheckupWarningContentNotSavedComponent } from '../components/checkup-warning-content-not-saved/checkup-warning-content-not-saved.component';
import { CheckupTagsInputComponent } from '../components/checkup-tags-input/checkup-tags-input.component';
import { LoadingDotsComponent } from '../components/loading-dots/loading-dots.component';
import { CheckupObservationBoxComponent } from '../components/checkup-observation-box/checkup-observation-box.component';
import { CheckupFeedbackEmailPreviewComponent } from '../components/checkup-feedback-email-preview/checkup-feedback-email-preview.component';
import { PackageObservationModalComponent } from '../components/package-observation-modal/package-observation-modal.component';
import { MenuCheckupListComponent } from '../components/menu-checkup-list/menu-checkup-list.component';
import { ContentParserComponent } from '../components/content-parser/content-parser.component';
import { CheckupKioskButtonComponent } from '../components/checkup-kiosk-button/checkup-kiosk-button.component';
import { CheckupPopupBlockedHelpComponent } from '../components/checkup-popup-blocked-help/checkup-popup-blocked-help.component';
import { AppointmentMenuComponent } from '../components/appointment-menu/appointment-menu.component';

//= =======================================================================
// PIPES
//= =======================================================================

import { KeysPipe } from '../pipes/keys.pipe';
import { TranslateFilterNamePipe } from '../pipes/translateFilterName.pipe';
import { GenderPipe } from '../pipes/gender.pipe';
import { AppointmentStatusPipe } from '../pipes/appointmentStatus.pipe';
import { AppointmentUnavailablePipe } from '../pipes/appointmentUnavailable.pipe';
import { AppointmentDatePipe } from '../pipes/appointmentDate.pipe';
import { FormatDatePipe } from '../pipes/formatDate.pipe';

//= =======================================================================
// Directives
//= =======================================================================

import { MultiLineElipsisDirective } from '../directives/multi-line-elipsis.directive';

@NgModule({
  declarations: [
    KeysPipe,
    FormatDatePipe,
    TranslateFilterNamePipe,
    GenderPipe,
    AppointmentStatusPipe,
    AppointmentUnavailablePipe,
    AppointmentDatePipe,
    FormErrorMessageComponent,
    LoadingSpinnerComponent,
    LoadingDotsComponent,
    MultiLineElipsisDirective,
    CheckupNextStepButtonComponent,
    CategorySelectorMenuComponent,
    ViewSelectorMenuComponent,
    CheckupObservationAndTagsModalComponent,
    CheckupObservationFormComponent,
    CheckupObservationCardComponent,
    CheckupWarningContentNotSavedComponent,
    CheckupTagsInputComponent,
    CheckupObservationBoxComponent,
    CheckupFeedbackEmailPreviewComponent,
    PackageObservationModalComponent,
    MenuCheckupListComponent,
    ContentParserComponent,
    CheckupKioskButtonComponent,
    CheckupPopupBlockedHelpComponent,
    AppointmentMenuComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgSelectModule,
    TranslateModule.forChild({}),
  ],
  exports: [
    TranslateModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    KeysPipe,
    FormatDatePipe,
    TranslateFilterNamePipe,
    GenderPipe,
    AppointmentStatusPipe,
    AppointmentUnavailablePipe,
    AppointmentDatePipe,
    MultiLineElipsisDirective,
    FormErrorMessageComponent,
    LoadingSpinnerComponent,
    LoadingDotsComponent,
    CheckupNextStepButtonComponent,
    CategorySelectorMenuComponent,
    ViewSelectorMenuComponent,
    CheckupObservationAndTagsModalComponent,
    CheckupObservationFormComponent,
    CheckupObservationCardComponent,
    CheckupWarningContentNotSavedComponent,
    CheckupTagsInputComponent,
    CheckupObservationBoxComponent,
    CheckupFeedbackEmailPreviewComponent,
    PackageObservationModalComponent,
    MenuCheckupListComponent,
    ContentParserComponent,
    CheckupKioskButtonComponent,
    CheckupPopupBlockedHelpComponent,
    AppointmentMenuComponent,
  ],
})
export class SharedModule {}
