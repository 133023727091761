import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  LanguageApiMetadataService,
  PhoneCountryCodesApiMetadataService,
  PhrasesApiMetadataService,
} from './api-metadata.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiMetadataService {
  constructor(private http: HttpClient) {}

  public getLanguages() {
    return this.http.get<LanguageApiMetadataService[]>(`${environment.api}/metadata/languages`);
  }

  public getTimezones() {
    return this.http.get<string[]>(`${environment.api}/metadata/timezones`);
  }

  public getPhoneCountryCodes() {
    return this.http.get<PhoneCountryCodesApiMetadataService>(
      `${environment.api}/metadata/phone_country_codes`
    );
  }

  public getTranslations() {
    return this.http.get<PhrasesApiMetadataService>(`${environment.api}/metadata/phrases/docapp`);
  }

  public getFollowUpEmailTranslations() {
    return this.http.get<PhrasesApiMetadataService>(
      `${environment.api}/metadata/phrases/followup-email`
    );
  }
}
