import { Injectable } from '@angular/core';
import { Utils } from '../../class/utils/utils.class';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  public myStorage = window.localStorage;

  constructor(private utils: Utils) {}

  public set(key: string, content: {}): boolean {
    try {
      this.myStorage.setItem(key, JSON.stringify(content));
      return true;
    } catch (err) {
      return false;
    }
  }

  public get(key: string): any {
    try {
      const parsedContent: {} = this.utils.getJson(this.myStorage.getItem(key)) || {};
      return parsedContent;
    } catch (err) {
      return false;
    }
  }

  public delete(key: string): boolean {
    try {
      this.myStorage.removeItem(key);
      return true;
    } catch (err) {
      return false;
    }
  }

  public clear(): boolean {
    try {
      this.myStorage.clear();
      return true;
    } catch (err) {
      return false;
    }
  }
}
