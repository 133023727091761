import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-checkup-age-card',
  templateUrl: './checkup-age-card.component.html',
  styleUrls: ['./checkup-age-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckupAgeCardComponent implements OnInit {
  @Input()
  public name: string = null;

  @Input()
  public currentAge: number = null;

  @Input()
  public previousAge: number = null;

  public biomarkerGroupsIcons: { [key: number]: string } = {
    1: 'heart',
    2: 'activity',
    4: 'metabolism',
    5: 'body',
    7: 'respiratory',
    8: 'brain',
  };

  public biomarkerGroupIcon: string;

  @Input('groupId') set loadDateFromNow(groupId: number) {
    if (groupId) {
      this.biomarkerGroupIcon = this.getIcon(groupId);
    }
  }

  public ngOnInit() {}

  public getIcon(groupId: number) {
    const icon: string = this.biomarkerGroupsIcons[groupId];

    if (icon) {
      return `./assets/images/icons/biomarkers-groups/${icon}.svg`;
    }
    return null;
  }
}
