import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import { getEntity, withEntities, upsertEntities, deleteEntities } from '@ngneat/elf-entities';
import { persistState, localStorageStrategy } from '@ngneat/elf-persist-state';
import { ReviewScoreMessage } from '../../interfaces/checkup-feedback.interface';

export interface FeedbackMessagesState {
  text: string;
  checkup_id: number;
  score: ReviewScoreMessage;
}

const store = createStore(
  { name: 'feedback-messages' },
  withEntities<FeedbackMessagesState, 'checkup_id'>({ idKey: 'checkup_id', initialValue: [] })
);

export let persist;

@Injectable({
  providedIn: 'root',
})
export class FeedbackMessagesRepository {
  constructor() {
    persist = persistState(store, {
      key: 'feedback-messages',
      storage: localStorageStrategy,
    });
  }

  addMessage(payload: FeedbackMessagesState) {
    return store.update(upsertEntities(payload));
  }

  removeMessage(checkupId: number) {
    return store.update(deleteEntities([checkupId]));
  }

  getFeedbackMessage(checkupId: number) {
    return store.query(getEntity(checkupId));
  }

  reset() {
    store.reset();
  }
}
