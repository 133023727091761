import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  Input,
  Renderer2,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { of as observableOf, Observable, Subject } from 'rxjs';
import { switchMap, debounceTime, finalize, map } from 'rxjs/operators';
import {
  CheckupChallengeClass,
  CheckupChallengeFactory,
} from '../../class/checkup-challenge/checkup-challenge.class';
import { ToastService } from '../../services/toast-service/toast-service.service';
import { ChallengeToInclude } from './checkup-challenges-search.interface';
import { AnalyticsTrackerService } from '../../services/analytics-tracker/analytics-tracker.service';
import { AnalyticsTracking } from '../../interfaces/analytics.interface';

@Component({
  selector: 'app-checkup-challenges-search',
  templateUrl: './checkup-challenges-search.component.html',
  styleUrls: ['./checkup-challenges-search.component.scss'],
})
export class CheckupChallengesSearchComponent implements OnInit, OnDestroy {
  @Input() public challengesIncluded: CheckupChallengeClass[] = [];

  @Output() public submitButtonClick = new EventEmitter();

  @ViewChild('searchBox', { static: true }) public searchBox;

  @ViewChild('scrolling') public scrolling: ElementRef;

  public isOpen: boolean = false;

  public challengesToBeIncluded: ChallengeToInclude = {};

  public loading: boolean = false;

  public checkupChallenge: CheckupChallengeClass;

  public showLoadMoreButton: boolean = false;

  public challengesIdIncluded: {} = {};

  public showNoResultsMessage: boolean = false;

  public currentPage: number = 0;

  public searchTerm$ = new Subject<string>();

  public searchObservable: any;

  public challengePreview: CheckupChallengeClass;

  public loadingPreview: boolean = false;

  public visiblePreview: boolean = true;

  constructor(
    public checkupChallengeFactory: CheckupChallengeFactory,
    public renderer: Renderer2,
    public toastService: ToastService,
    private analyticsTrackerService: AnalyticsTrackerService
  ) {}

  public ngOnInit() {
    this.checkupChallenge = this.checkupChallengeFactory.createCheckupChallenge();
    this.startSearchObservable();
  }

  public ngOnDestroy() {
    this.searchObservable.unsubscribe();
  }

  public startSearchObservable() {
    this.searchObservable = this.search(this.searchTerm$).subscribe();
  }

  public search(terms: Observable<string>) {
    return terms.pipe(
      debounceTime(400),
      switchMap(() => this.searchChallengeByTitle())
    );
  }

  public loadCheckupsIncluded() {
    this.challengesIdIncluded = {};

    for (const checkup of this.challengesIncluded) {
      this.challengesIdIncluded[checkup.challengeId] = true;
    }

    // console.log('[loadCheckupsIncluded]', this.challengesIdIncluded);
  }

  public addOrRemoveChallenge(currentChallenge: CheckupChallengeClass, $event?: Event) {
    if ($event) {
      $event.stopPropagation();
    }

    let action: string = 'Select';

    if (this.challengesToBeIncluded[currentChallenge.challengeId] !== undefined) {
      action = 'Unselect';
      delete this.challengesToBeIncluded[currentChallenge.challengeId];
    } else {
      this.challengesToBeIncluded[currentChallenge.challengeId] = { content: currentChallenge };
    }

    this.analyticsTrackerService.trackEvent(
      AnalyticsTracking.ACTIONS.SELECT_CHALLENGE,
      `${action} Challenge "${currentChallenge.analytics}"`
    );
  }

  public challengeClick(currentChallenge: CheckupChallengeClass) {
    if (this.visiblePreview) {
      this.previewChallenge(currentChallenge);
    } else {
      this.addOrRemoveChallenge(currentChallenge);
    }
  }

  public previewChallenge(currentChallenge: CheckupChallengeClass, $event?: Event) {
    if ($event) {
      $event.stopPropagation();
    }

    this.showPreview();

    if (this.loadingPreview) {
      return;
    }

    if (this.challengePreview === currentChallenge) {
      return;
    }

    this.loadingPreview = true;
    this.challengePreview = currentChallenge;

    currentChallenge.getChallengeContent().then(() => {
      this.loadingPreview = false;
    });
  }

  public showPreview() {
    this.visiblePreview = true;
  }

  public hidePreview() {
    this.visiblePreview = false;
  }

  public openModal() {
    this.analyticsTrackerService.initPageTracking(
      ['Checkup', 'Challenges Search'],
      'Challenges Search'
    );
    this.isOpen = true;
    this.loadBodyClass('add');
    this.resetSearch();
    this.loadCheckupsIncluded();
  }

  public resetSearch() {
    this.searchBox.nativeElement.value = '';
    this.checkupChallenge.challengesList = [];
    this.showLoadMoreButton = false;
    this.challengesToBeIncluded = {};
  }

  public closeModal() {
    this.analyticsTrackerService.initPageTracking(['Checkup', 'Journeys'], 'Journeys');
    this.loadBodyClass('remove');
    this.isOpen = false;
  }

  public loadMore() {
    return this.searchChallengeByTitle(true).subscribe(() => {
      if (this.showLoadMoreButton) {
        this.focusBottomPage();
      }
    });
  }

  public searchChallengeByTitle(fromShowMore: boolean = false) {
    const challengeTitle: string = this.searchBox.nativeElement.value;

    if (this.loading || challengeTitle === '') {
      this.checkupChallenge.challengesList = [];
      this.showLoadMoreButton = false;
      return observableOf().pipe(
        map((e: any) => {
          return e.target.value;
        })
      );
    }

    this.loading = true;
    this.showNoResultsMessage = false;

    if (fromShowMore) {
      this.currentPage += 1;
    } else {
      this.currentPage = 1;
    }

    this.analyticsTrackerService.trackEvent(
      AnalyticsTracking.ACTIONS.SEARCH,
      `Search Challenge "${challengeTitle}"`
    );

    return this.checkupChallenge.searchChallengesByTitle(challengeTitle, this.currentPage).pipe(
      map(
        (res: { showLoadMoreButton: boolean }) => {
          this.analyticsTrackerService.trackEvent(
            AnalyticsTracking.ACTIONS.REQUEST_SEARCH_CHALLENGES,
            'Successfully Search Challenges'
          );
          this.showLoadMoreButton = res.showLoadMoreButton;

          if (this.checkupChallenge.challengesList.length === 0) {
            this.showNoResultsMessage = true;
          }
        },
        (err) => {
          // console.log('[getChallengesByGroup] ERROR', err);
          this.analyticsTrackerService.trackEventError(
            AnalyticsTracking.ACTIONS.REQUEST_SEARCH_CHALLENGES,
            err.error
          );
          this.toastService.error(err.message);
        }
      ),
      finalize(() => {
        this.loading = false;
      })
    );

    // console.log('[searchChallengeByTitle]', challengeTitle);
  }

  public isSubmitButtonEnabled() {
    return Object.keys(this.challengesToBeIncluded).length > 0;
  }

  public addChallenges() {
    if (!this.isSubmitButtonEnabled()) {
      return;
    }

    this.analyticsTrackerService.trackEvent(
      AnalyticsTracking.ACTIONS.ADD_CHALLENGES,
      'Add Challenges'
    );

    this.submitButtonClick.emit({ challengesToBeIncluded: this.challengesToBeIncluded });
    this.closeModal();
  }

  public loadBodyClass(type: string) {
    if (type === 'add') {
      this.renderer.addClass(document.body, 'disable-scroll');
    } else {
      this.renderer.removeClass(document.body, 'disable-scroll');
    }
  }

  private focusBottomPage() {
    setTimeout(() => {
      this.scrolling.nativeElement.scrollIntoView(true);
    }, 200);
  }
}
