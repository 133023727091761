<div class="float-element">
  <div class="container">
    <div class="modal" [class.modal-open]="isOpen">
      <div class="modal-title" (click)="toggleModal()">
        <i class="modal-icon u-icon ion-md-chatboxes"></i>

        <h5 *ngIf="!isOpen">
          <span>{{ 'OBSERVATIONS' | translate }} & {{ 'TAGS' | translate }}</span>
        </h5>

        <div class="toggle" (click)="onTabClick($event)" *ngIf="isOpen">
          <span [class.active]="getTabSelected() === 'observations'"
            (click)="selectTab('observations')">
            {{ 'OBSERVATIONS' | translate }}
          </span>
          <span [class.active]="getTabSelected() === 'tags'" (click)="selectTab('tags')">
            {{ 'CHECKUP.FEEDBACK.TAGS_TITLE' | translate }}
          </span>
        </div>

        <a class="close-modal">
          <i class="u-icon ion-ios-arrow-up"></i>
        </a>
      </div>

      <div class="modal-content">
        <div class="tab-content tab-content--tags" [class.is-hidden]="getTabSelected() !== 'tags'">
          <app-checkup-tags-input #checkupTagsInputComponent [checkupId]="checkupId"
            [appendToBody]="true"></app-checkup-tags-input>
        </div>

        <div class="tab-content" [class.is-hidden]="getTabSelected() !== 'observations'">
          <app-checkup-observation-box #checkupObservationBoxComponent
            (onSave)="newObservationAdded()"></app-checkup-observation-box>
        </div>
      </div>
    </div>
  </div>
</div>