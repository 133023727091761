import { Component, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import DomManipulationUtility from '../../utils/dom-manipulation';

@Component({
  selector: 'content-parser',
  templateUrl: './content-parser.component.html',
  styleUrls: ['./content-parser.component.scss'],
})
export class ContentParserComponent implements AfterViewInit {
  @Input() public descriptionContent = '';

  @ViewChild('descriptionContainer', { static: true })
  public descriptionElement: ElementRef;

  public ngAfterViewInit() {
    this.parseLinks();
    this.addPhotoViewEventsToImages();
  }

  private addPhotoViewEventsToImages() {
    // Enable pitch and zoom using PhotoViewer
    const imgElements = this.descriptionElement.nativeElement.getElementsByTagName('img');

    for (const img of imgElements) {
      const magnifierIcon = document.createElement('div');
      magnifierIcon.setAttribute('class', 'overlay-icon-image');

      img.parentNode.insertBefore(magnifierIcon, img.nextSibling);
    }
  }

  private parseLinks() {
    const aElements: any[] = this.descriptionElement.nativeElement.getElementsByTagName('a');

    if (aElements.length > 0) {
      for (let i = aElements.length - 1; i >= 0; i -= 1) {
        this.parseElement(aElements[i]);
      }
    }
  }

  private parseElement(aElement: any) {
    try {
      const link: any = aElement.href;
      const stringLink: string = link.toString();

      if (stringLink.search('youtube') >= 0 || stringLink.search('youtu.be') >= 0) {
        this.parseYoutubeLink(aElement, link, stringLink);
      } else if (
        stringLink.search('biomarker/') >= 0 ||
        stringLink.search('group/') >= 0 ||
        stringLink.search('article/') >= 0
      ) {
        // do nothing
      } else {
        this.parseOutboundLink(aElement, stringLink);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('[ContentParserClass] Error parsing content', err);
    }
  }

  private parseOutboundLink(currentElement: any, stringLink: string) {
    DomManipulationUtility.replaceLinkWithClickableLink(currentElement, stringLink);
  }

  private parseYoutubeLink(currentElement: any, link: any, stringLink: string) {
    // console.log('[parseYoutubeLink]', link);

    DomManipulationUtility.replaceLinkWithIframe(currentElement, link, stringLink);
  }
}
