<div *ngIf="!loading">
  <div [ngSwitch]="currentStatus">
    <div class="wrapper" *ngSwitchCase="STATUS.READY">
      <div class="container"
        [class.container__message]="checkup.teleconsultationPhone || checkup.teleconsultationReason">
        <i class="u-icon ion-md-call icon"></i>
        <span class="title">
          {{ 'CHECKUP.INTEGRATION.CALL_CURRENT_USER' | translate }}
        </span>

        <a class="button-rounded ready" (click)="startCall()" *ngIf="!teleIntegrationLoading">
          <i class="u-icon ion-md-call"></i>
          {{ 'CHECKUP.INTEGRATION.START_CALL_BUTTON' | translate }}
        </a>

        <a class="button-rounded loading" *ngIf="teleIntegrationLoading">
          <img src="./assets/images/spinner-loader-white.svg">
        </a>
      </div>

      <div class="message" *ngIf="checkup.teleconsultationPhone">
        <i class="u-icon ion-md-return-right"></i>
        <p class="text">
          {{ 'CHECKUP.INTEGRATION.USER_PHONE_MESSAGE' | translate:{phone: checkup.teleconsultationPhone} }}
        </p>
      </div>
      <div class="message" *ngIf="checkup.teleconsultationReason">
        <i class="fa fa-comment"></i>
        <p class="text">
          {{ 'CHECKUP.INTEGRATION.USER_REASON' | translate }} {{checkup.teleconsultationReason}}
        </p>
      </div>
    </div>

    <div class="wrapper" *ngSwitchCase="STATUS.CONNECTED_SAME_USER">
      <div class="container container__message">
        <i class="u-icon ion-md-link"></i>
        <span class="title">
          {{ 'CHECKUP.INTEGRATION.USER_IS_CONNECTED' | translate }}
        </span>

        <a class="button-rounded impersonate" (click)="openMobileApp()"
          *ngIf="!appOpened && !appIntegrationLoading">
          <i class="u-icon ion-md-person-add"></i>
          {{ 'CHECKUP.INTEGRATION.IMPERSONATE_BUTTON' | translate }}
        </a>

        <a class="button-rounded loading" *ngIf="appOpened && appIntegrationLoading">
          <img src="./assets/images/spinner-loader-white.svg">
        </a>

        <a class="button-rounded hungup" (click)="hungUp()">
          <i class="fas fa-phone-slash"></i>
          {{ 'CHECKUP.INTEGRATION.HUNG_UP_BUTTON' | translate }}
        </a>
      </div>
      <div class="message">
        <i class="u-icon ion-md-return-right"></i>
        <p class="text">
          {{ 'CHECKUP.INTEGRATION.REFRESH_MESSAGE' | translate }}
        </p>
      </div>
      <div class="message" *ngIf="checkup.teleconsultationReason">
        <i class="fa fa-comment"></i>
        <p class="text">
          {{ 'CHECKUP.INTEGRATION.USER_REASON' | translate }} {{checkup.teleconsultationReason}}
        </p>
      </div>
    </div>

    <div class="wrapper" *ngSwitchCase="STATUS.CONNECTED_DIFFERENT_USER">
      <div class="container container__message">
        <i class="u-icon ion-md-call icon"></i>
        <span class="title">
          {{ 'CHECKUP.INTEGRATION.DIFFERENT_USER' | translate }}
        </span>

        <a class="button-rounded impersonate" (click)="startCall()" *ngIf="!appIntegrationLoading">
          <i class="u-icon ion-md-person-add"></i>
          {{ 'CHECKUP.INTEGRATION.IMPERSONATE_BUTTON' | translate }}
        </a>

        <a class="button-rounded loading" *ngIf="appIntegrationLoading">
          <img src="./assets/images/spinner-loader-white.svg">
        </a>

        <a class="button-rounded icon-only hungup" (click)="hungUp()">
          <i class="fas fa-phone-slash"></i>
        </a>
      </div>
      <div class="message">
        <i class="u-icon ion-md-return-right"></i>
        <p class="text">
          {{ 'CHECKUP.INTEGRATION.CALL_DIFFERENT_USER_MESSAGE' | translate }}
        </p>
      </div>
    </div>

    <div class="wrapper" *ngSwitchCase="STATUS.READY_KIOSK">
      <div class="container">
        <i class="u-icon ion-md-business icon"></i>
        <span class="title">
          {{ 'CHECKUP.INTEGRATION.CALL_CURRENT_KIOSK' | translate:{voucher: checkup.voucher} }}
        </span>

        <a class="button-rounded ready" (click)="startCall()" *ngIf="!teleIntegrationLoading">
          <i class="u-icon ion-md-call"></i>
          {{ 'CHECKUP.INTEGRATION.START_CALL_KIOSK_BUTTON' | translate }}
        </a>

        <a class="button-rounded loading" *ngIf="teleIntegrationLoading">
          <img src="./assets/images/spinner-loader-white.svg">
        </a>
      </div>
    </div>

    <div class="wrapper" *ngSwitchCase="STATUS.CONNECTED_SAME_KIOSK_SAME_USER">
      <div class="container container__message">
        <i class="u-icon ion-md-business icon"></i>
        <span class="title">
          {{ 'CHECKUP.INTEGRATION.KIOSK_IS_CONNECTED' | translate:{voucher: checkup.voucher} }}
        </span>

        <a class="button-rounded loading" *ngIf="appOpened && appIntegrationLoading">
          <img src="./assets/images/spinner-loader-white.svg">
        </a>

        <a class="button-rounded hungup" (click)="hungUp()">
          <i class="fas fa-phone-slash"></i>
          {{ 'CHECKUP.INTEGRATION.HUNG_UP_BUTTON' | translate }}
        </a>
      </div>
      <div class="message" *ngIf="currentWellabeId">
        <i class="u-icon ion-md-return-right"></i>
        <p class="text">
          {{ 'CHECKUP.INTEGRATION.IMPERSONATED_USER' | translate:{wellabeId:currentWellabeId} }}
        </p>
      </div>
      <div class="message">
        <i class="u-icon ion-md-return-right"></i>
        <p class="text">
          {{ 'CHECKUP.INTEGRATION.REFRESH_MESSAGE' | translate }}
        </p>
      </div>
    </div>

    <div class="wrapper" *ngSwitchCase="STATUS.CONNECTED_SAME_KIOSK_DIFFERENT_USER">
      <div class="container container__message">
        <i class="u-icon ion-md-business icon"></i>
        <span class="title">
          {{ 'CHECKUP.INTEGRATION.KIOSK_IS_CONNECTED' | translate:{voucher: checkup.voucher} }}
        </span>

        <a class="button-rounded impersonate" (click)="startCall()" *ngIf="!appIntegrationLoading">
          <i class="u-icon ion-md-person-add"></i>
          {{ 'CHECKUP.INTEGRATION.IMPERSONATE_BUTTON' | translate }}
        </a>

        <a class="button-rounded loading" *ngIf="appOpened && appIntegrationLoading">
          <img src="./assets/images/spinner-loader-white.svg">
        </a>

        <a class="button-rounded icon-only hungup" (click)="hungUp()">
          <i class="fas fa-phone-slash"></i>
        </a>
      </div>
      <div class="message" *ngIf="currentWellabeId">
        <i class="u-icon ion-md-return-right"></i>
        <p class="text">
          {{ 'CHECKUP.INTEGRATION.IMPERSONATED_USER' | translate:{wellabeId:currentWellabeId} }}
        </p>
      </div>
      <div class="message">
        <i class="u-icon ion-md-return-right"></i>
        <p class="text">
          {{ 'CHECKUP.INTEGRATION.REFRESH_MESSAGE' | translate }}
        </p>
      </div>
    </div>

    <div class="wrapper" *ngSwitchCase="STATUS.CONNECTED_DIFFERENT_KIOSK">
      <div class="container">

        <ng-container *ngIf="isTeleconsultation; else differentKioskMessage">
          <i class="u-icon ion-md-call icon"></i>
          <span class="title">
            {{ 'CHECKUP.INTEGRATION.DIFFERENT_USER' | translate }}
          </span>
        </ng-container>

        <ng-template #differentKioskMessage>
          <i class="u-icon ion-md-business icon"></i>
          <span class="title">
            {{ 'CHECKUP.INTEGRATION.KIOSK_IS_CONNECTED' | translate:{voucher: currentPackageId} }}
          </span>
        </ng-template>

        <a class="button-rounded ready" (click)="startCall()" *ngIf="!teleIntegrationLoading">
          <i class="u-icon ion-md-call"></i>
          {{ 'CHECKUP.INTEGRATION.START_CALL_KIOSK_BUTTON' | translate }}
        </a>

        <a class="button-rounded loading" *ngIf="teleIntegrationLoading">
          <img src="./assets/images/spinner-loader-white.svg">
        </a>

        <a class="button-rounded icon-only hungup" (click)="hungUp()">
          <i class="fas fa-phone-slash"></i>
        </a>
      </div>
    </div>
  </div>
</div>

<div *ngIf="loading">
  <div class="container c-fake-content"></div>
</div>

<app-checkup-popup-blocked-help #popupBlockedHelpComponent (onRetry)="startCall()">
</app-checkup-popup-blocked-help>