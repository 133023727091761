<div *ngIf="!loading">
  <div class="biomarker-box" #biomarkerGroupBox *ngFor="let keyGroup of resultsByGroup"
    id="biomarker-group-{{ keyGroup.group_id }}">
    <div class="biomarker-box-title">
      <h2>{{ keyGroup.name }}</h2>

      <div class="biomarker-dates" *ngIf="previousPerformedAt && currentPerformedAt">
        <span>{{ previousPerformedAt | date:'MMM yyyy' }}</span>
        <img src="./assets/images/icons/caret-right-solid.svg" />
        <span>{{ currentPerformedAt | date:'MMM yyyy' }}</span>
      </div>
    </div>
    <div class="generic-panel">
      <ul>
        <app-checkup-age-card [name]="keyGroup.name" [groupId]="keyGroup.group_id"
          [previousAge]="keyGroup.ages.previous" [currentAge]="keyGroup.ages.current">
        </app-checkup-age-card>
        <li *ngFor="let keyBiomarker of keyGroup.biomarkers">
          <app-checkup-biomarker-card [name]="keyBiomarker.doc_name" [unit]="keyBiomarker.unit"
            [type]="keyBiomarker.type" [currentCheckup]="keyBiomarker.values.current"
            [previousCheckup]="keyBiomarker.values.previous"
            [hasSelfReportedBiomarker]="keyBiomarker.has_self_reported_values">
          </app-checkup-biomarker-card>
        </li>
      </ul>
    </div>
  </div>
</div>

<div *ngIf="loading">
  <div class="biomarker-box" *ngFor="let key of [1, 2, 3, 4]">
    <h2 class="fake-content"></h2>
    <div class="generic-panel fake-content-biomarker-list">
      <ul>
        <li *ngFor="let key of [1, 2, 3, 4]" class="fake-content fake-content-biomarker-item"></li>
      </ul>
    </div>
  </div>
</div>