import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { concatMap, finalize } from 'rxjs/operators';
import {
  CheckupChallengeClass,
  CheckupChallengeFactory,
} from '../../class/checkup-challenge/checkup-challenge.class';
import { ToastService } from '../../services/toast-service/toast-service.service';
import { TranslationService } from '../../services/translation/translation.service';
import { CheckupClass } from '../../class/checkup/checkup.class';
import { AnalyticsTrackerService } from '../../services/analytics-tracker/analytics-tracker.service';
import { CheckupReviewService } from '../../services/checkup-review/checkup-review.service';
import { PackageInfo } from '../../interfaces/package.interface';
import { PackageService } from '../../services/package/package.service';

@Component({
  selector: 'app-checkup-challenges',
  templateUrl: './checkup-challenges.component.html',
  styleUrls: ['./checkup-challenges.component.scss'],
})
export class CheckupChallengesComponent implements OnInit {
  @ViewChild('sidebarMenu') public sidebarMenu;

  @ViewChild('challengesBox', { static: true }) public challengesBox;

  public checkupChallenge: CheckupChallengeClass;

  public loading: boolean = false;

  public checkupLink: string;

  public checkup: CheckupClass;

  private sub: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translationService: TranslationService,
    private toastService: ToastService,
    private analyticsTrackerService: AnalyticsTrackerService,
    private checkupReviewService: CheckupReviewService,
    private checkupChallengeFactory: CheckupChallengeFactory,
    private packageService: PackageService
  ) {}

  public ngOnInit() {
    this.analyticsTrackerService.initPageTracking(['Checkup', 'Journeys'], 'Checkup Journeys');
    this.loading = true;

    this.sub = this.activatedRoute.parent.params.subscribe((params) => {
      this.loadCheckupsAndChallengesByGroups(+params.packageId, +params.checkupId);
    });
  }

  public loadCheckupsAndChallengesByGroups(packageId: number, checkupId: number) {
    this.checkupChallenge = this.checkupChallengeFactory.createCheckupChallenge();

    this.checkupReviewService
      .init(checkupId, packageId)
      .pipe(
        concatMap(() => this.checkupReviewService.loadCheckupChallenges()),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        () => {
          this.checkup = this.checkupReviewService.getCheckupLoaded();
          this.checkupChallenge = this.checkupReviewService.getCheckupChallengesLoaded();

          this.challengesBox.loadingComplete();
          this.setRecentPackage();
        },
        (err) => {
          this.toastService.error(err.message);
        }
      );
  }

  public onSaving() {
    this.loading = true;
  }

  public onSaved() {
    this.loading = false;
  }

  private setRecentPackage() {
    const packageInfo: PackageInfo = {
      title: this.checkup.clientName,
      token: this.checkup.voucher,
      packageId: this.checkup.packageId,
      teleconsultation: this.checkup.hasTeleconsultation,
    };
    this.packageService.setRecentPackage(packageInfo);
  }
}
