import * as Rollbar from 'rollbar';

import { Injectable, Inject, InjectionToken, ErrorHandler } from '@angular/core';
import { environment } from '../../../environments/environment';

const rollbarConfig = environment.rollbar;

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err: any): void {
    this.rollbar.error(err.originalError || err);
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}
