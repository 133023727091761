export const environment = {
  production: true,
  currentEnv: 'production',
  api: 'https://api.wellabe.fit',
  teleapp: 'https://tele.wellabe.fit',
  app: 'https://app.wellabe.fit/',
  rollbar: {
    accessToken: '81b7012cf50c411fb3d4de4866a4e904',
    payload: {
      environment: 'prod',
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: '1.0.0',
          guess_uncaught_frames: true,
        },
      },
    },
    enabled: true,
  },
  analytics: {
    trackerUrl: 'https://track.wellabe.fit/',
    siteId: '12',
    subdomain: '*.doc.wellabe.fit',
  },
  cookie: {
    privacy_link: 'https://web.wellabe.fit/terms/policy-terms',
  },
  appointments: {
    supportPagination: false,
  },
};
