import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { CheckupChallengeClass } from '../../class/checkup-challenge/checkup-challenge.class';
import { CheckupChallengesSearchComponent } from '../checkup-challenges-search/checkup-challenges-search.component';
import { ToastService } from '../../services/toast-service/toast-service.service';
import { TranslationService } from '../../services/translation/translation.service';
import { AnalyticsTracking } from '../../interfaces/analytics.interface';
import { AnalyticsTrackerService } from '../../services/analytics-tracker/analytics-tracker.service';
import { CheckupClass } from '../../class/checkup/checkup.class';

@Component({
  selector: 'app-checkup-challenges-box',
  templateUrl: './checkup-challenges-box.component.html',
  styleUrls: ['./checkup-challenges-box.component.scss'],
})
export class CheckupChallengesBoxComponent implements OnInit {
  @ViewChild('scrolling', { static: true }) public scrolling: ElementRef;

  @ViewChild('challengesSearch', { static: true }) public challengesSearch;

  @ViewChild(CheckupChallengesSearchComponent, { static: true })
  public checkupChallengesSearchComponent: CheckupChallengesSearchComponent;

  @Input() public checkup: CheckupClass = null;

  @Input() public checkupChallenge: CheckupChallengeClass = null;

  @Input() public challengeIncludedForCheckup: boolean = false;

  @Output() public onSaving = new EventEmitter();

  @Output() public onSaved = new EventEmitter();

  public loading: boolean = true;

  public sortablejsOptions = {
    filter: '.disabled',
    onMove: (event: any) => {
      return !event.related.classList.contains('disabled');
    },
    onUpdate: (event: any) => {
      this.analyticsTrackerService.trackEvent(
        AnalyticsTracking.ACTIONS.REORDER_LIST,
        'Reorder Challenges List'
      );
      this.checkupChallenge.updateChallengesOrder();

      this.submitChallengesChanges().catch(() => {
        // revert changes on error
        const challenge = this.checkupChallenge.challengesList.splice(event.newIndex, 1);
        this.checkupChallenge.challengesList.splice(event.oldIndex, 0, challenge[0]);
        this.checkupChallenge.updateChallengesOrder();
      });
    },
  };

  constructor(
    public toastService: ToastService,
    private analyticsTrackerService: AnalyticsTrackerService,
    public translationService: TranslationService
  ) {}

  public ngOnInit() {}

  public loadingComplete() {
    this.loading = false;
  }

  public removeChallenge(challenge: CheckupChallengeClass) {
    const oldIndex = this.checkupChallenge.challengesList.indexOf(challenge);

    this.analyticsTrackerService.trackEvent(
      AnalyticsTracking.ACTIONS.REMOVE_CHALLENGE,
      `Remove Challenge "${challenge.analytics}"`
    );
    this.checkupChallenge.removeChallengeFromList(challenge);
    this.challengesSearch.loadCheckupsIncluded();

    this.submitChallengesChanges().catch(() => {
      this.checkupChallenge.challengesList.splice(oldIndex, 0, challenge);
      this.checkupChallenge.updateChallengesOrder();
    });
  }

  public addChallenges(event: any) {
    const totalItensToAdd: number = Object.keys(event.challengesToBeIncluded).length;

    Object.keys(event.challengesToBeIncluded).forEach((item) => {
      this.checkupChallenge.includeNewChallenge(event.challengesToBeIncluded[item].content);
      this.setTemporaryHighlight(event.challengesToBeIncluded[item].content.challengeId);
    });

    if (totalItensToAdd) {
      this.focusBottomPage();
    }

    this.submitChallengesChanges().catch(() => {
      Object.keys(event.challengesToBeIncluded).forEach((item) => {
        this.checkupChallenge.removeChallengeFromList(event.challengesToBeIncluded[item].content);
      });
    });
  }

  private submitChallengesChanges() {
    this.onSaving.emit();
    return this.checkupChallenge
      .submitChallengesChanges()
      .toPromise()
      .then(() => {
        this.analyticsTrackerService.trackEvent(
          AnalyticsTracking.ACTIONS.REQUEST_UPDATE_CHALLENGES,
          'Successfully Update Challenges'
        );
        this.onSaved.emit();
      })
      .catch((err) => {
        this.toastService.error(err.message);
        this.analyticsTrackerService.trackEventError(
          AnalyticsTracking.ACTIONS.REQUEST_UPDATE_CHALLENGES,
          err.error
        );

        this.onSaved.emit();
        throw err;
      });
  }

  private setTemporaryHighlight(challengeId: number) {
    setTimeout(() => {
      const challengeElement: any = document.getElementById(`challenge-${challengeId}`);

      if (challengeElement) {
        challengeElement.className = 'highlight';
      }
    }, 200);

    setTimeout(() => {
      const challengeElement: any = document.getElementById(`challenge-${challengeId}`);
      if (challengeElement) {
        challengeElement.classList.remove('highlight');
      }
    }, 2000);
  }

  private focusBottomPage() {
    setTimeout(() => {
      this.scrolling.nativeElement.scrollIntoView(true);
    }, 200);
  }
}
