import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import * as FileSaver from 'file-saver';
import { finalize } from 'rxjs/operators';
import { CheckupClass } from '../../class/checkup/checkup.class';
import { Utils } from '../../class/utils/utils.class';
import { AnalyticsTracking } from '../../interfaces/analytics.interface';
import { AnalyticsTrackerService } from '../../services/analytics-tracker/analytics-tracker.service';
import { CheckupReviewService } from '../../services/checkup-review/checkup-review.service';
import { ToastService } from '../../services/toast-service/toast-service.service';
import { TranslationService } from '../../services/translation/translation.service';

@Component({
  selector: 'app-checkup-header',
  templateUrl: './checkup-header.component.html',
  styleUrls: ['./checkup-header.component.scss'],
})
export class CheckupHeaderComponent implements OnInit {
  @Input() public checkup: CheckupClass = null;

  isSelectorOpen: boolean = false;

  loading: boolean = true;

  isDownloadingReport = false;

  files: any[] = [];

  filesLoading: boolean[] = [];

  constructor(
    private utils: Utils,
    private analyticsTrackerService: AnalyticsTrackerService,
    private toastService: ToastService,
    private translationService: TranslationService,
    private checkupReviewService: CheckupReviewService,
    private el: ElementRef
  ) {}

  @HostListener('document:click', ['$event.target'])
  public onDocumentClick(targetElement) {
    const clickedInside = this.el.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.hideSelector();
    }
  }

  ngOnInit() {
    this.checkupReviewService.loadCheckupFiles().subscribe((files) => {
      this.files = files;
    });
  }

  loadingComplete() {
    this.loading = false;
  }

  downloadReport() {
    if (this.loading || this.checkup == null) {
      return;
    }

    this.isDownloadingReport = true;
    this.analyticsTrackerService.trackEvent(AnalyticsTracking.ACTIONS.DOWNLOAD, 'PDF Report');

    this.checkup
      .getPdfReport()
      .pipe(
        finalize(() => {
          this.isDownloadingReport = false;
        })
      )
      .subscribe(
        (pdfResults) => {
          this.analyticsTrackerService.trackEvent(
            AnalyticsTracking.ACTIONS.REQUEST_GET_CHECKUP_PDF,
            'Successfully Get Checkup PDF'
          );

          try {
            const decodedPdf: Blob = this.utils.base64ToPdfBlob(pdfResults.file_content);
            FileSaver.saveAs(decodedPdf, pdfResults.file_name);
          } catch (err) {
            this.analyticsTrackerService.trackEventError(
              AnalyticsTracking.ACTIONS.DOWNLOAD,
              `PDF Report Error: ${err}`,
              false
            );
            this.toastService.error(
              this.translationService.getInstantTranslation('CHECKUP.DOWNLOAD_REPORT_ERROR')
            );
          }
        },
        (err) => {
          this.analyticsTrackerService.trackEventError(
            AnalyticsTracking.ACTIONS.REQUEST_GET_CHECKUP_PDF,
            err.error
          );
          this.toastService.error(err.message);
        }
      );
  }

  downloadFile(filename: string) {
    const index = this.files.indexOf(filename);

    if (this.filesLoading[index]) {
      return;
    }

    this.filesLoading[index] = true;

    this.checkupReviewService
      .loadCheckupFile(filename)
      .pipe(
        finalize(() => {
          this.filesLoading[index] = false;
        })
      )
      .subscribe(
        (blob) => {
          this.analyticsTrackerService.trackEvent(
            AnalyticsTracking.ACTIONS.REQUEST_GET_CHECKUP_FILE,
            'Successfully Get Checkup PDF'
          );

          try {
            FileSaver.saveAs(blob, filename);
          } catch (err) {
            this.analyticsTrackerService.trackEventError(
              AnalyticsTracking.ACTIONS.DOWNLOAD,
              `File Error: ${err}`,
              false
            );
            this.toastService.error(
              this.translationService.getInstantTranslation('CHECKUP.DOWNLOAD_REPORT_ERROR')
            );
          }
        },
        (err) => {
          this.analyticsTrackerService.trackEventError(
            AnalyticsTracking.ACTIONS.REQUEST_GET_CHECKUP_FILE,
            err.error
          );
          this.toastService.error(err.message);
        }
      );
  }

  showOrHideSelector() {
    this.isSelectorOpen = !this.isSelectorOpen;
  }

  hideSelector() {
    if (this.isSelectorOpen) {
      this.isSelectorOpen = false;
    }
  }
}
