<div class="modal" [style.display]="isOpen ? 'flex' : 'none'">
  <div class="container">
    <div class="row">
      <div>
        <div class="panel">
          <div class="content">
            <div class="close" (click)="closeModal()">
              <i class="u-icon ion-md-close"></i>
            </div>
            <h2>{{ 'LOGOUT_MODAL.TITLE' | translate }}</h2>
            <p>{{ 'LOGOUT_MODAL.DESCRIPTION' | translate }}</p>

            <div class="e-logout__btn-container">
              <div class="e-logout__btn">
                <button class="c-btn c-btn__secondary" (click)="closeModal()">
                  {{ 'LOGOUT_MODAL.DISMISS' | translate }}
                </button>
              </div>
              <div>
                <div class="e-logout__btn">
                  <button class="c-btn" (click)="logout(false)">
                    {{ 'LOGOUT_MODAL.CONFIRM' | translate }}
                  </button>
                </div>

                <div class="e-logout__all">
                  <a (click)="logout(true)">{{ 'LOGOUT_MODAL.CONFIRM_ALL_DEVICES' | translate }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>