import { Component, OnInit } from '@angular/core';
import { CookieConsentService } from '../../services/cookie-consent/cookie-consent.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public currentYear: number = null;

  constructor(private cookieConsentService: CookieConsentService) {}

  public ngOnInit() {
    const todayDate = new Date();
    this.currentYear = todayDate.getFullYear();
  }

  public openCookies() {
    this.cookieConsentService.openDialog();
  }
}
