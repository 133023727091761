<div id="biomarker-group-checkup-user-contact">
  <div *ngIf="!loading && checkup; else loadingContent" class="user-contact-card">
    <h2>{{ 'CHECKUP.CONTACT.TITLE' | translate }}</h2>
    <div class="generic-panel">
      <div class="main">
        <div class="split">
          <h3 class="label">
            <i class="fa fa-phone icon"></i>
            <span>{{ 'CHECKUP.CONTACT.PHONE' | translate }}:</span>
            <span class="value">{{ checkup.userPhoneNumber || 'N/A' }}</span>
            <button *ngIf="checkup.userPhoneNumber" class="button--icon-only"
              (click)="copyToClipboard('phone', checkup.userPhoneNumber)">
              <i *ngIf="!copiedToClipboard['phone']" class="far fa-copy"></i>
              <i *ngIf="copiedToClipboard['phone']"
                class="u-icon ion-md-checkmark icon--success"></i>
            </button>
          </h3>
        </div>

        <div class="split">
          <h3 class="label">
            <i class="fa fa-envelope icon"></i>
            <span>{{ 'CHECKUP.CONTACT.EMAIL' | translate }}: </span>
            <span class="value value--email">{{ checkup.userEmail || 'N/A' }}</span>
            <button *ngIf="checkup.userEmail" class="button--icon-only"
              (click)="copyToClipboard('email', checkup.userEmail)">
              <i *ngIf="!copiedToClipboard['email']" class="far fa-copy"></i>
              <i *ngIf="copiedToClipboard['email']"
                class="u-icon ion-md-checkmark icon--success"></i>
            </button>
          </h3>
        </div>

      </div>
    </div>
  </div>

  <ng-template #loadingContent>
    <div class="c-fake-content fake-content-contact-card">
    </div>
  </ng-template>
</div>