import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Pipe({ name: 'formatDate' })
export class FormatDatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public transform(date: string, format: string, _watcher: boolean): string {
    if (format === 'questionnaire') {
      return this.formatQuestionnaireDate(date);
    }

    return moment(date).format(format);
  }

  private formatQuestionnaireDate(date: string): string {
    const momentDate = moment(date);
    const momentNow = moment(new Date());
    const monthDiff = momentNow.diff(momentDate, 'months');
    const yearDiff = momentNow.diff(momentDate, 'years');

    if (monthDiff === 0) {
      return this.translateService.instant('CHECKUP.QUESTIONNAIRE_ANSWER_DATE.THIS_MONTH');
    }

    if (monthDiff === 1) {
      return this.translateService.instant('CHECKUP.QUESTIONNAIRE_ANSWER_DATE.MONTH', {
        value: monthDiff,
      });
    }

    if (monthDiff > 0 && monthDiff < 12) {
      return this.translateService.instant('CHECKUP.QUESTIONNAIRE_ANSWER_DATE.MONTHS', {
        value: monthDiff,
      });
    }

    if (yearDiff === 1) {
      return this.translateService.instant('CHECKUP.QUESTIONNAIRE_ANSWER_DATE.YEAR', {
        value: yearDiff,
      });
    }

    return this.translateService.instant('CHECKUP.QUESTIONNAIRE_ANSWER_DATE.YEARS', {
      value: yearDiff,
    });
  }
}
