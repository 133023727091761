import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ReviewScoreMessage } from '../../interfaces/checkup-feedback.interface';
import { ApiReviewScoresService } from '../api-review-scores/api-review-scores.service';
import { ApiCheckupService } from '../api-checkup/api-checkup.service';
import { TranslationService } from '../translation/translation.service';
import { ApiCheckupTagsService } from '../api-checkup-tags/api-checkup-tags.service';
import { ApiCheckupTagsInfoResponse } from '../api-checkup-tags/api-checkup-tags.interface';
import { LocalStorageService } from '../local-storage/local-storage.service';

const DB_KEY_CHECKUPS_IN_PROGRESS = 'checkupInProgress';
@Injectable({
  providedIn: 'root',
})
export class CheckupService {
  public checkupTagsOptions: ApiCheckupTagsInfoResponse[] = [];

  public reviewScoresOptions: ReviewScoreMessage[] = [];

  public checkupsInProgress: string[] = [];

  public translationServiceUpdateObservable: Subscription;

  constructor(
    private apiReviewScoresService: ApiReviewScoresService,
    private apiCheckupTagsService: ApiCheckupTagsService,
    private apiCheckupService: ApiCheckupService,
    private localStorageService: LocalStorageService,
    private translationService: TranslationService
  ) {
    this.listenEvents();
    this.loadCheckupsInProgress();
  }

  public getReviewScoresOptions() {
    return this.reviewScoresOptions;
  }

  public getTagsOptions() {
    return this.checkupTagsOptions;
  }

  public loadReviewScoresOptions() {
    return new Observable((observer) => {
      if (this.reviewScoresOptions.length > 0) {
        observer.next();
        observer.complete();
      } else {
        this.apiReviewScoresService.getReviewScores().subscribe(
          (reviewScoresList: ReviewScoreMessage[]) => {
            this.reviewScoresOptions = reviewScoresList;
            observer.next();
            observer.complete();
          },
          (err) => {
            observer.error(err);
          }
        );
      }
    });
  }

  public loadTagsOptions() {
    return new Observable((observer) => {
      if (this.checkupTagsOptions.length > 0) {
        observer.next();
        observer.complete();
      } else {
        this.apiCheckupTagsService.getCheckupTags().subscribe(
          (checkupTagsOptions: ApiCheckupTagsInfoResponse[]) => {
            this.checkupTagsOptions = checkupTagsOptions;
            observer.next();
            observer.complete();
          },
          (err) => {
            observer.error(err);
          }
        );
      }
    });
  }

  public listenEvents() {
    this.translationServiceUpdateObservable = this.translationService
      .getTranslationsUpdate()
      .subscribe(() => {
        this.reset();
      });
  }

  public reset() {
    this.reviewScoresOptions = [];
    this.checkupTagsOptions = [];
  }

  public loadCheckupsInProgress() {
    let inProgressArray = this.localStorageService.get(DB_KEY_CHECKUPS_IN_PROGRESS);
    if (!Array.isArray(inProgressArray)) {
      inProgressArray = [];
    }

    this.checkupsInProgress = inProgressArray;
  }

  public isCheckupInProgress(checkupId: number) {
    if (!checkupId) {
      return false;
    }

    const stringCheckupId = checkupId.toString();
    if (this.checkupsInProgress.indexOf(stringCheckupId) === -1) {
      return false;
    }

    return true;
  }

  public getCheckupsInProgress(): {} {
    return this.checkupsInProgress.reduce((acc, value) => {
      acc[value] = true;
      return acc;
    }, {});
  }

  public setCheckupInProgress(checkupId: number, isTeleconsultation: boolean = false) {
    if (!checkupId) {
      return;
    }

    if (isTeleconsultation) {
      return;
    }

    const stringCheckupId = checkupId.toString();

    if (this.checkupsInProgress.indexOf(stringCheckupId) === -1) {
      // if not already in array, add it
      this.checkupsInProgress.push(stringCheckupId);
    }

    this.localStorageService.set(DB_KEY_CHECKUPS_IN_PROGRESS, this.checkupsInProgress);
  }

  public removeCheckupFromInProgress(checkupId: number) {
    if (!checkupId) {
      return;
    }

    const stringCheckupId = checkupId.toString();

    this.checkupsInProgress = this.checkupsInProgress.filter((id) => id !== stringCheckupId);

    this.localStorageService.set(DB_KEY_CHECKUPS_IN_PROGRESS, this.checkupsInProgress);
  }
}
