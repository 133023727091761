<nav>
  <ul class="main-menu">
    <li *ngFor="let item of menuItems | keys; let i = index">
      <a [routerLink]="[{ outlets: { checkup: [item.key] } }]" routerLinkActive="active"
        [class.checked]="item.value.checked" [class.clickable]="item.value.clickable"
        [class.link-disabled]="!item.value.checked">
        <i class="u-icon ion-md-checkmark" *ngIf="item.value.checked"></i>
        <span>{{ item.value.title | translate }}</span>
        <img *ngIf="item.value.order < 3" src="../assets/images/icons/arrow-right.svg" />
      </a>
    </li>
  </ul>
</nav>