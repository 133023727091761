import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-form-error-message',
  templateUrl: './form-error-message.component.html',
  styleUrls: ['./form-error-message.component.scss'],
})
export class FormErrorMessageComponent {
  @Input() visible = false;

  public errorMessage: string;

  public validatorValues: { minValue: number; maxValue: number } = {
    minValue: null,
    maxValue: null,
  };

  public currentControl = null;

  @Input() set control(controlAtr: UntypedFormControl) {
    if (controlAtr && this.currentControl == null) {
      this.currentControl = controlAtr;
      this.currentControl.valueChanges.subscribe(() => {
        this.getErrorMessage();
      });
    }
  }

  private getErrorMessage() {
    this.errorMessage = null;

    if (!this.currentControl.errors) {
      return;
    }

    Object.keys(this.currentControl.errors).forEach((propertyName) => {
      if (this.currentControl.touched) {
        this.errorMessage = this.getValidatorErrorMessage(
          propertyName,
          this.currentControl.errors[propertyName]
        );
      }
    });
  }

  private getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    this.validatorValues.minValue = null;
    this.validatorValues.maxValue = null;

    const config: {} = {
      required: `FORM_VALIDATION_MESSAGES.FIELD_REQUIRED`,
      minMaxValidator: `FORM_VALIDATION_MESSAGES.FIELD_RANGE`,
      pattern: `FORM_VALIDATION_MESSAGES.INVALID_INPUT`,
    };

    if (validatorValue.minValue !== null && validatorValue.maxValue !== null) {
      this.validatorValues.minValue = validatorValue.minValue;
      this.validatorValues.maxValue = validatorValue.maxValue;
    }

    return config[validatorName];
  }
}
