import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ReviewScoreMessage } from '../../interfaces/checkup-feedback.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiReviewScoresService {
  constructor(private http: HttpClient) {}

  public getReviewScores() {
    return this.http.get<ReviewScoreMessage[]>(`${environment.api}/doc/review_scores`);
  }
}
