import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  QueryList,
  ViewChildren,
  Output,
  EventEmitter,
} from '@angular/core';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ToastService } from '../../services/toast-service/toast-service.service';
import { CheckupReviewService } from '../../services/checkup-review/checkup-review.service';

@Component({
  selector: 'app-checkup-observation-box',
  templateUrl: './checkup-observation-box.component.html',
  styleUrls: ['./checkup-observation-box.component.scss'],
})
export class CheckupObservationBoxComponent implements OnInit, OnDestroy {
  @ViewChild('scrolling', { static: true }) public scrolling: ElementRef;

  @ViewChild('checkupObservationForm', { static: true }) public checkupObservationForm;

  @Output() public onSave = new EventEmitter();

  public loading = false;

  public reloadObservations: boolean = false;

  private observationsSubscription: ISubscription;

  constructor(
    private toastService: ToastService,
    public checkupReviewService: CheckupReviewService
  ) {}

  @ViewChildren('observations') set content(observations: QueryList<any>) {
    if (this.observationsSubscription == null && observations) {
      this.observationsSubscription = observations.changes.subscribe(() => {
        this.updateScroll();
      });
    }
  }

  public ngOnInit() {}

  public ngOnDestroy() {
    if (this.observationsSubscription) {
      this.observationsSubscription.unsubscribe();
    }
  }

  public loadCheckupObservations() {
    this.loading = true;

    this.checkupReviewService
      .loadCheckupObservations(this.reloadObservations)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.reloadObservations = false;
          this.updateScroll();
        })
      )
      .subscribe(
        () => {
          this.checkupObservationForm.setCheckupInstance(
            this.checkupReviewService.getCheckupLoaded()
          );
          this.checkupObservationForm.setCheckupObsInstance(
            this.checkupReviewService.getCheckupObsLoaded()
          );
          this.checkupObservationForm.loadFormData();
        },
        (err) => {
          // console.log('[loadCheckupObservations] ERROR', err);
          this.toastService.error(err.message);
        }
      );
  }

  public updateScroll() {
    if (this.scrolling) {
      this.scrolling.nativeElement.scrollTop = this.scrolling.nativeElement.scrollHeight;
    }
  }

  public newObservationAdded() {
    this.reloadObservations = true;
    this.onSave.emit();
  }

  public cacheObservationNotSaved() {
    this.checkupObservationForm.cacheObservationNotSaved();
  }
}
