import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  JourneyApiServiceResponse,
  JourneyApiServiceJourneyToUpdate,
  JourneyApiServiceJourneyToUpdateResponse,
} from './api-journey.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiJourneyService {
  constructor(private http: HttpClient) {}

  public getJourney(checkupId: number) {
    return this.http.get<JourneyApiServiceResponse[]>(
      `${environment.api}/doc/checkup/${checkupId}/journey`
    );
  }

  public putJourney(checkupId: number, challengesList: JourneyApiServiceJourneyToUpdate[]) {
    return this.http.put<JourneyApiServiceJourneyToUpdateResponse>(
      `${environment.api}/doc/checkup/${checkupId}/journey`,
      challengesList
    );
  }
}
