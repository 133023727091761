import { Component, Input } from '@angular/core';
import {
  CheckupApiServiceCheckupAnswerResponse,
  CheckupApiServiceCheckupQuestionPivot,
} from '../../services/api-checkup/api-checkup.interface';

@Component({
  selector: 'app-checkup-questionnaire-card',
  templateUrl: './checkup-questionnaire-card.component.html',
  styleUrls: ['./checkup-questionnaire-card.component.scss'],
})
export class CheckupQuestionnaireCardComponent {
  @Input()
  public title: string = null;

  @Input()
  public answers: CheckupApiServiceCheckupAnswerResponse[] = null;

  @Input()
  public pivot: CheckupApiServiceCheckupQuestionPivot = null;
}
