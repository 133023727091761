import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { PhrasesApiMetadataService } from '../api-metadata/api-metadata.interface';
import { ApiMetadataService } from '../api-metadata/api-metadata.service';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  public currentLanguage: string = 'en';

  public languageSubject = new Subject();

  constructor(
    private translateService: TranslateService,
    private injector: Injector,
    private localStorageService: LocalStorageService
  ) {}

  public init() {
    return new Observable((observer) => {
      if (this.loadCurrentLanguageAndTranslationsFromCache()) {
        this.loadTranslationsFromApi().subscribe(); // Update translations in background without blocking the initial loading
        observer.next();
        observer.complete();
      } else {
        this.loadTranslationsFromApi().subscribe(
          () => {
            observer.next();
            observer.complete();
          },
          (err) => {
            observer.error(err);
          }
        );
      }
    });
  }

  public getCurrentLanguage() {
    return this.currentLanguage;
  }

  public setCurrentLanguage(language: string) {
    // console.log('[setCurrentLanguage]', language);
    this.currentLanguage = language;
  }

  public setLanguageAndTranslations(
    translationsInfo: PhrasesApiMetadataService,
    currentLanguage: string,
    emitLanguageUpdateEvent: boolean = false
  ) {
    // console.log(`[setLanguageAndTranslations] "${currentLanguage}" set successfully.`);

    this.translateService.use(currentLanguage);
    this.translateService.setTranslation(currentLanguage, translationsInfo);
    this.localStorageService.set('translations', {
      language: currentLanguage,
      translations: translationsInfo,
    });

    this.setMomentLocale(currentLanguage);
    this.emitLanguageUpdateEvent(currentLanguage, emitLanguageUpdateEvent);
  }

  public setMomentLocale(currentLanguage) {
    moment.locale(currentLanguage);
  }

  public getTranslationsUpdate(): Observable<any> {
    return this.languageSubject.asObservable();
  }

  public loadCurrentLanguageAndTranslationsFromCache() {
    const currentLanguageFromCache: {
      language: string;
      translations: any;
    } = this.localStorageService.get('translations');

    // console.log('[currentLanguageFromCache]', currentLanguageFromCache);

    if (
      currentLanguageFromCache &&
      Object.keys(currentLanguageFromCache).length &&
      currentLanguageFromCache.language &&
      currentLanguageFromCache.translations
    ) {
      this.setCurrentLanguage(currentLanguageFromCache.language);
      this.setLanguageAndTranslations(
        currentLanguageFromCache.translations,
        this.getCurrentLanguage()
      );
      // console.log(`Language "${this.currentLanguage}" loaded from cache.`);

      return true;
    }
    return false;
  }

  public getInstantTranslation(key: string, params = {}) {
    return this.translateService.instant(key, params);
  }

  public loadTranslationsFromApi(emitLanguageUpdateEvent: boolean = false) {
    return this.getTranslationsFromApi().pipe(
      map((translationsInfo: PhrasesApiMetadataService) => {
        this.setLanguageAndTranslations(
          translationsInfo,
          this.getCurrentLanguage(),
          emitLanguageUpdateEvent
        );
      })
    );
  }

  public getTranslationsFromApi() {
    // console.log('[getTranslationsFromApi]');

    const apiMetadataService = this.injector.get(ApiMetadataService);
    return apiMetadataService.getTranslations();
  }

  public emitLanguageUpdateEvent(currentLanguage, emitEvent) {
    if (emitEvent) {
      this.languageSubject.next({ currentLanguage });
    }
  }
}

/* tslint:disable:ban-types */
export function initTranslations(translationService: TranslationService): Function {
  return () => {
    return new Promise((resolve) => {
      translationService.init().subscribe(
        () => {
          resolve(true);
        },
        () => {
          resolve(true);
        }
      );
    });
  };
}
