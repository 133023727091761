<div class="container">
  <app-checkup-header #userHeader [checkup]="checkup">
  </app-checkup-header>

  <div class="row">
    <div class="col-lg-9 col-md-8 col-sm-12 col-xs-12 container__main-section">

      <app-checkup-appointment-box #appointmentBox [checkup]="checkup">
      </app-checkup-appointment-box>

      <app-checkup-user-contact-card #userContactCard [checkup]="checkup">
      </app-checkup-user-contact-card>

      <app-checkup-user-info-card #userInfoCard [checkup]="checkup"
        (openObservations)="openObsModal()"></app-checkup-user-info-card>

      <app-checkup-questionnaire-box #questionnaireBox [checkup]="checkup">
      </app-checkup-questionnaire-box>

      <app-checkup-biomarkers-box #biomarkersBox [currentPerformedAt]="checkup?.performedAt"
        [previousPerformedAt]="checkup?.previousCheckup?.performed_at"
        [resultsByGroup]="checkup?.biomarkersResultsByGroups"></app-checkup-biomarkers-box>

      <app-checkup-calculated-biomarkers-box #calculatedBiomarkersBox
        [resultsByGroup]="checkup?.calculatedBiomarkersResultsByGroups">
      </app-checkup-calculated-biomarkers-box>

      <app-checkup-next-step-button *ngIf="!loading"
        [isTeleconsultation]="checkup?.hasTeleconsultation" nextStep="challenges"
        buttonTitle="GENERAL.PROCEED"></app-checkup-next-step-button>
    </div>
    <div class="col-lg-3 col-md-4 hide-sm hide-xs sidebar-wrapper" #boundary>
      <div #spacer></div>
      <div class="sidebar-menu">
        <app-checkup-sidebar-menu stickyThing [spacer]="spacer" [boundary]="boundary"
          marginTop="100" #sidebarMenu [checkupId]="checkup.checkupId" [showModalButton]="true"
          nextStep="questions" prefixGroupTitle="biomarker-group"></app-checkup-sidebar-menu>
      </div>
    </div>
  </div>
</div>