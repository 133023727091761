<ul *ngIf="!loading" class="menu">
  <li *ngFor="let currentItem of items">
    <a id="biomarker-menu-item-{{ prefixGroupTitle }}-{{ currentItem.id }}"
      [class.active]="activeMenuItem == currentItem"
      scrollTo="{{ prefixGroupTitle }}-{{ currentItem.id }}" offset="120"
      (click)="menuSelect(currentItem)">
      {{ currentItem.title }}
    </a>
  </li>
</ul>

<ul class="menu-fake" *ngIf="loading">
  <li *ngFor="let key of [1, 2, 3, 4]">
    <a class="fake-content"></a>
  </li>
</ul>