<div *ngIf="biomarker" class="container biomarker-card">
  <div class="row">
    <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
      <div class="tooltip">
        <div class="tooltiptext">
          {{ 'CHECKUP.CALCULATED_BIOMARKER.GROUP' | translate: { group: groupName } }}
        </div>
        <span>
          <i class="biomarker-card__status-circle check"
            [style.background-color]="biomarker.reading?.tag?.color ? biomarker.reading.tag.color : '#2C3239'"></i>
          <strong class="biomarker-card__name">{{ biomarker.doc_name }}</strong>
        </span>
      </div>
    </div>

    <div class="biomarker-card__wrapper col-lg-4 col-md-4 col-sm-4 col-xs-4">
      <div *ngIf="biomarker.reading" class="biomarker-card__value tooltip">
        <div class="tooltiptext" *ngIf="readingValueTooltip">
          {{ readingValueTooltip }}
          <br>
          <span
            class="biomarker-card__value-tooltip">({{ biomarker.reading.measured_at | formatDate: 'LL' }})</span>
        </div>
        <span>
          <span>{{ biomarker.reading.value }}
            {{ biomarker.unit }}</span>
        </span>
      </div>
    </div>
  </div>
</div>