import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiCheckupTagsInfoResponse } from './api-checkup-tags.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiCheckupTagsService {
  constructor(private http: HttpClient) {}

  public getCheckupTags() {
    return this.http.get<ApiCheckupTagsInfoResponse[]>(`${environment.api}/doc/tags`);
  }
}
