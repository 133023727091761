export class AnalyticsTracking {
  public static readonly ACTIONS = {
    SUBMIT_LOGIN: 'Submit Login',
    TOGGLE_SELECT: 'Toggle Select',
    REQUEST_LOGOUT: 'Request Logout',
    SELECT_OPTION: 'Select Option',
    TOGGLE_MODAL: 'Toggle Modal',
    REQUEST_SUBMIT_EDIT_PROFILE: 'Request Submit Edit Profile',
    CHOOSE_FILE: 'Choose File',
    VIEW_CHECKUPS: 'View Checkups',
    VIEW_CHECKUP_RESULTS: 'View Checkup Results',
    REQUEST_SUBMIT_CHANGE_PASSWORD: 'Request Submit Change Password',
    TOGGLE_SEARCH: 'Toggle Search',
    SEARCH: 'Search',
    FILTER_PACKAGES: 'Filter Packages',
    FILTER_CHECKUPS: 'Filter Checkups',
    NOTIFICATION_POPUP_UPDATE_CHECKUPS: 'Notification Popup Update Checkups',
    SELECT_CHECKUP_GROUP: 'Select Checkup Group',
    DOWNLOAD: 'Download',
    REQUEST_SUBMIT_OBSERVATION: 'Request Submit Observation',
    CHECKUP_RESULT_STEP_CONFIRMATION: 'Checkup Results Step Confirmation',
    REMOVE_CHALLENGE: 'Remove Challenge',
    REORDER_LIST: 'Reoder List',
    REQUEST_UPDATE_CHALLENGES: 'Request Update Challenges',
    CLICK_CHALLENGE: 'Click Challenge',
    SELECT_CHALLENGE: 'Select Challenge',
    ADD_CHALLENGES: 'Add Challenges',
    REQUEST_SUBMIT_FEEDBACK: 'Request Submit Feedback',
    REQUEST_UPDATE_FEEDBACK: 'Request Update Feedback',
    REQUEST_GET_CHECKUP_PDF: 'Request Get Checkup PDF',
    REQUEST_GET_CHECKUP_FILE: 'Request Get Checkup File',
    REQUEST_SEARCH_CHALLENGES: 'Request Search Challenges',
    REQUEST_UPDATE_TAGS: 'Request Update Tags',
  };
}
