import { UntypedFormControl } from '@angular/forms';
import { ApiCheckupTagsInfoResponse } from '../../services/api-checkup-tags/api-checkup-tags.interface';

export interface FeedbackFormControls {
  score: UntypedFormControl;
  scoreInput: UntypedFormControl;
  scoreOptions: UntypedFormControl;
  riskFlagged: UntypedFormControl;
  healthConditionUnknownByUser: UntypedFormControl;
  healthScore: UntypedFormControl;
  allowContact: UntypedFormControl;
  willingnessToChange: UntypedFormControl;
  tags: ApiCheckupTagsInfoResponse[];
  followupEmail: UntypedFormControl;
}

export interface ScoreSliderOptions {
  showTicks: boolean;
  stepsArray: Array<{ value: number; legend?: string }>;
  disabled?: boolean;
}

export const SCORE_SLIDER_UNDEFINED_STEP = -1;
