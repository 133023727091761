import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit, OnDestroy {
  constructor(private location: Location, private renderer: Renderer2) {}

  public ngOnInit() {
    this.loadClass('add');
  }

  public ngOnDestroy() {
    this.loadClass('remove');
  }

  public goBack() {
    this.location.back();
  }

  public loadClass(type: string) {
    if (type === 'add') {
      this.renderer.addClass(document.documentElement, 'page-not-found');
    } else {
      this.renderer.addClass(document.documentElement, 'page-not-found');
    }
  }
}
