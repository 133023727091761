export default class DomManipulationUtility {
  /**
   * Create an iFrame with the youtube video URL and removes the URL from the DOM
   *
   */
  public static replaceLinkWithIframe(domElement, link, stringLink) {
    // Show embbeded youtube videos
    // Check if link is full youtube link or shortern link
    let formattedLink = link;
    if (stringLink.search('youtube') >= 0) {
      formattedLink = link.replace('watch?v=', 'embed/');
      formattedLink = link.replace('v/', 'embed/');
    }
    if (stringLink.search('youtu.be') >= 0) {
      formattedLink = link.replace('youtu.be/', 'youtube.com/embed/');
    }

    // Add theme to iframe
    formattedLink += '?theme=light';

    // Create a div as a placeholder
    const videoContainer = document.createElement('div');
    videoContainer.className = 'video-container';

    // Create the video iframe
    const videoFrame = document.createElement('iframe');
    videoFrame.setAttribute('src', formattedLink);
    videoFrame.frameBorder = '0';
    videoFrame.allowFullscreen = true;
    videoFrame.width = '853';
    videoFrame.height = '480';

    // Add div and iframe
    videoContainer.appendChild(videoFrame);

    domElement.parentNode.insertBefore(videoContainer, domElement);
    // Remove original Link
    domElement.remove();
  }

  /**
   *  Replaces links with clickable elements that opens in a bew tab
   *
   */
  public static replaceLinkWithClickableLink(domElement, stringLink: string) {
    // Open links with in-app-browser
    const linkButton = document.createElement('a');
    linkButton.setAttribute('class', 'link-button');
    linkButton.setAttribute('href', stringLink);
    linkButton.setAttribute('target', '_blank');
    linkButton.innerText = domElement.innerText;

    domElement.parentNode.insertBefore(linkButton, domElement);
    domElement.remove();
  }
}
