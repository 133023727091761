import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  ApiCheckupObservationsServiceObservationInfoResponse,
  ApiCheckupObservationsServiceObservationPostRequest,
} from './api-checkup-observations.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiCheckupObservationsService {
  constructor(private http: HttpClient) {}

  public postCheckupObservation(
    checkupId: number,
    observationBody: ApiCheckupObservationsServiceObservationPostRequest
  ) {
    return this.http.post<ApiCheckupObservationsServiceObservationPostRequest>(
      `${environment.api}/doc/checkup/${checkupId}/observations`,
      observationBody
    );
  }

  public getUserObservations(userId: number) {
    return this.http.get<ApiCheckupObservationsServiceObservationInfoResponse[]>(
      `${environment.api}/doc/user/${userId}/checkup_observations`
    );
  }
}
