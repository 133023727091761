<div class="overlay-container">
  <div *ngFor="let notification of notifications" class="toast {{ notification?.effect }}">
    <div class="{{ cssClass(notification) }}">
      <i *ngIf="cssClass(notification) == 'toast-success'"
        class="toast-icon u-icon ion-md-checkmark-circle-outline"></i>
      <i *ngIf="cssClass(notification) == 'toast-error'" class="toast-icon u-icon ion-md-close"></i>
      <i *ngIf="cssClass(notification) == 'toast-warning'"
        class="toast-icon u-icon ion-md-information-circle-outline"></i>
    </div>
    <span class="toast-message">{{ notification?.description }}</span>
  </div>
</div>