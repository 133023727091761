<div class="modal" [style.display]="isOpen ? 'flex' : 'none'">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="panel">
          <div class="content">
            <h2>{{ 'CHECKUP.CONTENT_NOT_SAVED_MODAL.TITLE' | translate }}</h2>
            <p>{{ 'CHECKUP.CONTENT_NOT_SAVED_MODAL.DESCRIPTION' | translate }}:</p>

            <div class="observation">
              <p class="message">"{{ currentObservation?.text }}"</p>

              <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-12 buttons-custom">
                  <div class="bt" [class.disabled]="loading" (click)="submitCheckupObs()">
                    <a>
                      <span *ngIf="loading" class="loading">
                        <img src="./assets/images/button-loading.gif" />
                      </span>
                      {{ 'GENERAL.SAVE' | translate }}
                    </a>
                  </div>
                </div>

                <div class="col-md-3 col-sm-3 col-xs-12 buttons-custom">
                  <div class="bt edit" [class.disabled]="loading" (click)="openObsBox()">
                    <a>
                      {{ 'GENERAL.EDIT' | translate }}
                    </a>
                  </div>
                </div>

                <div class="col-md-3 col-sm-3 col-xs-12 buttons-custom">
                  <div class="bt cancel" [class.disabled]="loading" (click)="discardCheckupObs()">
                    <a>{{ 'GENERAL.DISCARD' | translate }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>