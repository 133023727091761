import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CheckupReviewService } from '../../services/checkup-review/checkup-review.service';
import { CheckupClass } from '../../class/checkup/checkup.class';
import { CheckupObsClass } from '../../class/checkup-obs/checkup-obs.class';
import { ObservationFlag } from '../../class/checkup-obs/checkup-obs.interface';

@Component({
  selector: 'app-checkup-lab-observation',
  templateUrl: './checkup-lab-observation.component.html',
  styleUrls: ['./checkup-lab-observation.component.scss'],
})
export class CheckupLabObservationComponent implements OnInit {
  @Input() public checkup: CheckupClass = null;

  @Output() public openObservations = new EventEmitter();

  public hasPreviousLabObs = false;

  public labObservations: CheckupObsClass[] = [];

  constructor(public checkupReviewService: CheckupReviewService) {}

  public ngOnInit() {
    const checkup = this.checkupReviewService.getCheckupLoaded();
    const observations = this.checkupReviewService.getCheckupObsLoaded().observationsList;
    this.labObservations = observations.filter(
      (item) => item.fromLab && item.checkupId === checkup.checkupId
    );
    this.hasPreviousLabObs =
      observations.filter((item) => item.fromLab && item.checkupId !== checkup.checkupId).length >
      0;
  }

  public openObservationsModal() {
    this.openObservations.emit();
  }

  public getFlagPhrase(flag: ObservationFlag): string {
    switch (flag) {
      case 'quality_related':
        return 'OBSERVATIONS.IS_QUALITY_RELATED';
      case 'special_case':
        return 'OBSERVATIONS.IS_SPECIAL_CASE';
      default:
        break;
    }

    return '';
  }
}
