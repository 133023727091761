import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsTrackerService } from '../../services/analytics-tracker/analytics-tracker.service';

@Component({
  selector: 'app-checkup-popup-blocked-help',
  templateUrl: './checkup-popup-blocked-help.component.html',
  styleUrls: ['./checkup-popup-blocked-help.component.scss'],
})
export class CheckupPopupBlockedHelpComponent implements OnInit {
  @Output() public onRetry? = new EventEmitter();

  @Output() public onClose? = new EventEmitter();

  public isOpen: boolean = false;

  constructor(
    private renderer: Renderer2,
    private translateService: TranslateService,
    private analyticsTrackerService: AnalyticsTrackerService
  ) {}

  get lang() {
    return this.translateService.currentLang || this.translateService.defaultLang;
  }

  public ngOnInit() {}

  public retry() {
    this.close(true);
    this.onRetry.emit();
  }

  public open() {
    this.analyticsTrackerService.trackEvent('Toggle Modal', 'Open Popup Blocked Help Modal');

    this.isOpen = true;
    this.toggleBodyClass();
  }

  public close(skipEmit = false) {
    this.analyticsTrackerService.trackEvent('Toggle Modal', 'Open Popup Blocked Help Modal');

    this.isOpen = false;
    this.toggleBodyClass();

    if (!skipEmit) {
      this.onClose.emit();
    }
  }

  public toggleBodyClass() {
    if (this.isOpen) {
      this.renderer.addClass(document.body, 'disable-scroll');
    } else {
      this.renderer.removeClass(document.body, 'disable-scroll');
    }
  }
}
