import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  ChallengeApiServiceResponse,
  ChallengeSearchApiServiceResponse,
} from './api-challenges.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiChallengesService {
  constructor(private http: HttpClient) {}

  public getChallengesByTitle(checkupTitle: string, currentPage: number) {
    return this.http.get<ChallengeSearchApiServiceResponse>(
      `${environment.api}/doc/content/search/challenges/${checkupTitle}?page=${currentPage}`
    );
  }

  public getChallengeById(challengeId: number) {
    return this.http.get<ChallengeApiServiceResponse>(
      `${environment.api}/doc/challenge/${challengeId}`
    );
  }
}
