<nav class="e-menu" *ngIf="isUserLogged">
  <div class="e-menu__container">
    <div class="e-menu__brand col-sm-3 hide-xs">
      <a routerLink="/" routerLinkActive="active">
        <img src="./assets/images/logo.svg">
      </a>
    </div>
    <ul class="e-menu__main col-sm-6 col-xs-9" *ngFor="let package of [recentPackage$ | async]">
      <li class="e-menu__main-item">
        <a class="e-menu__main-link" routerLink="/packages"
          [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active">
          {{ 'PACKAGES' | translate }}
        </a>
      </li>

      <li class="e-menu__main-item" *ngIf="package && !package.teleconsultation">
        <a class="e-menu__main-link" routerLink="/packages/{{ package.packageId }}/checkups"
          [routerLinkActiveOptions]="{ exact: false, __force_change_detection__: package.packageId }"
          routerLinkActive="active">
          {{ 'CHECKUPS' | translate }}
          <span>#{{ package.token }}</span>
        </a>
      </li>

      <li class="e-menu__main-item">
        <a class="e-menu__main-link" routerLink="/appointments" routerLinkActive="active">
          {{ 'APPOINTMENTS' | translate }}
        </a>
      </li>
    </ul>

    <div class="col-sm-3 col-xs-3">
      <div class="e-menu__user" appClickOutside (clickOutside)="closeSubmenu()">
        <div class="e-menu__user-field" (click)="showOrHideSubmenu()">
          <span class="hide-md">{{ 'GENERAL.HI' | translate }},</span>
          <p class="hide-md"> {{ currentUser.firstName }}</p>
          <img *ngIf="currentUser && currentUser.profilePictureSmall"
            [src]="currentUser.profilePictureSmall">
          <i
            [class]="submenuIsOpen ? 'arrow arrow-active u-icon ion-md-arrow-dropup up' : 'arrow u-icon ion-md-arrow-dropdown down'"></i>
        </div>
        <ul class="e-menu__user-menu" *ngIf="submenuIsOpen" (click)="closeSubmenu()">
          <li>
            <a class="e-menu__user-option" routerLink="/profile" routerLinkActive="active">
              <i class="u-icon ion-md-person"></i>
              <span>
                {{ 'PROFILE_PAGE.PAGE_TITLE' | translate }}
              </span>
            </a>
          </li>
          <li>
            <a class="e-menu__user-option" routerLink="/change-password" routerLinkActive="active">
              <i class="u-icon ion-md-lock"></i>
              <span> {{ 'PASSWORD_PAGE.TITLE' | translate }}</span>
            </a>
          </li>
          <li>
            <a class="e-menu__user-option" (click)="logoutModalComponent.openModal()">
              <i class="u-icon ion-md-power"></i>
              <span>{{ 'MENU_BAR.LOGOUT' | translate }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>

<app-logout-modal></app-logout-modal>